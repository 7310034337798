import { Box, Stack, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import {
  ArrowRightIcon,
  CheckFacilityIcon,
} from "../../../common/assets/svgIcons/icon";
import { MouseEventHandler } from "react";

type Props = {
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
};

const MapViewButton = ({ onClick }: Props): JSX.Element => {
  return (
    <Wrapper onClick={onClick}>
      <CheckFacilityIcon />
      <Text variant="body-500-18">Map view</Text>
      <ArrowButton>
        <ArrowRightIcon />
      </ArrowButton>
    </Wrapper>
  );
};

export default MapViewButton;

const Wrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    gap: theme.spacing(2),

    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.primary["base-border"]}`,

    "& .enter-location-autocomplete": {
      width: "100%",
    },

    ".pac-container": {
      margineTop: "20px",
    },

    "& svg": {
      color: theme.palette.primary["text-secondary"],
      fontSize: "100%",
    },
  })
);

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-secondary"],
  lineHeight: "20px",
  transform: "translateY(2px)",
}));

const ArrowButton = styled(Stack)(({ theme }) => ({
  marginLeft: "auto",
  alignItems: "center",

  "& svg": {
    color: theme.palette.primary["text-secondary"],
    fontSize: "20px",
  },
}));
