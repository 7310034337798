import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import IconPrevButton from "../../../common/components/navigationButtons/IconPrevButton";
import AnimatedTextButton from "../../../common/components/styledComponents/AnimatedTextButton";

const FacilityNavigation = (): JSX.Element => {
  const navigate = useNavigate();

  const handlePrevButtonClick = (): void => {
    navigate("/configurator/choose-location");
  };

  const handleOnBookAdvancedButtonClick = (): void => {
    navigate("/preorder/detail");
  };

  return (
    <NavigationButtonsWrapper>
      <IconPrevButton onClick={handlePrevButtonClick} />
      <AnimatedTextButton
        variant="contained"
        color="button-primary"
        onClick={handleOnBookAdvancedButtonClick}
        endIcon={null}
      >
        Book a call for advanced build
      </AnimatedTextButton>
    </NavigationButtonsWrapper>
  );
};

export default FacilityNavigation;

const NavigationButtonsWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    position: "absolute",
    right: "0",
    display: "flex",
    gap: "16px",

    [theme.breakpoints.down("laptop")]: {
      bottom: 0,
    },
  })
);
