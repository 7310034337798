import StyledIconButton from "../../../../common/components/styledComponents/StyledIconButton";

const TransformControlButton = ({ handleOnClick, children }) => {
  return (
    <StyledIconButton
      variant="contained"
      color="button-secondary-icon"
      onClick={handleOnClick}
    >
      {children}
    </StyledIconButton>
  );
};

export default TransformControlButton;
