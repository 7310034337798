import { ReactElement } from "react";
import AnimatedIconButton from "../styledComponents/AnimatedIconButton";
import MenuIcon from "../../assets/svgIcons/MenuIcon";
import CloseMenuIcon from "../../assets/svgIcons/CloseMenuIcon";

type MenuButtonProps = {
  handleOnClick: () => void;
  type: "open" | "close";
};

const MenuButton = ({ handleOnClick, type }: MenuButtonProps): ReactElement => {
  return (
    <AnimatedIconButton
      variant="outlined"
      color="button-tertiary"
      onClick={handleOnClick}
      endIcon={false}
      startIcon={false}
      opacityIcon={type === "open" ? <MenuIcon /> : <CloseMenuIcon />}
    />
  );
};

export default MenuButton;
