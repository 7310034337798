import { ChangeEvent } from "react";
import { IconButton, Stack, styled } from "@mui/material";

import StyledSlider from "../../../common/components/StyledSlider";

import { MinusIcon, PlusIcon } from "../../../common/assets/svgIcons/icon";
import { clamp } from "three/src/math/MathUtils";
import { useResponsive } from "../../../common/hooks/useResponsive";

type Props = {
  value: number;
  onChange: (value: number) => void;
};

export const EffectIntensitySlider = ({
  value,
  onChange,
}: Props): JSX.Element => {
  const { isLargeDevice } = useResponsive();

  const handleOnSliderChange = (event: ChangeEvent<HTMLInputElement>): void =>
    onChange(+event.target.value);

  const handleSetValue = (value: number): void => {
    const newValue = clamp(value, 0, 1);
    onChange(newValue);
  };

  const increaseValue = (): void => handleSetValue(value + 0.1);
  const decreaseValue = (): void => handleSetValue(value - 0.1);

  return (
    <Wrapper direction={"row"} justifyContent={"center"}>
      <StyledIconButton disabled={value === 0} onClick={decreaseValue}>
        <MinusIcon />
      </StyledIconButton>
      {isLargeDevice && (
        <StyledSlider
          min={0}
          max={1}
          step={0.01}
          value={value}
          onChange={handleOnSliderChange}
          onRelease={(): void => {}}
        />
      )}
      <StyledIconButton disabled={value === 1} onClick={increaseValue}>
        <PlusIcon />
      </StyledIconButton>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  flex: "1.5",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 2,
  [theme.breakpoints.down("tablet")]: {
    flex: "0.5",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "relative",
  opacity: "1",
  backgroundColor: theme.palette["button-secondary"].main,
  width: "48px",
  height: "48px",

  "&>svg": {
    fontSize: "20px",
    color: theme.palette.primary.dark,
  },
  "&:hover": {
    backgroundColor: theme.palette["button-secondary"].main,
  },
  "&.Mui-disabled": {
    opacity: 0.5,
    background: theme.palette["button-secondary"].main,
  },
  [theme.breakpoints.down("tablet")]: {
    "&>svg": {
      fontSize: "18px",
    },
  },
}));
