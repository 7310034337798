import { useContext } from "react";

import ConfiguratorProgressBarContext from "../../../context/configuratorProgressBarContext/ConfiguratorProgressBarContext";
import { Box, Typography, styled } from "@mui/material";

const MobileConfiguratorProgressBar = () => {
  const { currentStep, stepsAmount } = useContext(
    ConfiguratorProgressBarContext
  );

  return (
    <ProgressBarContainer>
      <StepTextWrapper>
        <StepText variant="body-500-12">{`${currentStep}/${stepsAmount}`}</StepText>
      </StepTextWrapper>
      <LineWrapper>
        <BackLine>
          <FrontLine percentage={(currentStep / stepsAmount) * 100} />
        </BackLine>
      </LineWrapper>
    </ProgressBarContainer>
  );
};

export default MobileConfiguratorProgressBar;

const ProgressBarContainer = styled(Box)(() => ({
  position: "absolute",
  top: "100%",
  left: 0,
  width: "100%",
  padding: "0px 16px 16px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StepTextWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "30px",
}));

const StepText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-primary"],
}));

const LineWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
}));

const BackLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.primary["base-border"],
}));

const FrontLine = styled(Box)(({ percentage, theme }) => ({
  width: `${percentage}%`,
  height: "1px",
  backgroundColor: theme.palette.primary["grey-1300"],
}));
