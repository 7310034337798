export const offsetPoints = [
  {
    x: -200,
    y: -50,
  },
  {
    x: -100,
    y: 100,
  },
  {
    x: 0,
    y: 200,
  },
];

export const offsetPointsMobile = [
  {
    x: -200,
    y: 100,
  },
  {
    x: -100,
    y: 80,
  },
  {
    x: -10,
    y: 100,
  },
];
