import { useState } from "react";
import { Box, styled } from "@mui/material";

import MenuTitle from "./MuneTitle";
import MenuItem from "./MenuItem";

const MenuBlock = ({ orientation, navData }) => {
  const [isHovered, setIsHovered] = useState("default");

  const handleOnMouseEnter = () => {
    setIsHovered("hovered");
  };

  const handleOnMouseLeave = () => {
    setIsHovered("default");
  };

  const handleOnClick = () => {
    setIsHovered(isHovered === "hovered" ? "default" : "hovered");
  };

  return (
    <MenuBlockWrapper
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnClick}
      orientation={orientation}
    >
      <MenuTitle
        orientation={orientation}
        isHovered={isHovered}
        title={navData.title}
      />
      <MenuItemsWrapper orientation={orientation} ishovered={isHovered}>
        {navData.navItems.map((data, i) => (
          <MenuItem
            key={`${data.title}-${i}`}
            title={data.title}
            describe={data.describe}
            navigationPathName={data.navigationPathName}
            orientation={orientation}
          />
        ))}
      </MenuItemsWrapper>
    </MenuBlockWrapper>
  );
};

export default MenuBlock;

const MenuBlockWrapper = styled(Box)(({ theme, orientation }) => ({
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: orientation === "start" ? "flex-end" : "flex-end",
  width: "380px",

  gap: "24px",
  pointerEvents: "auto",

  [theme.breakpoints.down("laptop")]: {
    width: "270px",
  },
}));

const MenuItemsWrapper = styled(Box)(({ ishovered }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",

  transform: ishovered === "hovered" ? "translateY(0px)" : "translateY(-20px)",
  opacity: ishovered === "hovered" ? 1 : 0,

  transition: "all 0.3s",
}));
