import { useContext } from "react";
import { Stack, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import SelectInput from "../../../common/components/formInputs/SelectInput";
import TextInput from "../../../common/components/formInputs/TextInput";

import { useCountries } from "../hooks/useCountries";

import DetailsFormContext, {
  DetailsFormContextType,
} from "../context/detailsFormContext/DetailsFormContext";

const EnterDetailsForm = (): JSX.Element => {
  const { detailsData, handleOnDetailChange, isShowInputError } = useContext(
    DetailsFormContext
  ) as DetailsFormContextType;
  const { countries } = useCountries();

  return (
    <Wrapper>
      <TextInput
        type={detailsData.firstname?.type}
        name={detailsData.firstname.dataKey}
        value={detailsData.firstname?.value}
        placeholder={detailsData.firstname?.placeholder}
        isError={isShowInputError && detailsData.firstname?.error}
        errorMessage={detailsData.firstname?.errorMessage}
        handleOnChange={(value): void =>
          handleOnDetailChange(value, detailsData.firstname?.dataKey)
        }
      />
      <TextInput
        type={detailsData.lastname?.type}
        name={detailsData.lastname.dataKey}
        value={detailsData.lastname?.value}
        placeholder={detailsData.lastname?.placeholder}
        isError={isShowInputError && detailsData.lastname?.error}
        errorMessage={detailsData.lastname?.errorMessage}
        handleOnChange={(value): void =>
          handleOnDetailChange(value, detailsData.lastname?.dataKey)
        }
      />
      <TextInput
        type={detailsData.email?.type}
        name={detailsData.email.dataKey}
        value={detailsData.email?.value}
        placeholder={detailsData.email?.placeholder}
        isError={isShowInputError && detailsData.email?.error}
        errorMessage={detailsData.email?.errorMessage}
        handleOnChange={(value): void =>
          handleOnDetailChange(value, detailsData.email?.dataKey)
        }
      />
      <TextInput
        type={detailsData.phone.type}
        name={detailsData.phone.dataKey}
        value={detailsData.phone.value}
        placeholder={detailsData.phone.placeholder}
        isError={isShowInputError && detailsData.phone.error}
        errorMessage={detailsData.phone.errorMessage}
        handleOnChange={(value): void =>
          handleOnDetailChange(value, detailsData.phone.dataKey)
        }
      />
      <SelectInput
        value={detailsData.country.value}
        name={detailsData.country.dataKey}
        placeholder={detailsData.country.placeholder}
        isError={isShowInputError && detailsData.country.error}
        errorMessage={detailsData.country.errorMessage}
        options={countries.getData()}
        handleOnChange={(value): void =>
          handleOnDetailChange(value, detailsData.country.dataKey)
        }
      />
      <TextInput
        type={detailsData.company.type}
        name={detailsData.company.dataKey}
        value={detailsData.company.value}
        placeholder={detailsData.company.placeholder}
        isError={isShowInputError && detailsData.company.error}
        errorMessage={detailsData.company.errorMessage}
        handleOnChange={(value): void =>
          handleOnDetailChange(value, detailsData.company.dataKey)
        }
      />
      <TextInput
        type={detailsData.jobtitle.type}
        name={detailsData.jobtitle.dataKey}
        value={detailsData.jobtitle.value}
        placeholder={detailsData.jobtitle.placeholder}
        isError={isShowInputError && detailsData.jobtitle.error}
        errorMessage={detailsData.jobtitle.errorMessage}
        handleOnChange={(value): void =>
          handleOnDetailChange(value, detailsData.jobtitle.dataKey)
        }
      />
    </Wrapper>
  );
};

export default EnterDetailsForm;

const Wrapper = styled(Stack)(
  (): CSSProperties => ({
    width: "100%",
    maxHeight: "600px",
    overflow: "auto",
    pointerEvents: "auto",

    gap: "20px",
  })
);
