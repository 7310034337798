import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

const AppFooter = () => {
  return (
    <FooterWrapper>
      <TextTypography variant="body-400-14">
        Copyright © 2023 Minerva. All rights reserved.
      </TextTypography>
      <ButtonWrapper>
        <StyledLink to="https://www.cleanconnect.ai/">
          <TextTypography variant="body-400-14">
            About Cleanconnect
          </TextTypography>
        </StyledLink>
        <StyledLink to="https://www.cleanconnect.ai/privacy-policy">
          <TextTypography variant="body-400-14">Privacy policy</TextTypography>
        </StyledLink>
        <StyledLink to="/preorder/detail">
          <TextTypography variant="body-400-14">Contact</TextTypography>
        </StyledLink>
      </ButtonWrapper>
    </FooterWrapper>
  );
};

export default AppFooter;

const FooterWrapper = styled("footer")(({ theme }) => ({
  width: "100%",

  display: "none",
  [theme.breakpoints.up("laptop")]: {
    display: "flex",
  },
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px  24px",
  backgroundColor: theme.palette.primary["base-background"],
  pointerEvents: "auto",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "24px",
}));

const TextTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-secondary"],
}));

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",

  pointerEvents: "auto",
}));
