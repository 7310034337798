import { useContext, useEffect } from "react";
import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import QuizQuestionWrapper from "./components/QuizQuestionWrapper";
import QuizProgressBar from "./components/QuizProgressBar";
import QuizNavigation from "./components/QuizNavigation";

import QuizContextProvider from "./context/quizContext/QuizContextProvider";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import ConfiguratorProgressBarContext from "../../common/context/configuratorProgressBarContext/ConfiguratorProgressBarContext";

const QuizPage = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);
  const { handleSetCurrentStep } = useContext(ConfiguratorProgressBarContext);

  useEffect((): void => {
    if (threeScene) {
      threeScene.loadSceneStage("hide", "desktop");
      handleSetCurrentStep("quiz");
    }
  }, []);

  return (
    <PageWrapper>
      <QuizContextProvider>
        <ContentWrapper>
          <QuizQuestionWrapper />
          <ContentFooter>
            <QuizProgressBar />
            <QuizNavigation />
          </ContentFooter>
        </ContentWrapper>
      </QuizContextProvider>
    </PageWrapper>
  );
};

export default QuizPage;

const PageWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    height: "100%",
    display: "flex",
    padding: theme.spacing(10, 6, 6, 6),
    alignItems: "flex-start",
    justifyContent: "center",
    pointerEvents: "auto",

    [theme.breakpoints.down("tablet")]: {
      padding: theme.spacing(4),
    },
  })
);

const ContentWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "676px",
    height: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "32px",
  })
);

const ContentFooter = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  })
);
