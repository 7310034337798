import { Vector3, Euler, CameraHelper } from "three";
import { degToRad } from "three/src/math/MathUtils";
import Camera from "./Camera";

import { Scene3DObjectsNames } from "../sceneObjects/SceneObjectsPathData";

class SceneCameras {
  constructor(
    scene,
    sceneObjects,
    mainViewportDimensions,
    viewGasViewportDimensions,
    view360ViewportDemensions
  ) {
    this.scene = scene;
    this.sceneObjects = sceneObjects;
    this.mainViewportDimensions = mainViewportDimensions;
    this.viewGasViewportDimensions = viewGasViewportDimensions;
    this.view360ViewportDemensions = view360ViewportDemensions;

    this.init();
  }

  init() {
    this.mainCamera = new Camera(this.mainViewportDimensions);
    this.gasCamera = new Camera(this.viewGasViewportDimensions);

    this.view360Cameras = this.view360ViewportDemensions.map(
      dimensions => new Camera(dimensions)
    );
  }

  addCameraHelper(camera) {
    const helper = new CameraHelper(camera);
    this.scene.add(helper);
  }

  addCameraToScene() {
    this.mainCamera.addCameraToParent(this.scene);

    const gasCameraParent = this.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.GasCamera35mm
    ).object;

    this.gasCamera.addCameraToParent(gasCameraParent);
    this.gasCamera.setCameraPositionAndRotation(
      new Vector3(0, 0, 10),
      new Euler(0, degToRad(180), 0, "XYZ")
    );

    const camera360Parent = this.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.PointsViews
    ).object;

    this.view360Cameras?.forEach((camera, i) => {
      camera.addCameraToParent(
        camera360Parent.children[0].children[1].children[i]
      );

      camera.setCameraPositionAndRotation(
        i <= 1 ? new Vector3(0, 0, 0.3) : new Vector3(0, 0, -0.3),
        new Euler(0, degToRad(i * 90 + (i % 2 ? 30 : -135)), 0, "XYZ")
      );
    });
  }

  resize(
    mainViewportDimensions,
    viewGasViewportDimensions,
    view360ViewportDemensions
  ) {
    this.mainViewportDimensions = mainViewportDimensions;
    this.viewGasViewportDimensions = viewGasViewportDimensions;
    this.view360ViewportDemensions = view360ViewportDemensions;

    this.mainCamera.resize(mainViewportDimensions);
    this.gasCamera.resize(viewGasViewportDimensions);

    this.view360Cameras?.forEach((camera, i) =>
      camera.resize(this.view360ViewportDemensions[i])
    );
  }
}

export default SceneCameras;
