import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography, styled } from "@mui/material";

import { WindRainToggle } from "./components/WindRainToggle";
import { EffectIntensitySlider } from "./components/EffectIntensitySlider";
import PageTransition from "../../common/components/pageTransition/PageTransition";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";
import { getHotspotPoint } from "../../common/utils/getHotspotPoint";
import { useResponsive } from "../../common/hooks/useResponsive";

type Hotspot = {
  x: number;
  y: number;
};

const WindPage = (): JSX.Element => {
  const { threeScene, sceneViewport } = useContext(SceneAPIContext);
  const [isWind, setIsWind] = useState<boolean>(true);
  const [sliderValue, setSliderValue] = useState(0.5);
  const [loaded, setLoaded] = useState(false);

  const [hotspotPoint, setHotspotPoint] = useState<Hotspot>({ x: 0, y: 0 });

  const navigate = useNavigate();
  const { isLargeDevice } = useResponsive();

  const openNextPage = (): void => {
    navigate("/gimbal-stabilisation/details");
  };

  const setIntensity = (isWind: boolean, sliderValue: number): void => {
    if (isWind) {
      threeScene.sceneAnimation.windEffect.setIntensity(sliderValue);
    } else {
      threeScene.sceneAnimation.rainEffect.setIntensity(sliderValue);
    }
  };

  const onSwitchChange = (isChecked: boolean): void => {
    setIsWind(isChecked);

    setEffect(isChecked);
    setIntensity(isChecked, sliderValue);
  };

  const onSliderChange = (value: number): void => {
    const sliderValue = value;
    setSliderValue(sliderValue);

    setIntensity(isWind, sliderValue);
  };

  const setEffect = (isWind: boolean): void => {
    if (isWind) {
      threeScene.sceneAnimation.windEffect.start();
      threeScene.sceneAnimation.rainEffect.stop();
    } else {
      threeScene.sceneAnimation.windEffect.stop();
      threeScene.sceneAnimation.rainEffect.start();
    }
  };

  const getHotspotPosition = (): void => {
    const point = threeScene.sceneObjects.getObjectByName(
      Scene3DObjectsNames.MinervaCameraHandle
    ).children[0].children[0].children[0].children[3];

    const windowPosition =
      threeScene.sceneObjects.getObjectWindowPosition(point);

    const position = getHotspotPoint(
      windowPosition,
      sceneViewport.current.offsetHeight
    );

    setHotspotPoint(position);
  };

  const onComplete = (): void => {
    getHotspotPosition();
    setLoaded(true);

    setEffect(isWind);
    setIntensity(isWind, sliderValue);
  };

  useEffect((): (() => void) => {
    setLoaded(false);
    if (threeScene) {
      threeScene.loadSceneStage(
        "wind",
        isLargeDevice ? "desktop" : "mobile",
        1000,
        onComplete
      );
      threeScene.sceneAnimation.rainEffect.init(true);
      threeScene.sceneAnimation.windEffect.init();
    }

    return () => {
      if (threeScene) {
        threeScene.sceneAnimation.rainEffect.setIntensity(0);
        threeScene.sceneAnimation.windEffect.setIntensity(0);

        threeScene.sceneAnimation.windEffect.stop(true);
        threeScene.sceneAnimation.rainEffect.stop(true);
      }
    };
  }, [isLargeDevice]);

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      setLoaded(false);

      onComplete();
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageTransition>
      <PageWrapper p={6} gap={6}>
        <Typography variant="h3">Gimbal stabilisation</Typography>
        <ToolsWrapper>
          <WindRainToggle onChange={onSwitchChange} isWind={isWind} />
          <EffectIntensitySlider
            value={sliderValue}
            onChange={onSliderChange}
          />
        </ToolsWrapper>
        {loaded && (
          <HotspotPoint
            x={hotspotPoint.x}
            y={hotspotPoint.y}
            onClick={openNextPage}
          />
        )}
      </PageWrapper>
    </PageTransition>
  );
};

const PageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 80px)",
  display: "flex",
  pointerEvents: "auto",
  justifyContent: "center",

  "& h3": {
    position: "absolute",
    left: 0,
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down("tablet")]: {
      paddingLeft: theme.spacing(4),
    },
  },
  position: "relative",

  padding: theme.spacing(6),

  [theme.breakpoints.down("tablet")]: {
    padding: theme.spacing(4),
  },
}));

const ToolsWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  background: theme.palette.primary["grey-200"],
  width: "676px",
  height: "64px",
  borderRadius: "100px",
  alignSelf: "center",
  marginTop: "auto",
  padding: theme.spacing(2),
  gap: theme.spacing(2),

  [theme.breakpoints.down("tablet")]: {
    width: "100%",
    justifyContent: "space-between",
  },
}));
const HotspotPoint = styled(Box)<{ x: number; y: number }>(
  ({ theme, x, y }) => ({
    position: "absolute",
    background: theme.palette.primary["brand-orange"],
    cursor: "pointer",

    top: y,
    left: x,
    width: "16px",
    height: "16px",
    borderRadius: "100%",
  })
);

export default WindPage;
