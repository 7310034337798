import { useContext, useEffect, useRef } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";

import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { useResponsive } from "../../common/hooks/useResponsive";

const NvidiaPageDetails = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);
  const { isExtraLargeDevice } = useResponsive();

  const wrapperRef = useRef<HTMLBaseElement>();

  const onAnimationComplete = (): void => {
    const object = threeScene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.MinervaCameraTopCover
    );

    const position = {
      x: object.initialPosition.x + 0.4,
      y: object.initialPosition.y,
      z: object.initialPosition.z,
    };

    threeScene.sceneTransitionAnimation.move.start(
      Scene3DObjectsNames.MinervaCameraTopCover,
      position
    );

    threeScene.sceneTransitionAnimation.opacity.start(0.5);
  };

  useEffect((): (() => void) => {
    if (threeScene) {
      threeScene.loadSceneStage(
        "nvidia2",
        isExtraLargeDevice ? "desktop" : "mobile",
        500,
        onAnimationComplete
      );
    }
    threeScene.sceneTransitionAnimation.opacity.init(
      Scene3DObjectsNames.MinervaCameraTopCover
    );
    return () => {
      threeScene.sceneTransitionAnimation.move.stop();
      threeScene.sceneTransitionAnimation.opacity.stop();
    };
  }, [isExtraLargeDevice]);

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      onAnimationComplete();
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <InfoBlock>
        <Typography variant="h5">
          High-Power Computing with NVIDIA AGX Orin
        </Typography>
        <Typography variant="body-400-18" component={"p"}>
          Minerva harnesses the cutting-edge power of NVIDIA&apos;s AGX Orin
          systems, boasting a scalable computing architecture capable of housing
          up to four AGX units.
        </Typography>
      </InfoBlock>
    </Wrapper>
  );
};

export default NvidiaPageDetails;

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",
  padding: theme.spacing(6),
  [theme.breakpoints.down("laptop")]: {
    padding: theme.spacing(4),
  },
}));

const InfoBlock = styled(Stack)(({ theme }) => ({
  background: theme.palette.primary["grey-200"],
  padding: theme.spacing(10),
  width: "30%",
  height: "fit-content",
  maxHeight: "100%",
  overflowY: "auto",
  pointerEvents: "auto",

  borderRadius: "16px",
  gap: theme.spacing(4),
  "& p": {
    color: theme.palette.primary["text-secondary"],
  },

  [theme.breakpoints.down("desktop")]: {
    gap: theme.spacing(2),
    padding: theme.spacing(6),
  },

  [theme.breakpoints.down("laptop")]: {
    width: "100%",
    marginTop: "auto",
  },
}));
