import { useState } from "react";

import ConfiguratorProgressBarContext from "./ConfiguratorProgressBarContext";

const ConfiguratorProgressBarContextProvider = ({ children }) => {
  const configuratorStepsData = [
    { stepName: "quiz", stepNumber: 1 },
    { stepName: "preview", stepNumber: 2 },
    { stepName: "factory", stepNumber: 3 },
    { stepName: "map", stepNumber: 4 },
  ];

  const [currentStep, setCurrentStep] = useState(
    configuratorStepsData[0].stepNumber
  );
  const stepsAmount = configuratorStepsData.length;

  const handleSetCurrentStep = (stepName = "quiz") => {
    const stepNumber = configuratorStepsData.find(
      stepData => stepData.stepName === stepName
    ).stepNumber;

    setCurrentStep(stepNumber);
  };

  return (
    <ConfiguratorProgressBarContext.Provider
      value={{
        configuratorStepsData,
        currentStep,
        stepsAmount,
        handleSetCurrentStep,
      }}
    >
      {children}
    </ConfiguratorProgressBarContext.Provider>
  );
};

export default ConfiguratorProgressBarContextProvider;
