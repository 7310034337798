import { useContext, useEffect } from "react";
import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { Stack, Typography, styled } from "@mui/material";

import PageTransition from "../../common/components/pageTransition/PageTransition";
import { FeatureData } from "./components/FeatureData";
import FeatureLine from "./components/FeatureLine";

import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";
import { useResponsive } from "../../common/hooks/useResponsive";

const AllInOne = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);

  const { isExtraLargeDevice } = useResponsive();

  const onComplete = (): void => {
    threeScene.sceneTransitionAnimation.opacity.start(1);
  };

  useEffect((): (() => void) => {
    if (threeScene) {
      threeScene.loadSceneStage(
        "allInOne",
        isExtraLargeDevice ? "desktop" : "mobile",
        1000,
        onComplete
      );
      threeScene.sceneTransitionAnimation.opacity.init(
        Scene3DObjectsNames.BlackCircle
      );
    }
    return () => {
      threeScene.sceneTransitionAnimation.opacity.stop();
    };
  }, [isExtraLargeDevice]);

  return (
    <PageTransition>
      <Wrapper p={6} gap={6}>
        <Typography variant="h3">All in one communication</Typography>
        <Container>
          {FeatureData.map((el, i) => (
            <FeatureLine
              key={i}
              title={el.title}
              subtitle={el.subtitle}
              icon={el.icon}
            />
          ))}
        </Container>
      </Wrapper>
    </PageTransition>
  );
};

export default AllInOne;

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 80px)",
  pointerEvents: "auto",
  position: "relative",
  padding: theme.spacing(6),
  gap: theme.spacing(6),
  justifyContent: "flex-end",

  "& h3": {
    color: theme.palette.primary.main,
  },

  [theme.breakpoints.down("tablet")]: {
    padding: theme.spacing(4),
    gap: theme.spacing(4),
  },
}));

const Container = styled(Stack)(({ theme }) => ({
  width: "40%",
  height: "100%",
  justifyContent: "space-between",
  alignItems: "flex-end",

  [theme.breakpoints.down("laptop")]: {
    width: "100%",
    height: "40%",
    marginTop: "auto",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    overflow: "auto",
  },
}));
