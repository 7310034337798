import { useEffect } from "react";
import { useAnimate, usePresence } from "framer-motion";
import { Stack, styled } from "@mui/material";
import { useNavigationContext } from "../../context/navigationContext/NavigationContext";

const PageTransition = ({ children }) => {
  const [scope, animate] = useAnimate();
  const [isPresent, safeToRemove] = usePresence();

  const { activeIndex, prevIndex } = useNavigationContext();

  useEffect(() => {
    const animateIn =
      prevIndex - activeIndex > 0 ? ["-100%", "0%"] : ["100%", "0%"];
    const animateOut =
      prevIndex - activeIndex > 0 ? ["0%", "100%"] : ["0%", "-100%"];

    if (isPresent) {
      const enterAnimation = () => {
        animate(
          scope.current,
          { top: animateIn },
          { opacity: ["0%", "100%"] },
          { duration: 0.5 }
        );
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { top: animateOut },
          { opacity: ["100%", "0%"] },
          { duration: 0.5 }
        );

        safeToRemove();
      };

      exitAnimation();
    }
  }, [isPresent]);

  return <Wrapper ref={scope}>{children}</Wrapper>;
};

export default PageTransition;

const Wrapper = styled(Stack)(() => ({
  top: "100%",
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));
