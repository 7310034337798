import { Box, Button, Stack, styled } from "@mui/material";

const CameraViewToggler = ({ pageState, handleToggler }) => {
  return (
    <Wrapper>
      <TogglerBackground translate={pageState ? 1 : 0} />
      <CameraViewButton
        variant="text"
        color="button-tertiary"
        onClick={handleToggler}
      >
        Bottom view
      </CameraViewButton>
      <CameraViewButton
        variant="text"
        color="button-tertiary"
        onClick={handleToggler}
      >
        Front view
      </CameraViewButton>
    </Wrapper>
  );
};

export default CameraViewToggler;

const Wrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(6),
  left: 0,
  right: 0,
  margin: "auto",
  width: "350px",
  height: "65px",
  borderRadius: "100px",
  background: theme.palette.primary["grey-100"],
  gap: theme.spacing(2),
  flexDirection: "row",
  padding: theme.spacing(2),
}));

const CameraViewButton = styled(Button)(({ theme, color }) => ({
  zIndex: 1,
  textAlign: "center",
  flex: 1,

  "&:hover": {
    color: theme.palette[color].main,
  },

  "&:active": {
    color: theme.palette[color].main,
  },
}));

const TogglerBackground = styled(Box)(({ theme, translate }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  width: `calc(50% - ${theme.spacing(3)})`,
  height: `calc(100% - ${theme.spacing(4)})`,
  borderRadius: "100px",
  backgroundColor: theme.palette["button-secondary"].main,
  transform: `translateX(${translate ? "0%" : "105%"})`,
  transition: "transform 0.3s",
}));
