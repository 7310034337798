import { Vector3 } from "three";
import { Easing, Tween } from "@tweenjs/tween.js";

import { Scene3DObjectsNames } from "../SceneObjectsPathData";

class SceneMoveAnimation {
  constructor(scene) {
    this.scene = scene;
    this.CB;
    this.object;
    this.destination;
    this.transitionSpeed;
  }

  transitionAnimation = new Tween(null)
    .to({}, this.transitionSpeed)
    .onUpdate((_, alpha) => {
      this.object.setObjectPosition(
        new Vector3(
          this.destination.x * alpha,
          this.destination.y * alpha,
          this.destination.z * alpha
        )
      );
    })
    .easing(Easing.Cubic.In);

  hideAnimation = new Tween(null)
    .to({}, this.transitionSpeed)
    .easing(Easing.Quadratic.In)
    .onStart(() => {
      this.transitionAnimation.isPlaying() && this.transitionAnimation.stop();
    })

    .onUpdate((_, alpha) => {
      this.object.setObjectPosition(
        new Vector3(
          this.object.object.position.x -
            this.object.object.position.x * alpha +
            this.object.initialPosition.x,
          this.object.object.position.y -
            this.object.object.position.y * alpha +
            this.object.initialPosition.y,
          this.object.object.position.z -
            this.object.object.position.z * alpha +
            this.object.initialPosition.z
        )
      );
    });

  start(objectName, destination, CB = () => {}, transitionSpeed = 1000) {
    this.CB = CB;
    this.transitionSpeed = transitionSpeed;
    this.object = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames[objectName]
    );
    this.destination = destination;

    this.hideAnimation.isPlaying() && this.hideAnimation.stop();
    !this.transitionAnimation.isPlaying() && this.transitionAnimation.start();
  }

  stop(transitionSpeed = 2000) {
    this.transitionSpeed = transitionSpeed;

    this.object && this.hideAnimation.start();
  }

  update() {
    if (this.hideAnimation && this.hideAnimation.isPlaying()) {
      this.hideAnimation.update();
      this.scene.sceneRenderers.toggleMainViewRendering(true);
    }

    if (this.transitionAnimation && this.transitionAnimation.isPlaying()) {
      this.transitionAnimation.update();
      this.scene.sceneRenderers.toggleMainViewRendering(true);
    }
  }
}

export default SceneMoveAnimation;
