import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import IconPrevButton from "../../../common/components/navigationButtons/IconPrevButton";
import SecureSpotButton from "./SecureSpotButton";

const DetilsNavigation = (): JSX.Element => {
  const navigate = useNavigate();

  const handlePrevButtonClick = (): void => {
    navigate(-1);
  };

  return (
    <ButtonsWrapper>
      <IconPrevButton onClick={handlePrevButtonClick} />
      <SecureSpotButton />
    </ButtonsWrapper>
  );
};

export default DetilsNavigation;

const ButtonsWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "16px",
  })
);
