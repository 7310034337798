import { FormControl, InputAdornment, TextField, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

interface TextInputPropsInterface {
  placeholder: string;
  type: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

const AutocompleteInput = ({
  placeholder,
  type,
  startIcon,
  endIcon,
}: TextInputPropsInterface): JSX.Element => {
  return (
    <StyledFormControl fullWidth>
      <StyledTextField
        hiddenLabel
        placeholder={placeholder}
        variant="standard"
        type={type}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
          disableUnderline: true,
        }}
      ></StyledTextField>
    </StyledFormControl>
  );
};

export default AutocompleteInput;

const StyledFormControl = styled(FormControl)(
  ({ theme }): CSSProperties => ({
    position: "relative",
    height: "48px",
    padding: "14px 0px",
    borderBottom: `1px solid ${theme.palette.primary["base-border"]}`,
  })
);

const StyledTextField = styled(TextField)(
  ({ theme }): CSSProperties => ({
    border: 0,

    "& input": {
      padding: "0px",

      color: theme.palette.primary["text-primary"],
      fontFamily: "Hubot-Sans-Normal-400",
      fontSize: "16px",
      fontStyle: "normal",
      lineHeight: "150%",

      "&::placeholder": {
        color: theme.palette.primary["text-secondary"],
        opacity: 1,
      },

      "&:-webkit-autofill": {
        backgroundColor: "unset !important",
        color: `${theme.palette.primary["text-primary"]} !important`,
        fontSize: "16px !important",
        transition: "background-color 0s 600000s, color 0s 600000s",
      },

      "&:-webkit-autofill:focus": {
        backgroundColor: "unset !important",
        color: `${theme.palette.primary["text-primary"]} !important`,
        fontSize: "16px !important",
        transition: "background-color 0s 600000s, color 0s 600000s",
      },

      [theme.breakpoints.down("laptop")]: {
        fontSize: "18px",
      },
    },
  })
);
