import { Vector3 } from "three";

import { Scene3DObjectsNames } from "../sceneObjects/SceneObjectsPathData";

export const SceneStageData = {
  home: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
      ],
      hideObjects: [
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,

        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.5,
            min: 1.5,
            max: 1.5,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 82,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 3,
            min: 3,
            max: 3,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 82,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },
  preview: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
      ],
      hideObjects: [
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.5,
            min: 1.5,
            max: 1.5,
          },
          azimuthAngleInDeg: {
            value: -60,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 82,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 3,
            min: 3,
            max: 3,
          },
          azimuthAngleInDeg: {
            value: -60,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 82,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },
  factory: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        mapTiles: true,
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.Factory,
            subVector: new Vector3(0, 10, -15),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 80,
            min: 1,
            max: 120,
          },
          azimuthAngleInDeg: {
            value: -90,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 70,
            min: 0,
            max: 75,
          },
        },
        transformControl: {
          enabled: true,
        },
      },
      mobile: {
        mapTiles: true,
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.Factory,
            subVector: new Vector3(0, 10, -15),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 150,
            min: 8,
            max: 150,
          },
          azimuthAngleInDeg: {
            value: -90,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 70,
            min: 0,
            max: 75,
          },
        },
        transformControl: {
          enabled: true,
        },
      },
    },
  },
  map: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        mapTiles: true,
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.Factory,
            subVector: new Vector3(0, 9, 0),
            vector: new Vector3(200, 400, -340),
          },
          distance: {
            value: 250,
            min: 20,
            max: 500,
          },
          azimuthAngleInDeg: {
            value: -90,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 70,
            min: 0,
            max: 75,
          },
        },
        transformControl: {
          enabled: true,
        },
      },
      mobile: {
        mapTiles: true,
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.Factory,
            subVector: new Vector3(0, 9, 0),
            vector: new Vector3(200, 400, -340),
          },
          distance: {
            value: 250,
            min: 20,
            max: 500,
          },
          azimuthAngleInDeg: {
            value: -90,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 70,
            min: 0,
            max: 75,
          },
        },
        transformControl: {
          enabled: true,
        },
      },
    },
  },
  hide: {
    sceneObjects: {
      showObjects: [],
      hideObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2500,
            min: 2500,
            max: 2500,
          },
          azimuthAngleInDeg: {
            value: -60,
            min: -65,
            max: -55,
          },
          polarAngleInDeg: {
            value: 82,
            min: 77,
            max: 87,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2500,
            min: 2500,
            max: 2500,
          },
          azimuthAngleInDeg: {
            value: -60,
            min: -65,
            max: -55,
          },
          polarAngleInDeg: {
            value: 82,
            min: 77,
            max: 87,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },

  cameraView: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,

        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        mapTiles: false,
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.Circle,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 250,
            min: 8,
            max: 250,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 0,
            min: 0,
            max: 0,
          },
        },
        transformControl: {
          enabled: true,
        },
      },
      mobile: {
        mapTiles: false,
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.Circle,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 400,
            min: 8,
            max: 600,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 0,
            min: 0,
            max: 0,
          },
        },
        transformControl: {
          enabled: true,
        },
      },
    },
  },

  cameraView2: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,

        Scene3DObjectsNames.PointsViews,
      ],
      hideObjects: [
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.Circle,

        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0.1, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.5,
            min: 1.5,
            max: 1.5,
          },
          azimuthAngleInDeg: {
            value: 90,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 180,
            min: -180,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0.2, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 3,
            min: 3,
            max: 3,
          },
          azimuthAngleInDeg: {
            value: 90,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 180,
            min: -180,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },

  cameraView3: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.PointsCameraViews,
      ],
      hideObjects: [
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.BlackCircle2,
            subVector: new Vector3(0.3, -0.07, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 0.95,
            min: 0.95,
            max: 0.95,
          },
          azimuthAngleInDeg: {
            value: -30,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 82,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.GasCamera35mm,
            subVector: new Vector3(0.3, 0.3, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2,
            min: 2,
            max: 2,
          },
          azimuthAngleInDeg: {
            value: -30,

            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 82,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },

  gasCamera: {
    sceneObjects: {
      showObjects: [Scene3DObjectsNames.Factory],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,

        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        mapTiles: false,
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.Factory,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 60,
            min: 8,
            max: 120,
          },
          azimuthAngleInDeg: {
            value: -90,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 70,
            min: 0,
            max: 75,
          },
        },
        transformControl: {
          enabled: true,
        },
      },
      mobile: {
        mapTiles: false,
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.Circle,
            subVector: new Vector3(-40, 10, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 300,
            min: 8,
            max: 600,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 0,
            min: 0,
            max: 0,
          },
        },
        transformControl: {
          enabled: true,
        },
      },
    },
  },

  allInOne: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.PointsViews,
      ],
      hideObjects: [
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.BlackCircle,
            subVector: new Vector3(0.2, -0.05, 0.4),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2.2,
            min: 2.2,
            max: 2.2,
          },
          azimuthAngleInDeg: {
            value: -35,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 90,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0.2, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 3,
            min: 3,
            max: 3,
          },
          azimuthAngleInDeg: {
            value: -45,
            min: 0,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 82,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },

  temperature: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, -0.1, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.5,
            min: 1.5,
            max: 1.5,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: 0,
            max: 0,
          },
          polarAngleInDeg: {
            value: 90,
            min: 90,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0.2, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 3,
            min: 3,
            max: 3,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: 0,
            max: 0,
          },
          polarAngleInDeg: {
            value: 90,
            min: 90,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },
  dust: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.MinervaCameraHandle,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, -0.15, -0.8),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1,
            min: 1,
            max: 1,
          },
          azimuthAngleInDeg: {
            value: -60,
            min: -60,
            max: -60,
          },
          polarAngleInDeg: {
            value: 90,
            min: 90,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      tablet: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, -0.05, -0.8),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2,
            min: 1,
            max: 3,
          },
          azimuthAngleInDeg: {
            value: -60,
            min: -60,
            max: -60,
          },
          polarAngleInDeg: {
            value: 90,
            min: 90,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0.1, -0.3),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2.8,
            min: 2.8,
            max: 2.8,
          },
          azimuthAngleInDeg: {
            value: -55,
            min: -60,
            max: 60,
          },
          polarAngleInDeg: {
            value: 90,
            min: 90,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },
  wind: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.MinervaCameraHandle,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, -0.1, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.7,
            min: 1.7,
            max: 1.7,
          },
          azimuthAngleInDeg: {
            value: -45,
            min: -45,
            max: 45,
          },
          polarAngleInDeg: {
            value: 90,
            min: -90,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2.5,
            min: 2.5,
            max: 2.5,
          },
          azimuthAngleInDeg: {
            value: -45,
            min: -45,
            max: 45,
          },
          polarAngleInDeg: {
            value: 90,
            min: -90,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },
  wind2: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, -0.2, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.1,
            min: 1.1,
            max: 1.1,
          },
          azimuthAngleInDeg: {
            value: 50,
            min: -45,
            max: 65,
          },
          polarAngleInDeg: {
            value: 90,
            min: -90,
            max: 100,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(-0.1, -0.6, 0.1),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2.2,
            min: 2.2,
            max: 2.2,
          },
          azimuthAngleInDeg: {
            value: 60,
            min: -45,
            max: 65,
          },
          polarAngleInDeg: {
            value: 110,
            min: -90,
            max: 110,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },

  nvidia: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.PointsViews,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
        Scene3DObjectsNames.MinervaCameraHandle,

        Scene3DObjectsNames.MinervaCameraGimbalSystem,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.5,
            min: 1.5,
            max: 1.5,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 0,
            min: 0,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2.2,
            min: 2.2,
            max: 2.2,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 0,
            min: 0,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },
  nvidia2: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
        Scene3DObjectsNames.PointsViews,
      ],
      hideObjects: [
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
        Scene3DObjectsNames.MinervaCameraHandle,

        Scene3DObjectsNames.MinervaCameraGimbalSystem,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0.2, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.5,
            min: 1.5,
            max: 1.5,
          },
          azimuthAngleInDeg: {
            value: 0,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 0,
            min: 0,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: false,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0.3, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 2.2,
            min: 2.2,
            max: 2.2,
          },
          azimuthAngleInDeg: {
            value: -90,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 0,
            min: 0,
            max: 90,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },

  replaceDetails: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
      ],
      hideObjects: [
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, -0.15, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1.5,
            min: 1.5,
            max: 1.5,
          },
          azimuthAngleInDeg: {
            value: 40,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 100,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 3,
            min: 3,
            max: 3,
          },
          azimuthAngleInDeg: {
            value: 40,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 100,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },

  replaceCamera: {
    sceneObjects: {
      showObjects: [
        Scene3DObjectsNames.MinervaCameraCase,
        Scene3DObjectsNames.MinervaCameraTopCover,
        Scene3DObjectsNames.MinervaCameraHandle,
        Scene3DObjectsNames.MinervaCameraGimbalSystem,
        Scene3DObjectsNames.MinervaCameraSingleCamera,
        Scene3DObjectsNames.MinervaCameraInvidiaGPU,
        Scene3DObjectsNames.GasCameraHandle,
        Scene3DObjectsNames.GasCamera35mm,
      ],
      hideObjects: [
        Scene3DObjectsNames.Factory,
        Scene3DObjectsNames.Tower,
        Scene3DObjectsNames.WindFlow,
        Scene3DObjectsNames.MinervaTemperature,
        Scene3DObjectsNames.DustScroll,
        Scene3DObjectsNames.RainScroll,
        Scene3DObjectsNames.RainSkydrops,
        Scene3DObjectsNames.RainWaterdrops,
        Scene3DObjectsNames.WindScroll,
        Scene3DObjectsNames.Circle,
        Scene3DObjectsNames.PointsViews,
        Scene3DObjectsNames.PointsCameraViews,
        Scene3DObjectsNames.Firegas01,
        Scene3DObjectsNames.Firegas02,
        Scene3DObjectsNames.Firegas03,
        Scene3DObjectsNames.BlackCircle,
        Scene3DObjectsNames.BlackCircle1,
        Scene3DObjectsNames.BlackCircle2,
        Scene3DObjectsNames.PointsMountingPointers,
      ],
    },
    sceneControls: {
      desktop: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.GasCamera35mm,
            subVector: new Vector3(0.2, 0, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 0.5,
            min: 0.5,
            max: 0.5,
          },
          azimuthAngleInDeg: {
            value: -45,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 100,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
      mobile: {
        orbitControl: {
          enabled: true,
          target: {
            objectName: Scene3DObjectsNames.MinervaCameraCase,
            subVector: new Vector3(0, 0.3, 0),
            vector: new Vector3(0, 0, 0),
          },
          distance: {
            value: 1,
            min: 1,
            max: 1,
          },
          azimuthAngleInDeg: {
            value: -45,
            min: Infinity,
            max: Infinity,
          },
          polarAngleInDeg: {
            value: 100,
            min: 0,
            max: 180,
          },
        },
        transformControl: {
          enabled: false,
        },
      },
    },
  },
};
