import { ChangeEvent } from "react";
import { styled } from "@mui/material";

import { useBrowser } from "../hooks/useBrowser";

type Props = {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRelease: () => void;
};

const StyledSlider = ({
  min,
  max,
  step,
  value,
  onChange,
  onRelease,
}: Props): JSX.Element => {
  const { isSafari } = useBrowser();

  return (
    <Slider
      outlined={isSafari ? 0 : 1}
      type="range"
      min={min}
      max={max}
      value={value}
      step={step}
      onChange={onChange}
      onMouseUp={onRelease}
      onTouchEnd={onRelease}
      onMouseLeave={onRelease}
    />
  );
};

export default StyledSlider;

const Slider = styled("input")<{ outlined: number }>(({ theme, outlined }) => ({
  pointerEvents: "auto",
  width: "100%",
  height: 50,
  webkitAppearance: "none",
  appearance: "none",
  background: "transparent",
  cursor: "pointer",

  "&:focus": {
    outline: "none",
  },
  "&::-webkit-slider-runnable-track": {
    backgroundColor: theme.palette.primary["base-border"],
  },
  "&::-webkit-slider-thumb": {
    WebkitAppearance: "none",
    appearance: "none",
    marginTop: "-3.5px",
    marginBottom: "-3.5px",
    backgroundColor: theme.palette["button-secondary"].contrastText,
    borderRadius: "100%",
    height: "8px",
    width: "8px",
    outline: `${outlined}px solid ${theme.palette["button-secondary"].contrastText}`,

    outlineOffset: "8px",
  },
  "&::-moz-range-track": {
    backgroundColor: theme.palette.primary["base-border"],
  },
  "&::-moz-range-thumb": {
    backgroundColor: theme.palette["button-secondary"].contrastText,
    border: "none",
    borderRadius: "100%",
    height: "8px",
    width: "8px",

    outline: `1px solid ${theme.palette["button-secondary"].contrastText}`,
    outlineOffset: "8px",
  },
}));
