export const ThemePalette = {
  primary: {
    main: "#000000",
    light: "#000000",
    dark: "#292929",
    contrastText: "#FFFFFF",
    "grey-hotspot": "rgba(142, 142, 142, 0.60)",
    "grey-100": "#FDFDFD",
    "grey-200": "#F9F9F9",
    "grey-300": "#EFEFEE",
    "grey-400": "#DBDBD9",
    "grey-500": "#C2C2BF",
    "grey-1300": "#13130C",
    "text-primary": "#13130C",
    "text-secondary": "#696960",
    "text-accent": "#B76741",
    "base-border": "#C2C2BF",
    "base-background": "#DBDBD9",
    "brand-orange": "#CF7549",
    hot: "#CF4949",
    cold: "#4977CF",
    error: "#FF291C",
    alert: "#CF4949",
  },

  "button-primary": {
    main: "#000000",
    light: "#000000",
    dark: "#292929",
    contrastText: "#FFFFFF",
  },

  "button-primary-icon": {
    main: "#000000",
    light: "#000000",
    dark: "#292929",
    contrastText: "#FFFFFF",
  },

  "button-secondary": {
    main: "rgba(0, 0, 0, 0.07)",
    light: "rgba(0, 0, 0, 0.07)",
    dark: "rgba(0, 0, 0, 0.12)",
    contrastText: "#13130C",
  },

  "button-secondary-icon": {
    main: "rgba(0, 0, 0, 0.07)",
    light: "rgba(0, 0, 0, 0.12)",
    dark: "rgba(0, 0, 0, 0.12)",
    contrastText: "#13130C",
  },

  "button-tertiary": {
    main: "#13130C",
    light: "#13130C",
    dark: "#696960",
    contrastText: "#13130C",
  },

  "button-tertiary-icon": {
    main: "#13130C",
    light: "#696960",
    dark: "#13130C",
    contrastText: "#13130C",
  },

  "button-ghost": {
    main: "#696960",
    light: "#13130C",
    dark: "#696960",
    contrastText: "#FFFFFF",
  },

  "button-ghost-icon": {
    main: "#696960",
    light: "#13130C",
    dark: "#696960",
    contrastText: "#FFFFFF",
  },
};
