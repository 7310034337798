import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import AnimatedTextButton from "../../../common/components/styledComponents/AnimatedTextButton";

const ThankYouNavigation = (): JSX.Element => {
  const navigate = useNavigate();

  const handleContactUsButtonClick = (): void => {};

  const handleOnToHomePageButtonClick = (): void => {
    navigate("/");
  };

  return (
    <ButtonsWrapper>
      <AnimatedTextButton
        variant="outlined"
        color="button-tertiary"
        onClick={handleContactUsButtonClick}
        endIcon={null}
      >
        Contact us
      </AnimatedTextButton>
      <AnimatedTextButton
        variant="contained"
        color="button-primary"
        onClick={handleOnToHomePageButtonClick}
        endIcon={null}
      >
        To homepage
      </AnimatedTextButton>
    </ButtonsWrapper>
  );
};

export default ThankYouNavigation;

const ButtonsWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "16px",
    [theme.breakpoints.down("tablet")]: {
      justifyContent: "space-between",
    },
  })
);
