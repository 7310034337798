import "@google/model-viewer/dist/model-viewer";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";

import model from "../../assets/3dmodel/AR-model/minervaAR.gltf";
import iosModel from "../../assets/3dmodel/AR-model/minervaAR.usdz";

import { ArrowRightIcon } from "../../assets/svgIcons/icon";
import ARIcon from "../../assets/svgIcons/ARIcon";

const ARModeButton = () => {
  return (
    <ModelViewerWrapper>
      <model-viewer
        className="model-viewer"
        src={model}
        ios-src={iosModel}
        ar
        ar-modes="webxr scene-viewer quick-look"
        ar-scale="fixed"
        camera-controls
        touch-action="pan-y"
        alt="A 3D model"
        xr-environment
      >
        <ButtonWrapper
          gap={3}
          justifyContent={"space-between"}
          slot="ar-button"
        >
          <ARIcon color="button-tertiary" />
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"center"}
          >
            <Typography variant="body-600-18" color={"primary"}>
              Use AR to view Minerva
            </Typography>

            <IconButton>
              <ArrowRightIcon />
            </IconButton>
          </Stack>
        </ButtonWrapper>
      </model-viewer>
    </ModelViewerWrapper>
  );
};

export default ARModeButton;

const ModelViewerWrapper = styled(Box)(() => ({
  "& model-viewer": {
    display: "flex",
    visibility: "hidden",
    width: "100%",

    overflow: "hidden",
    userSelect: "none",
    pointerEvents: "auto",
  },
}));

const ButtonWrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  visibility: "visible",
  width: "100%",
  background: theme.palette.primary["grey-400"],
  borderRadius: theme.spacing(3),
  padding: theme.spacing(4),
  color: theme.palette.primary["text - secondary"],
}));
