import { useLocation, useNavigate } from "react-router-dom";

import StyledIconButton from "../styledComponents/StyledIconButton";

import { CloseIcon } from "../../assets/svgIcons/icon";
import { styled } from "@mui/material";

const CloseConfiguratorButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = () => {
    location.pathname.includes("details")
      ? navigate(
          location.pathname
            .split("/")
            .filter(el => el !== "details")
            .join("/")
        )
      : navigate("/");
  };

  return (
    <CloseButton
      variant="contained"
      color="button-primary-icon"
      onClick={handleOnClick}
    >
      <CloseIcon />
    </CloseButton>
  );
};

export default CloseConfiguratorButton;

const CloseButton = styled(StyledIconButton)(({ theme }) => ({
  [theme.breakpoints.up("laptop")]: {
    "&:hover": {
      transition: "all 0.5s",
      background: "transparent",
      border: `1px solid ${theme.palette["button-primary-icon"].main}`,

      "& svg path": {
        transition: "all 0.5s",

        fill: theme.palette["button-primary-icon"].main,
      },
    },
  },
}));
