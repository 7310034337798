import { useContext, useEffect } from "react";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";

import SceneAPIContext from "../common/context/sceneAPIContext/SceneAPIContext";
import PageTransition from "../common/components/pageTransition/PageTransition";

const Page3 = () => {
  const { threeScene } = useContext(SceneAPIContext);
  const theme = useTheme();

  const laptop = useMediaQuery(theme.breakpoints.up("laptop"));

  useEffect(() => {
    if (threeScene) {
      threeScene.loadSceneStage("preview", laptop ? "desktop" : "mobile");
    }
  }, [laptop]);

  return (
    <PageTransition>
      <PageWrapper />
    </PageTransition>
  );
};

export default Page3;

const PageWrapper = styled(motion.div)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  padding: "140px 102px 0px 102px",
  justifyContent: "space-between",
}));
