import { EffectCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import FactoryToolbar from "../factoryPage/components/FactoryToolbar";
import FacilityNavigation from "./components/FacilityNavigation";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import ConfiguratorProgressBarContext from "../../common/context/configuratorProgressBarContext/ConfiguratorProgressBarContext";

import { RendererViewModeTypes } from "../../common/webgl/renderer/SceneRendererData";
import LocationMapContext, {
  LocationMapContextInterface,
} from "../../common/context/locationMapContext/LocationMapContext";
import Loader from "../../common/components/loader/Loader";

import { useResponsive } from "../../common/hooks/useResponsive";

const FacilityPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { isExtraLargeDevice } = useResponsive();

  const { threeScene, setActiveRendererViewMode, activeRendererViewMode } =
    useContext(SceneAPIContext);
  const { handleSetCurrentStep } = useContext(ConfiguratorProgressBarContext);
  const { mapCenterCoordinates } = useContext(
    LocationMapContext
  ) as LocationMapContextInterface;

  const [is3dMapLoaded, setIs3dMapLoaded] = useState<boolean>(false);
  const [map3dLoadingPercentage, setMap3dLoadingPercentage] =
    useState<number>(0);

  useEffect((): ReturnType<EffectCallback> => {
    if (mapCenterCoordinates === undefined) {
      navigate("/configurator/choose-location");
    } else if (threeScene) {
      threeScene.mapTilesRenderer.loadTiles(
        mapCenterCoordinates?.lat,
        mapCenterCoordinates?.lng,
        (isLoaded: boolean): void => setIs3dMapLoaded(isLoaded),
        (percentage: number): void => setMap3dLoadingPercentage(percentage)
      );

      threeScene.loadSceneStage(
        "map",
        isExtraLargeDevice ? "desktop" : "mobile",
        0
      );
      handleSetCurrentStep("map");
    }

    return (): void => {
      threeScene.mapTilesRenderer.dispose();

      setActiveRendererViewMode(RendererViewModeTypes.viewMain);

      threeScene.sceneRenderers.toggleGasViewRendering(false);
      threeScene.sceneRenderers.toggle360ViewRendering(false);

      threeScene.sceneControls.disableLookCameraControl();

      threeScene.sceneObjects.resetObjectsToInitialPositionAndRotation();
    };
  }, [isExtraLargeDevice]);

  return (
    <PageWrapper>
      <Title variant="h3">Test it out on your own location</Title>

      {is3dMapLoaded ? (
        <PageFooter>
          <FactoryToolbar />
          {activeRendererViewMode === RendererViewModeTypes.viewMain && (
            <FacilityNavigation />
          )}
        </PageFooter>
      ) : (
        <PageLoader>
          <Loader percentage={map3dLoadingPercentage} />
        </PageLoader>
      )}
    </PageWrapper>
  );
};

export default FacilityPage;

const PageWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
    padding: theme.spacing(0, 6, 8),
    [theme.breakpoints.down("laptop")]: {
      padding: theme.spacing(0, 6, 6),
    },
  })
);

const PageFooter = styled(Box)(
  (): CSSProperties => ({
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  })
);

const PageLoader = styled(Box)(
  ({ theme }): CSSProperties => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary["base-background"],
    pointerEvents: "auto",
  })
);

const Title = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: 0,
  [theme.breakpoints.down("laptop")]: {
    position: "absolute",
    top: theme.spacing(6),
    left: theme.spacing(6),
    right: theme.spacing(6),
  },
}));
