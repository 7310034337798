import { Box3, Euler, Mesh, Vector3 } from "three";

import SceneObject from "./SceneObject";

import { loadGltfAsset } from "../utils/AssetsLoader";

import {
  Scene3DObjectsData,
  Scene3DObjectsNames,
  SceneName,
} from "./SceneObjectsPathData";

class SceneObjects {
  constructor(threeScene) {
    this.threeScene = threeScene;
    this.scene = threeScene.scene;
    this.listOfSceneObjects = [];
  }

  async load(progressCallBack) {
    await this.loadSceneObjects3d(Scene3DObjectsData, progressCallBack);

    this.listOfSceneObjects.forEach(object => {
      object.initialBBoxCenter = object
        .getObjectBoundingBoxAndCenter()
        .center.clone();
    });
  }

  async loadSceneObjects3d(objects3dData, progressCallBack) {
    let counter = 0;

    progressCallBack(counter);

    const uploadedObjectsList = await Promise.all(
      objects3dData.map(async data => {
        const asset = await loadGltfAsset(data.path);

        ++counter;

        const objectsLoadedInPercentage =
          (counter / objects3dData.length) * 100;
        progressCallBack(objectsLoadedInPercentage);

        return { asset, data };
      })
    );

    uploadedObjectsList.sort((a, b) => a.data.order - b.data.order);

    uploadedObjectsList.forEach(({ asset, data }) => {
      const object = asset.scene;
      const animations = asset.animations;

      object.name = data.name;

      const parentObject = this.getObjectByName(data.parentName);
      parentObject.add(object);

      const sceneObjectInstance = new SceneObject(
        object,
        data.position,
        new Euler(0, 0, 0, "XYZ"),
        animations
      );

      this.listOfSceneObjects.push(sceneObjectInstance);
    });
  }

  getObjectWindowPosition(object) {
    const worldPosition = new Vector3();
    object.getWorldPosition(worldPosition);

    const windowPosition = worldPosition
      .clone()
      .project(this.threeScene.sceneCameras.mainCamera.camera);
    return windowPosition;
  }

  getObjectWindowPositionByName(name) {
    const object = this.getObjectByName(name);
    const windowPosition = this.getObjectWindowPosition(object);

    return windowPosition;
  }

  getObjectBoundingsWindowPositionByName(name) {
    const object = this.getObjectByName(name);

    const boundingBox = new Box3();

    boundingBox.setFromObject(object);

    const max = boundingBox.max;
    const min = boundingBox.min;

    const maxScreen = max
      .clone()
      .project(this.threeScene.sceneCameras.mainCamera.camera);

    const minScreen = min
      .clone()
      .project(this.threeScene.sceneCameras.mainCamera.camera);

    return {
      max: maxScreen,
      min: minScreen,
    };
  }

  getObjectByName(name) {
    return name === SceneName ? this.scene : this.scene.getObjectByName(name);
  }

  getSceneObjectInstanceByName(name) {
    return this.listOfSceneObjects.find(
      sceneObject => sceneObject.object.name === name
    );
  }

  resetObjectsToInitialPositionAndRotation() {
    this.listOfSceneObjects.forEach(objectInstance =>
      objectInstance.setInitialPositionAndRotation()
    );
  }

  setTowerToMapLocationPosition(vector) {
    const object = this.getSceneObjectInstanceByName(Scene3DObjectsNames.Tower);

    object.setObjectPosition(vector);
  }

  setAnisotropy() {
    this.scene.traverse(child => {
      child.frustumCulled = false;

      if (child instanceof Mesh && child.material && child.material.map) {
        child.material.map.anisotropy = 1;
      }
    });
  }
}

export default SceneObjects;
