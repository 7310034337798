import { useContext, useState } from "react";
import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";
import { Autocomplete } from "@react-google-maps/api";

import AutocompleteInput from "../../../common/components/formInputs/AutocompleteInput";

import { LocationIcon } from "../../../common/assets/svgIcons/icon";

import LocationMapContext, {
  LocationMapContextInterface,
} from "../../../common/context/locationMapContext/LocationMapContext";

const EnterLocationInput = (): JSX.Element => {
  const [searchResult, setSearchResult] =
    useState<google.maps.places.Autocomplete>();

  const { onSearchMapLocation } = useContext(
    LocationMapContext
  ) as LocationMapContextInterface;

  const onLoad = (autocomplete: google.maps.places.Autocomplete): void => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = (): void => {
    if (searchResult) {
      const place = searchResult.getPlace();

      if (place && place.name) {
        onSearchMapLocation(place.name);
      }
    }
  };

  return (
    <Wrapper>
      <Autocomplete
        className="enter-location-autocomplete"
        onPlaceChanged={onPlaceChanged}
        onLoad={onLoad}
      >
        <AutocompleteInput
          type="text"
          placeholder="Enter your location"
          endIcon={<LocationIcon />}
        />
      </Autocomplete>
    </Wrapper>
  );
};

export default EnterLocationInput;

const Wrapper = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "auto",

    "& .enter-location-autocomplete": {
      width: "100%",
    },

    ".pac-container": {
      margineTop: "20px",
    },
  })
);
