import { MathUtils, Vector3 } from "three";
import { TransformControls } from "three/addons/controls/TransformControls.js";

import { TransformControlsMode } from "./TransformControlData";

class CustomTransformControls extends TransformControls {
  constructor(camera, domElement) {
    super(camera, domElement);

    this.positionLimit = {
      enabled: true,
      min: new Vector3(0, 0, 0),
      max: new Vector3(0, 0, 0),
    };

    this.setSpace("local");

    this.addEventListener("objectChange", () => {
      this.onObjectChange();
    });
  }

  enableControls(controlledObject, settings, mode) {
    this.setPositionLimit(
      controlledObject.parent.children[0],
      settings.positionLimitSubVectors
    );
    this.setTransformMode(settings.axis[mode], mode);

    this.attach(controlledObject);
  }

  disableControls() {
    this.detach();
  }

  setPositionLimit(limitObject, positionLimitSubVectors) {
    this.positionLimit.enabled = positionLimitSubVectors.enabled;

    if (positionLimitSubVectors.enabled) {
      const { min, max } = limitObject.geometry.boundingBox;

      this.positionLimit.min.copy(min).add(positionLimitSubVectors.min);
      this.positionLimit.max.copy(max).sub(positionLimitSubVectors.max);
    }
  }

  setTransformMode(axis, mode) {
    this.showX = axis.x;
    this.showY = axis.y;
    this.showZ = axis.z;

    this.setMode(mode);
  }

  onObjectChange() {
    if (
      this.mode === TransformControlsMode.translateObject &&
      this.positionLimit.enabled
    ) {
      if (this.showX) {
        this.object.position.x = MathUtils.clamp(
          this.object.position.x,
          this.positionLimit.min.x,
          this.positionLimit.max.x
        );
      }

      if (this.showY) {
        this.object.position.y = MathUtils.clamp(
          this.object.position.y,
          this.positionLimit.min.y,
          this.positionLimit.max.y
        );
      }

      if (this.showZ) {
        this.object.position.z = MathUtils.clamp(
          this.object.position.z,
          this.positionLimit.min.z,
          this.positionLimit.max.z
        );
      }
    }
  }
}

export default CustomTransformControls;
