type PositionType = {
  x: number;
  y: number;
};

export const getHotspotPoint = (
  windowPosition: PositionType,
  clientHeight: number | undefined
): PositionType => {
  const diff = window.innerHeight - (clientHeight ? clientHeight : 0);

  const x = ((windowPosition.x + 1) / 2) * window.innerWidth;
  const y = ((-windowPosition.y + 1) / 2) * window.innerHeight - diff;

  return { x, y };
};
