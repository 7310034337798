import { useContext, useEffect } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import MinervaCard from "./MinervaCard";

import BuiltMinervaContext, {
  BuiltMinervaContextInterface,
} from "../../context/builtMinervaContext/BuiltMinervaContext";

const BuiltMinervas = (): JSX.Element => {
  const {
    builtMinervas,
    editMinervaBuilt,
    deleteMinervaBuilt,
    noCameraConfig,
    setNoCameraConfig,
  } = useContext(BuiltMinervaContext) as BuiltMinervaContextInterface;

  useEffect(() => {
    return () => {
      setNoCameraConfig(false);
    };
  }, []);

  return (
    <Wrapper>
      {noCameraConfig && (
        <Stack>
          <Typography>We need more information from you.</Typography>
          <Typography>
            Provide us your contacts, let&apos;s have a call.
          </Typography>
        </Stack>
      )}
      {builtMinervas.map(
        (data: any): JSX.Element => (
          <MinervaCard
            key={data.id}
            cardData={data}
            handleOnEdit={editMinervaBuilt}
            handleOnDelete={deleteMinervaBuilt}
          />
        )
      )}
    </Wrapper>
  );
};

export default BuiltMinervas;

const Wrapper = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    overflowY: "auto",
  })
);
