import { Easing, Tween } from "@tweenjs/tween.js";
import { AnimationMixer, MathUtils, Mesh } from "three";
import { Scene3DObjectsNames } from "../SceneObjectsPathData";

const clipsNames = {
  handle: "anim_gimbal-stabilization",
};

class Wind {
  tweenOpacitySide = 1;
  windShown = false;
  intensity = 0.5;

  MIN_ANIM_WIND_OPACITY = 0.8;
  MAX_WIND_OPACITY = 0.95;

  animationActions = {};

  showWindAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Quadratic.Out)
    .onUpdate((_, alpha) => {
      alpha *= this.intensity;
      const maxOpacity = this.MAX_WIND_OPACITY * this.intensity;
      alpha = MathUtils.clamp(alpha, 0, maxOpacity);
      this.windObject.setObjectOpacity(alpha);
    })
    .onComplete(() => {
      this.windShown = true;
    });

  hideWindAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Quadratic.In)
    .onStart(() => {
      this.windShown = false;
    })
    .onUpdate((_, alpha) => {
      alpha *= this.intensity;
      const maxOpacity = this.MAX_WIND_OPACITY * this.intensity;
      alpha = MathUtils.clamp(alpha, 0, maxOpacity);
      this.windObject.setObjectOpacity(maxOpacity - alpha);
    })
    .onComplete(() => {
      this.windAnimation.isPlaying() && this.windAnimation.stop();
    });

  windAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Linear.None)
    .repeat(Infinity)
    .onUpdate((_, alpha) => {
      const yoyoAlpha = this.tweenOpacitySide < 0 ? 1 - alpha : alpha;

      this.windObject.object.traverse(child => {
        if (child instanceof Mesh) {
          child.material.map.offset.x = alpha;
        }
      });

      if (this.windShown) {
        let minOpacity = this.MIN_ANIM_WIND_OPACITY * this.intensity;
        minOpacity = MathUtils.clamp(minOpacity, 0, 1);
        let maxOpacity = this.MAX_WIND_OPACITY * this.intensity;
        maxOpacity = MathUtils.clamp(maxOpacity, 0, 1);

        const opacity = MathUtils.mapLinear(
          yoyoAlpha,
          0,
          1,
          minOpacity,
          maxOpacity
        );

        this.windObject.setObjectOpacity(opacity);
      }
    })
    .onRepeat(() => {
      this.tweenOpacitySide *= -1;
    });

  constructor(scene) {
    this.scene = scene;
  }

  init() {
    this.windObject = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.WindScroll
    );

    this.handleObject = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.MinervaCameraHandle
    );

    this.handleMixer = new AnimationMixer(this.handleObject.object);

    // Raindrops clips.
    for (const clip of this.handleObject.animations) {
      this.animationActions[clip.name] = this.handleMixer.clipAction(clip);
      this.animationActions[clip.name].timeScale = 0.003;
    }
  }

  setIntensity(intensity) {
    this.intensity = intensity;

    const windAnimationDuration = MathUtils.mapLinear(
      intensity,
      0,
      1,
      1000,
      300
    );
    this.windAnimation.duration(windAnimationDuration);

    const timescale = MathUtils.lerp(0, 0.003, intensity);
    this.animationActions[clipsNames.handle].setEffectiveTimeScale(timescale);
  }

  start() {
    this.animationActions[clipsNames.handle].reset().play();

    this.showWindAnimation.isPlaying() && this.showWindAnimation.stop();
    this.hideWindAnimation.isPlaying() && this.hideWindAnimation.stop();
    !this.windAnimation.isPlaying() && this.windAnimation.start();

    this.showWindAnimation.start();
  }

  stop(isFinal = false) {
    this.showWindAnimation.isPlaying() && this.showWindAnimation.stop();
    this.animationActions[clipsNames.handle].stop();

    if (isFinal) {
      this.windAnimation.isPlaying() && this.windAnimation.stop();
    } else {
      this.hideWindAnimation.start();
    }
  }

  update(dt) {
    this.showWindAnimation &&
      this.showWindAnimation.isPlaying() &&
      this.showWindAnimation.update();

    this.hideWindAnimation &&
      this.hideWindAnimation.isPlaying() &&
      this.hideWindAnimation.update();

    if (this.windAnimation && this.windAnimation.isPlaying()) {
      this.windAnimation.update();
      this.handleMixer.update(dt);
      this.scene.sceneRenderers.toggleMainViewRendering(true);
    }
  }
}

export default Wind;
