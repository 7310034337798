import { useNavigate } from "react-router-dom";
import { Stack, Typography, keyframes, styled } from "@mui/material";

import ImageSrc from "../../../common/assets/images/notificationImage.png";
import LogoSrc from "../../../common/assets/images/claenConnectLogo.png";

type GasCameraNotificationType = {
  state: boolean;
  index: number;
};

const GasCameraNotification = ({
  index,
  state,
}: GasCameraNotificationType): JSX.Element => {
  const navigate = useNavigate();
  const navigateToPage = (): void => {
    navigate("/hazard-alarm-system");
  };
  return (
    <Wrapper
      onClick={navigateToPage}
      justifyContent={"center"}
      alignItems={"space-between"}
      className={state ? "visible" : "unvisible"}
      index={index}
      gap={4}
    >
      <TitleBlock>
        <Logo src={LogoSrc} />
      </TitleBlock>
      <Stack p={3} justifyContent={"space-between"}>
        <InfoBox>
          <Typography variant="h5">Fire Alert</Typography>
          <TextWrapper>
            <TextRow>
              <Typography component={"p"} variant="body-400-14">
                Location:
              </Typography>
              <Typography variant="body-400-14">Miami</Typography>
            </TextRow>
            <TextRow>
              <Typography component={"p"} variant="body-400-14">
                Event time:
              </Typography>
              <Typography variant="body-400-14">
                9:30 AM (MST), 05/27/23
              </Typography>
            </TextRow>
          </TextWrapper>
        </InfoBox>
        <Image src={ImageSrc} alt="Notification image" />
      </Stack>
    </Wrapper>
  );
};

export default GasCameraNotification;

const Wrapper = styled(Stack)<{ index: number }>(({ theme, index }) => ({
  position: "absolute",
  zIndex: index,
  bottom: 0,
  background: theme.palette.primary["grey-300"],

  borderRadius: "16px",

  backdropFilter: "blur(40px)",
  pointerEvents: "auto",
  cursor: "pointer",

  transition: "all 1s",
  "&.visible": {
    transition: "all 1s",
    opacity: 1,
    animation: `${notificatioOn} 1s `,
  },
  "&.unvisible": {
    animationDelay: "1s",
    animation: `${notificationOff} 1s `,
  },
}));

const TitleBlock = styled(Stack)(() => ({
  width: "100%",
  height: "50px",
  background: "#4CB050",
  borderRadius: "16px 16px 0px 0px",
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  maxHeight: "40%",
  objectFit: "contain",

  [theme.breakpoints.down("laptop")]: {
    display: "none",
  },
}));

const Logo = styled("img")(() => ({
  width: "100%",
  maxHeight: "100%",
  objectFit: "contain",
}));

const InfoBox = styled(Stack)(() => ({
  width: "100%",
}));

const TextWrapper = styled(Stack)(() => ({
  flex: 1,
}));

const TextRow = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  "& p": {
    color: theme.palette.text.secondary,
    width: "90px",
  },
}));

const notificatioOn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const notificationOff = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
