import { Vector3 } from "three";

import { Scene3DObjectsNames } from "../sceneObjects/SceneObjectsPathData";

export const TransformControlsMode = Object.freeze({
  translateObject: "translate",
  rotateObject: "rotate",
});

export const TransformControlsObjectType = Object.freeze({
  camera: "camera",
  towerOnFactory: "towerOnFactory",
  towerOnFacility: "towerOnFacility",
});

export const TransformControlsSettings = Object.freeze({
  camera: {
    controledObjectName: Scene3DObjectsNames.MinervaCameraHandle,
    settings: {
      axis: {
        [TransformControlsMode.translateObject]: {
          x: false,
          y: true,
          z: false,
        },
      },
      positionLimitSubVectors: {
        enabled: true,
        min: new Vector3(0, 0, 0),
        max: new Vector3(0, 0, 0),
      },
    },
  },
  towerOnFactory: {
    controledObjectName: Scene3DObjectsNames.Tower,
    settings: {
      axis: {
        [TransformControlsMode.translateObject]: { x: true, y: false, z: true },
        [TransformControlsMode.rotateObject]: { x: false, y: true, z: false },
      },
      positionLimitSubVectors: {
        enabled: true,
        min: new Vector3(0, 0, 0),
        max: new Vector3(0, 0, 0),
      },
    },
  },
  towerOnFacility: {
    controledObjectName: Scene3DObjectsNames.Tower,
    settings: {
      axis: {
        [TransformControlsMode.translateObject]: { x: true, y: false, z: true },
        [TransformControlsMode.rotateObject]: { x: false, y: true, z: false },
      },
      positionLimitSubVectors: {
        enabled: false,
        min: null,
        max: null,
      },
    },
  },
});
