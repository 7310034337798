import Temperature from "./Effects/Temperature";
import Dust from "./Effects/Dust";
import Rain from "./Effects/Rain";
import Wind from "./Effects/Wind";
import GasLeak from "./Effects/GasLeak";
import ReplaceSensor from "./Effects/ReplaceSensor";
import ReplaceCamera from "./Effects/ReplaceCamera";

class SceneAnimation {
  constructor(scene) {
    this.temperatureEffect = new Temperature(scene);
    this.dustEffect = new Dust(scene);
    this.rainEffect = new Rain(scene);
    this.windEffect = new Wind(scene);
    this.gasEffect = new GasLeak(scene);
    this.replaceSensor = new ReplaceSensor(scene);
    this.replaceCamera = new ReplaceCamera(scene);
  }

  update(dt) {
    this.temperatureEffect.update();
    this.dustEffect.update();
    this.rainEffect.update(dt);
    this.windEffect.update(dt);
    this.gasEffect.update(dt);
    this.replaceSensor.update(dt);
    this.replaceCamera.update(dt);
  }
}

export default SceneAnimation;
