import LayerContainer from "./LayerContainer";

import ThreeViewport from "../threeViewport/ThreeViewport";

const WebGLLayer = () => {
  return (
    <LayerContainer>
      <ThreeViewport />
    </LayerContainer>
  );
};

export default WebGLLayer;
