export const QuizData = [
  {
    id: "1",
    type: "single",
    question: "Facility type",
    answers: [
      {
        id: "1",
        type: "predefined",
        title: "Lower Producing",
        description:
          "This as facilities typically older than 7 years classed as legacy or brownfield",
        selected: false,
      },
      {
        id: "2",
        type: "predefined",
        title: "High Producing",
        description:
          "This as a facility in peak production that is not located in a sensitive area",
        selected: false,
      },
      {
        id: "3",
        type: "predefined",
        title: "High Producing & Highly Sensitive",
        description:
          "Facilities in urban and protected areas these are typically highly regulated by state and federal agencies",
        selected: false,
      },
      {
        id: "4",
        type: "predefined",
        title: "Industrial Facility",
        description:
          "Chemical Plant, Refinery, Pulp & Paper, Agg, Food & Bev, Dairy, Manufacturing, Water Treatment & Wastewater, Etc...",
        selected: false,
      },
    ],
  },
  {
    id: "2",
    type: "single",
    question: "Networks requirements",
    answers: [
      {
        id: "1",
        type: "predefined",
        title: "Cell/Starlink",
        description: null,
        selected: false,
      },
      {
        id: "2",
        type: "predefined",
        title: "LAN",
        description: "Local Area Network",
        selected: false,
      },
    ],
  },
  {
    id: "3",
    type: "multiple",
    question: "What visual agents will help you solve your problems?",
    answers: [
      {
        id: "1",
        type: "predefined",
        title: "No Bundle",
        description: null,
        selected: false,
        predefinedOptionsIdList: [],
      },
      {
        id: "2",
        type: "predefined",
        title: "Minerva Software Bundle",
        description: null,
        selected: false,
        predefinedOptionsIdList: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        id: "3",
        type: "predefined",
        title: "Minerva Software Bundle + Prove Zero",
        description: null,
        selected: false,
        predefinedOptionsIdList: ["1", "2", "3", "4", "5", "6", "7", "8"],
      },
      {
        id: "4",
        type: "custom",
        title: "Individual Technologies",
        optionData: [
          {
            id: "1",
            title: "Gas Leak",
            description:
              "Measure and Quantify your emissions to ease LDAR requirements and manage reporting",
            selected: false,
          },
          {
            id: "2",
            title: "Fire",
            description:
              "Get notified as soon as a fire starts for immediate response",
            selected: false,
          },
          {
            id: "3",
            title: "Smoke",
            description:
              "Get notified as soon as smoke is visible to avoid fines",
            selected: false,
          },
          {
            id: "4",
            title: "Gate Guard",
            description: "Know who is coming to the location and when",
            selected: false,
          },
          {
            id: "5",
            title: "Liquid Leak",
            description:
              "Get notified when a leak or popoff blows to maintain containment",
            selected: false,
          },
          {
            id: "6",
            title: "PPE",
            description:
              "Catch people not following your PPE requirements on locations",
            selected: false,
          },
          {
            id: "7",
            title: "Tank Level Monitoring",
            description: "Non-invasive tank level monitoring",
            selected: false,
          },
          {
            id: "8",
            title: "Prove Zero",
            description: "Monetize your proven lack of emissions",
            selected: false,
          },
        ],
      },
    ],
  },

  {
    id: "5",
    type: "single",
    question:
      "What is the expected range that you will be interested in monitoring at your facility?",
    answers: [
      {
        id: "1",
        type: "predefined",
        title: "120m or less",
        description: null,
        selected: false,
      },
      {
        id: "2",
        type: "predefined",
        title: "more then 120m",
        description: null,
        selected: false,
      },
    ],
  },
];
