import { forwardRef } from "react";

import { Box } from "@mui/material";

const Canvas = forwardRef(({ id }, ref) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& canvas": {
          width: "100% !important",
          height: "100% !important",
          pointerEvents: "auto",
        },
      }}
    >
      <canvas ref={ref} id={id}></canvas>
    </Box>
  );
});

export default Canvas;
