import { Box, styled } from "@mui/material";

import { LogoIcon } from "../../assets/svgIcons/LogoIcon";

const Loader = ({ percentage }) => {
  return (
    <LoaderContainer>
      <LoaderWrapper>
        <LogoIcon />
        <ProggresBarWrapper>
          <ProggresBar percentage={percentage} />
        </ProggresBarWrapper>
      </LoaderWrapper>
    </LoaderContainer>
  );
};

export default Loader;

const LoaderContainer = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexShrink: 0,
  padding: "40px 80px",
  gap: "24px",
  borderRadius: "10000px",
  backgroundColor: theme.palette.primary["base-background"],
  border: `1px solid ${theme.palette.primary["base-border"]}`,
  "& svg": {
    width: "270px",
  },
  [theme.breakpoints.down("tablet")]: {
    "& svg": {
      width: "190px",
    },
    padding: theme.spacing(7, 15),
  },
}));

const ProggresBarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  alignSelf: "stretch",
  height: "1.5px",
  width: "100%",
  backgroundColor: theme.palette.primary["base-border"],
}));

const ProggresBar = styled(Box)(({ theme, percentage }) => ({
  width: `${percentage}%`,
  height: "100%",
  backgroundColor: theme.palette.primary["grey-1300"],
}));
