import { Box, Typography, styled } from "@mui/material";

const MinervaCardSummary = ({ title, description }) => {
  return (
    <SummaryWrapper>
      <Title variant="h6">{title}</Title>
      <Description variant="body-400-16">{description}</Description>
    </SummaryWrapper>
  );
};

export default MinervaCardSummary;

const SummaryWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: 1,
  gap: "4px",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-primary"],
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-secondary"],
}));
