import { Route, Routes, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import HomePage from "../pages/homePage/HomePage";
import QuizPage from "../pages/quizPage/QuizPage";
import FactoryPage from "../pages/factoryPage/FactoryPage";
import QuizResultPage from "../pages/quizResultPage/QuizResultPage";
import MinervaPreviewPage from "../pages/minervaPreviewPage/MinervaPreviewPage";
import PreorderPage from "../pages/preorderPage/PreorderPage";
import ThankYouPage from "../pages/thankYouPage/ThankYouPage";
import ChooseLocationPage from "../pages/chooseLocationPage/ChooseLocationPage";
import FacilityPage from "../pages/facilityPage/FacilityPage";
import CameraView360Page from "../pages/cameraView360Page/CameraView360Page";
import CameraView360PageDetails from "../pages/cameraView360Page/CameraView360PageDetails";
import TemperaturePage from "../pages/temperaturePage/TemperaturePage";

import Page3 from "../pages/Page3";
import EnclosedCameraCasingPage from "../pages/enclosedCameraCasingPage/EnclosedCameraCasingPage";
import WindPage from "../pages/windPage/WindPage";
import GasCameraPage from "../pages/gasCameraPage/GasCameraPage";
import HazardAlarmSystem from "../pages/hazardAlarmSystem/HazardAlarmSystem";
import AllInOne from "../pages/allInOne/AllInOne";
import WindPageDetails from "../pages/windPage/WindPageDetails";
import NvidiaPage from "../pages/nvidiaPage/NvidiaPage";
import NvidiaPageDetails from "../pages/nvidiaPage/NvidiaPageDetails";
import EasyReplaceDetailsPage from "../pages/easyReplaceDetails/EasyReplaceDetailsPage";

const AppRoutes = (): JSX.Element => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="/configurator">
          <Route path="/configurator/quiz" element={<QuizPage />} />
          <Route
            path="/configurator/quiz-result"
            element={<QuizResultPage />}
          />
          <Route
            path="/configurator/minerva-preview"
            element={<MinervaPreviewPage />}
          />
          <Route path="/configurator/factory" element={<FactoryPage />} />
          <Route
            path="/configurator/choose-location"
            element={<ChooseLocationPage />}
          />
          <Route path="/configurator/facility" element={<FacilityPage />} />
        </Route>
        <Route path="/preorder">
          <Route path="/preorder/detail" element={<PreorderPage />}></Route>
          <Route path="/preorder/thankyou" element={<ThankYouPage />}></Route>
        </Route>
        <Route path="/preview" element={<Page3 />} />
        <Route path="/temperature" element={<TemperaturePage />} />
        <Route
          path="/enclosed-camera-casing"
          element={<EnclosedCameraCasingPage />}
        />
        <Route path="/360-camera-view" element={<CameraView360Page />} />
        <Route
          path="/360-camera-view/details"
          element={<CameraView360PageDetails />}
        />
        <Route path="/gimbal-stabilisation" element={<WindPage />} />
        <Route
          path="/gimbal-stabilisation/details"
          element={<WindPageDetails />}
        />

        <Route path="/gas-camera" element={<GasCameraPage />} />
        <Route path="/hazard-alarm-system" element={<HazardAlarmSystem />} />
        <Route path="/all-in-one-communication" element={<AllInOne />} />
        <Route path="/nvidia" element={<NvidiaPage />} />
        <Route path="/nvidia/details" element={<NvidiaPageDetails />} />
        <Route path="/easy-replace" element={<EasyReplaceDetailsPage />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
