import { MouseEventHandler } from "react";
import { Box, Button, Stack, styled } from "@mui/material";

type Props = {
  isDust: boolean;
  onChange: MouseEventHandler<HTMLButtonElement>;
};

export const DustRainToggle = ({ isDust, onChange }: Props): JSX.Element => {
  return (
    <Wrapper>
      <TogglerBackground translatevalue={isDust ? 1 : 0} />
      <ModeButton variant="text" color="button-tertiary" onClick={onChange}>
        Dust
      </ModeButton>
      <ModeButton variant="text" color="button-tertiary" onClick={onChange}>
        Rain
      </ModeButton>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  width: "260px",
  height: "64px",
  borderRadius: "100px",
  background: theme.palette.primary["grey-100"],
  gap: theme.spacing(2),
  flexDirection: "row",
  padding: theme.spacing(2),

  [theme.breakpoints.down("tablet")]: {
    height: "48px",
    width: "200px",
  },
}));

const ModeButton = styled(Button)<{ color: string }>(({ theme, color }) => ({
  zIndex: 1,
  textAlign: "center",
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",

  "&:hover": {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    color: theme.palette[color].main,
  },

  "&:active": {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    color: theme.palette[color].main,
  },
}));

const TogglerBackground = styled(Box)<{ translatevalue: 0 | 1 }>(
  ({ theme, translatevalue }) => ({
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    width: `calc(50% - ${theme.spacing(3)})`,
    height: `calc(100% - ${theme.spacing(4)})`,
    borderRadius: "100px",
    backgroundColor: theme.palette["button-secondary"].main,
    transform: `translateX(${translatevalue === 1 ? "0%" : "105%"})`,
    transition: "transform 0.3s",
  })
);
