import { Scene } from "three";

import { SceneName } from "../sceneObjects/SceneObjectsPathData";

export const initScene = () => {
  const scene = new Scene();
  scene.background = "transparent";
  scene.name = SceneName;
  return scene;
};
