import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography, styled } from "@mui/material";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import PageTransition from "../../common/components/pageTransition/PageTransition";

import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";
import { useResponsive } from "../../common/hooks/useResponsive";

import InfoIcon from "../../common/assets/svgIcons/InfoIcon";

type PointType = {
  x: number;
  y: number;
};

const CameraView360Page = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);
  const { isLargeDevice } = useResponsive();

  const navigate = useNavigate();

  const wrapper = useRef<HTMLElement>();

  const [poinA, setPoinA] = useState<PointType>({ x: 0, y: 0 });
  const [poinB, setPoinB] = useState<PointType>({ x: 0, y: 0 });
  const [hotspot, setHotspot] = useState(false);

  const findPointA = (): void => {
    if (!wrapper.current) {
      return;
    }

    const windowPosition =
      threeScene.sceneObjects.getObjectWindowPositionByName(
        Scene3DObjectsNames.Circle
      );

    const x = ((windowPosition.x + 1) / 2) * window.innerWidth;
    const y = ((-windowPosition.y + 1) / 2) * window.innerHeight;
    const diff = window.innerHeight - wrapper.current.clientHeight;

    setPoinA({
      x: x,
      y: y - diff,
    });

    setPoinB({
      x: x,
      y: y - diff,
    });
  };

  const findPointB = (): void => {
    const maxScreen =
      threeScene.sceneObjects.getObjectBoundingsWindowPositionByName(
        Scene3DObjectsNames.Circle
      ).max;

    const x = ((maxScreen.x + 1) / 2) * window.innerWidth;

    setPoinB(prev => {
      prev.x = x;
      return prev;
    });
  };

  const createHotspot = (): void => {
    findPointA();
    findPointB();
    setHotspot(true);
  };

  const toNextPage = (): void => {
    navigate("/360-camera-view/details");
  };

  useEffect((): void => {
    setHotspot(false);

    if (threeScene) {
      threeScene.loadSceneStage(
        "cameraView",
        isLargeDevice ? "desktop" : "mobile",
        1000,
        createHotspot
      );
    }
  }, [isLargeDevice]);

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      setHotspot(false);
      createHotspot();
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageTransition>
      <Wrapper ref={wrapper}>
        <Typography variant="h3">360° Camera view</Typography>
        {hotspot && (
          <>
            <Point
              position_x={poinA.x}
              position_y={poinA.y}
              onClick={toNextPage}
            >
              <HotspotTextWrapper width={`${poinB.x - poinA.x - 6}px`}>
                <HotspotText variant="body-500-18">120m</HotspotText>
              </HotspotTextWrapper>
            </Point>
            <Point position_x={poinB.x} position_y={poinB.y}></Point>
            <InfoWrapper direction={"row"} gap={2} alignItems={"center"}>
              <InfoIcon />
              <Typography variant="body-400-12">
                Click on the hotspot to see more info
              </Typography>
            </InfoWrapper>
          </>
        )}
      </Wrapper>
    </PageTransition>
  );
};

export default CameraView360Page;

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  padding: theme.spacing(6),
  "& h3": {
    color: theme.palette.primary.main,
  },
  position: "relative",
}));

const Point = styled(Box)<{ position_x: number; position_y: number }>(
  ({ theme, position_x, position_y }) => ({
    pointerEvents: "auto",
    cursor: "pointer",
    width: "6px",
    height: "6px",
    background: theme.palette.primary["brand-orange"],

    borderRadius: "100%",

    position: "absolute",
    left: `${position_x - 3}px`,
    top: `${position_y - 3}px`,
  })
);

const HotspotTextWrapper = styled(Box)(({ theme }) => ({
  height: "1px",
  background: theme.palette.primary["brand-orange"],

  position: "absolute",
  left: "6px",
  top: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const HotspotText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  background: theme.palette.primary["brand-orange"],
  color: theme.palette.primary.contrastText,
  borderRadius: "16px",
}));

const InfoWrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: "100px",
  left: 0,
  right: 0,
  margin: "auto",
  width: "max-content",
  background: theme.palette.primary["grey-1300"],
  color: theme.palette.primary["grey-100"],

  padding: theme.spacing(2, 2.5),
  borderRadius: "8px",
}));
