import { useEffect, useRef, useState } from "react";

import SceneAPIContext from "./SceneAPIContext";

import { RendererViewModeTypes } from "../../webgl/renderer/SceneRendererData";

const SceneAPIContextProvider = ({ children }) => {
  const [threeScene, setThreeScene] = useState(null);
  const [isSceneReady, setIsSceneReady] = useState(false);
  const [percentageOfLoadedScene, setPercentageOfLoadedScene] = useState(0);
  const [activeRendererViewMode, setActiveRendererViewMode] = useState(
    RendererViewModeTypes.viewMain
  );
  const [isGasRenderWasVisible, setGasRenderVisibility] = useState(false);
  const [is360CameraRenderWasVisible, set360CameraRenderVisibility] =
    useState(false);

  const sceneViewport = useRef();

  const handleResize = () => {
    threeScene.sceneRenderers.toggleMainViewRendering(true);
  };

  useEffect(() => {
    if (!threeScene) {
      return;
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [threeScene]);

  return (
    <SceneAPIContext.Provider
      value={{
        percentageOfLoadedScene,
        setPercentageOfLoadedScene,
        threeScene,
        setThreeScene,
        isSceneReady,
        setIsSceneReady,
        activeRendererViewMode,
        setActiveRendererViewMode,
        sceneViewport,

        isGasRenderWasVisible,
        setGasRenderVisibility,
        is360CameraRenderWasVisible,
        set360CameraRenderVisibility,
      }}
    >
      {children}
    </SceneAPIContext.Provider>
  );
};

export default SceneAPIContextProvider;
