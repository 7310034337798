import { createContext } from "react";

export interface DetailsFormTextInputInterface {
  value: string;
  type: string;
  placeholder: string;
  error: boolean;
  errorMessage: string;
  dataKey: string;
}

export interface DetailsFormSelectInputInterface {
  value: string;
  placeholder: string;
  error: boolean;
  errorMessage: string;
  dataKey: string;
}

export interface DetailsFormDataInterface {
  firstname: DetailsFormTextInputInterface;
  lastname: DetailsFormTextInputInterface;
  country: DetailsFormSelectInputInterface;
  email: DetailsFormTextInputInterface;
  phone: DetailsFormTextInputInterface;
  company: DetailsFormTextInputInterface;
  jobtitle: DetailsFormTextInputInterface;
}

export interface DetailsFormContextType {
  detailsData: DetailsFormDataInterface;
  handleOnDetailChange: (value: string, dataKey: string) => void;
  isShowInputError: boolean;
  toggleShowInputError: (value: boolean) => void;
}

const DetailsFormContext = createContext<DetailsFormContextType | null>(null);

export default DetailsFormContext;
