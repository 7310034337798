import { PerspectiveCamera } from "three";

class Camera {
  constructor(viewportDimensions) {
    this.viewportDimensions = viewportDimensions;

    this.init();
  }

  init() {
    const { width, height } = this.viewportDimensions;

    const fieldOfView = 45;
    const aspectRatio = width / height;
    const nearClip = 0.4;
    const farClip = 10000;

    this.camera = new PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearClip,
      farClip
    );
  }

  resize(viewportDimensions) {
    this.viewportDimensions = viewportDimensions;

    this.camera.aspect = viewportDimensions.width / viewportDimensions.height;
    this.camera.updateProjectionMatrix();
  }

  addCameraToParent(parent) {
    parent.add(this.camera);
  }

  setCameraPositionAndRotation(position, rotation) {
    this.camera.position.copy(position);
    this.camera.rotation.copy(rotation);
  }
}

export default Camera;
