export const HotspotData = [
  {
    id: 1,
    title: "1. Innovative Stabilization",
    description:
      " Minerva stands out with its state-of-the-art internal gimbal system, meticulously engineered to ensure unwavering stability in diverse weather conditions.",
  },
  {
    id: 2,
    title: "2.Configurable Mounting",
    description:
      "Minerva is designed with versatility at its core, featuring adaptable mounting configurations that include tower, wall, and pole mount capabilities.",
  },
];
