export const DefaultsDetailsData = {
  firstname: {
    value: "",
    type: "text",
    placeholder: "First name",
    error: true,
    errorMessage: "First name is required",
    dataKey: "firstname",
  },
  lastname: {
    value: "",
    type: "text",
    placeholder: "Last name",
    error: true,
    errorMessage: "Last name is required",
    dataKey: "lastname",
  },
  email: {
    value: "",
    placeholder: "Email address",
    type: "email",
    error: true,
    errorMessage: "Email address is required",
    dataKey: "email",
  },
  phone: {
    value: "",
    placeholder: "Phone number",
    type: "tel",
    error: true,
    errorMessage: "Phone number is required",
    dataKey: "phone",
  },
  country: {
    value: "",
    placeholder: "Country",
    error: true,
    errorMessage: "Country is required",
    dataKey: "country",
  },
  company: {
    value: "",
    placeholder: "Company name",
    type: "text",
    error: true,
    errorMessage: "Company name is required",
    dataKey: "company",
  },
  jobtitle: {
    value: "",
    placeholder: "Job title",
    type: "text",
    error: true,
    errorMessage: "Job title is required",
    dataKey: "jobtitle",
  },
};
