import { useState } from "react";
import { Box, styled } from "@mui/material";

import StyledIconButton from "./StyledIconButton";

const AnimatedIconButton = ({
  variant,
  color,
  onClick,
  endIcon,
  startIcon,
  opacityIcon,
  disabled = false,
}) => {
  const [isItemHovered, setIsItemHovered] = useState("default");

  const handleOnMouseEnter = () => {
    setIsItemHovered("hovered");
  };

  const handleOnMouseLeave = () => {
    setIsItemHovered("default");
  };

  return (
    <StyledIconButton
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={onClick}
      variant={variant}
      color={color}
      disabled={disabled}
    >
      {startIcon && (
        <IconWrapper>
          <AnimatedIconWrapper position="start" isitemhovered={isItemHovered}>
            {startIcon}
          </AnimatedIconWrapper>
          <AnimatedIconWrapper position="start" isitemhovered={isItemHovered}>
            {startIcon}
          </AnimatedIconWrapper>
        </IconWrapper>
      )}
      {endIcon && (
        <IconWrapper>
          <AnimatedIconWrapper position="end" isitemhovered={isItemHovered}>
            {endIcon}
          </AnimatedIconWrapper>
          <AnimatedIconWrapper position="end" isitemhovered={isItemHovered}>
            {endIcon}
          </AnimatedIconWrapper>
        </IconWrapper>
      )}

      {opacityIcon && (
        <IconWrapper>
          <AnimatedIconWrapper position="center" isitemhovered={isItemHovered}>
            {opacityIcon}
          </AnimatedIconWrapper>
        </IconWrapper>
      )}
    </StyledIconButton>
  );
};

export default AnimatedIconButton;

const IconWrapper = styled(Box)(() => ({
  display: "flex",
  width: "20px",
  height: "20px",
  justifyContent: "center",
  overflow: "hidden",
}));

const AnimatedIconWrapper = styled(Box)(({ position, isitemhovered }) => ({
  width: "20px",
  height: "20px",
  "& svg": {
    fontSize: "20px",
  },

  ...(position === "start" && {
    transform:
      isitemhovered === "hovered" ? "translateX(-50%)" : "translateX(50%)",
  }),
  ...(position === "center" && {
    opacity: isitemhovered === "hovered" ? "0.5" : "1",
  }),

  ...(position === "end" && {
    transform:
      isitemhovered === "hovered" ? "translateX(50%)" : "translateX(-50%)",
  }),

  transition: "all 0.3s",
}));
