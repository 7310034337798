import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import { CheckMarkIcon } from "../../../common/assets/svgIcons/icon";

import { OptionDataInterface } from "./QuizTypes";

interface QuizCustomOptionItemInterface {
  optionData: OptionDataInterface;
  onClick: (optionData: OptionDataInterface) => void;
}

const QuizCustomOptionItem = ({
  optionData,
  onClick,
}: QuizCustomOptionItemInterface): JSX.Element => {
  const handleOnClick = (): void => {
    onClick({ ...optionData, selected: !optionData.selected });
  };

  return (
    <Wrapper onClick={handleOnClick}>
      <CheckboxButtonWrapper>
        {optionData.selected ? (
          <SelectedCheckboxButton>
            <CheckMarkIcon />
          </SelectedCheckboxButton>
        ) : (
          <DefaultCheckboxButton />
        )}
      </CheckboxButtonWrapper>
      <TextWrapper>
        <Title variant="body-500-18">{optionData.title}</Title>
        {optionData.description && (
          <Description variant="body-400-14">
            {optionData.description}
          </Description>
        )}
      </TextWrapper>
    </Wrapper>
  );
};
export default QuizCustomOptionItem;

const Wrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    cursor: "pointer",
    borderRadius: theme.spacing(4),
    border: `1px solid ${theme.palette.primary["base-border"]}`,
    backdropFilter: "blur(15px)",
    backgroundColor: "rgba(0, 0, 0, 0.02)",
  })
);

const CheckboxButtonWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "26px",
    height: "26px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "5px",
  })
);

const TextWrapper = styled(Box)(
  (): CSSProperties => ({
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  })
);

const Title = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-primary"],
  })
);

const Description = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-secondary"],
  })
);

const DefaultCheckboxButton = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    height: "100%",
    borderRadius: "2px",
    border: `2px solid ${theme.palette.primary["base-border"]}`,
  })
);

const SelectedCheckboxButton = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
    backgroundColor: theme.palette.primary["brand-orange"],
  })
);
