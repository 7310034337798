import Renderer from "./Renderer";
import GasCameraPostEffect from "../postEffect/GasCameraPostEffect";

class ViewGasCameraRenderer extends Renderer {
  constructor(
    scene,
    camera,
    canvasElement,
    viewportDimensions,
    clearColor,
    clearOpacity
  ) {
    super(
      scene,
      camera,
      canvasElement,
      viewportDimensions,
      clearColor,
      clearOpacity
    );

    this.enabled = false;

    this.postEffect = new GasCameraPostEffect(
      this.scene,
      this.camera,
      this.renderer
    );
  }
}

export default ViewGasCameraRenderer;
