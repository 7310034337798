import { useContext } from "react";
import { Box, Typography, styled } from "@mui/material";

import ConfiguratorProgressBarContext from "../../../context/configuratorProgressBarContext/ConfiguratorProgressBarContext";
import { useResponsive } from "../../../hooks/useResponsive";

const DesktopConfiguratorProgressBar = () => {
  const { isSmallDevice } = useResponsive();

  const { configuratorStepsData, currentStep, stepsAmount } = useContext(
    ConfiguratorProgressBarContext
  );

  return (
    <ProgressBarContainer translatex={isSmallDevice ? "-5%" : "-22%"}>
      <LineWrapper>
        <BackLine>
          <FrontLine
            percentage={((currentStep - 1) / (stepsAmount - 1)) * 100}
          />
        </BackLine>
        <StepTicWrapper>
          {configuratorStepsData.map(({ stepNumber }) => (
            <StepTic
              key={`step-tic-${stepNumber}`}
              backgroundcolorname={
                stepNumber <= currentStep ? "grey-1300" : "base-border"
              }
            />
          ))}
        </StepTicWrapper>
      </LineWrapper>
      <StepTextWrapper>
        {configuratorStepsData.map(({ stepNumber }) => (
          <StepText
            key={`step-text-${stepNumber}`}
            variant="body-500-14"
            colorname={
              stepNumber <= currentStep ? "text-primary" : "text-secondary"
            }
          >{`Step ${stepNumber}`}</StepText>
        ))}
      </StepTextWrapper>
    </ProgressBarContainer>
  );
};

export default DesktopConfiguratorProgressBar;

const ProgressBarContainer = styled(Box)(({ translatex }) => ({
  position: "relative",
  transform: `translateX(${translatex})`,
  width: "100%",
  maxWidth: "460px",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
}));

const LineWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0px 20px",
}));

const BackLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.primary["base-border"],
}));

const FrontLine = styled(Box)(({ percentage, theme }) => ({
  width: `${percentage}%`,
  height: "1px",
  backgroundColor: theme.palette.primary["grey-1300"],
}));

const StepTicWrapper = styled(Box)(() => ({
  width: "100%",
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StepTic = styled(Box)(({ theme, backgroundcolorname = "grey-1300" }) => ({
  height: "8px",
  width: "2px",
  backgroundColor: theme.palette.primary[backgroundcolorname],
}));

const StepTextWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StepText = styled(Typography)(
  ({ theme, colorname = "text-secondary" }) => ({
    color: theme.palette.primary[colorname],
  })
);
