import { Box, Typography, styled } from "@mui/material";

import StyledIconButton from "../styledComponents/StyledIconButton";
import { MinusIcon, PlusIcon } from "../../assets/svgIcons/icon";

const MinervaCardAmountControls = ({ builtId, amount, handleOnEdit }) => {
  const handleOnMinusButtonclick = () => {
    if (amount > 1) {
      handleOnEdit(builtId, amount - 1);
    }
  };

  const handleOnPlusButtonclick = () => {
    handleOnEdit(builtId, amount + 1);
  };

  return (
    <Wrapper>
      <ChangeAmountButton
        onClick={handleOnMinusButtonclick}
        variant="contained"
        color="button-secondary-icon"
        disabled={amount <= 1}
      >
        <MinusIcon />
      </ChangeAmountButton>
      <AmountText variant="body-500-18">{amount}</AmountText>
      <ChangeAmountButton
        onClick={handleOnPlusButtonclick}
        variant="contained"
        color="button-secondary-icon"
      >
        <PlusIcon />
      </ChangeAmountButton>
    </Wrapper>
  );
};

export default MinervaCardAmountControls;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const ChangeAmountButton = styled(StyledIconButton)(() => ({
  height: "40px",
  minWidth: "40px",
  padding: "10px",
  "& svg": {
    fontSize: "20px",
  },
}));

const AmountText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-primary"],
  textAlign: "center",
  minWidth: "30px",
}));
