import AnimatedTextIconButton from "../styledComponents/AnimatedTextIconButton";
import { ArrowRightIcon } from "../../assets/svgIcons/icon";

const TextNextButton = ({ onClick, disabled, title = "Next" }) => {
  return (
    <AnimatedTextIconButton
      variant="contained"
      color="button-primary"
      endIcon={<ArrowRightIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </AnimatedTextIconButton>
  );
};

export default TextNextButton;
