import { SyntheticEvent, useState } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import AccordionItem from "../../../common/components/styledComponents/AccordionItem";
import { FrontViewPartData } from "./FrontViewPartData";

type Hotspot = {
  x: number;
  y: number;
};

type Props = {
  hotspots: Array<Hotspot>;
  offset: Array<{ x: number; y: number }>;
};

const FrontViewPart = ({ hotspots, offset }: Props): JSX.Element => {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChangeAccordion =
    (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
      isExpanded ? changeExpandedComponent(panel) : setExpanded(0);
    };

  const changeExpandedComponent = (panel: number): void => {
    setExpanded(panel);
  };

  return (
    <Wrapper>
      {hotspots.map((el, i) => (
        <Box key={i}>
          <HotspotWrapper
            justifyContent={"center"}
            alignItems={"center"}
            x={el.x + offset[i]?.x}
            y={el.y + offset[i]?.y - (offset[i]?.y < 0 ? 20 : 0)}
          >
            <HotspotInfo
              justifyContent={"center"}
              alignItems={"center"}
              state={expanded === i + 1 ? 1 : 0}
              onClick={(): void => changeExpandedComponent(i + 1)}
            >
              <Typography variant="h6">{i + 1}</Typography>
            </HotspotInfo>
          </HotspotWrapper>
          <HotspotPoint x={el.x} y={el.y} />
          <HotspotSVG>
            <Line
              x1={el.x + offset[i]?.x + (offset[i]?.x >= -36 ? 18 : 36)}
              y1={el.y + offset[i]?.y + (offset[i]?.y > 0 ? -0 : 0)}
              x2={el.x + 3}
              y2={el.y + 3}
            />
          </HotspotSVG>
        </Box>
      ))}
      <AccordionWrapper>
        {FrontViewPartData.map(component => (
          <AccordionItem
            key={component.id}
            title={component.title}
            description={component.description}
            isExpanded={expanded === component.id}
            onChange={handleChangeAccordion(component.id)}
          />
        ))}
      </AccordionWrapper>
    </Wrapper>
  );
};

export default FrontViewPart;

const Wrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
}));

const HotspotWrapper = styled(Stack)<{ x: number; y: number }>(
  ({ theme, x, y }) => ({
    position: "absolute",
    top: `${y}px`,
    left: `${x}px`,

    width: "36px",
    height: "36px",
    border: `2px solid ${theme.palette["button-secondary"].main} `,

    borderRadius: "100%",
  })
);

const HotspotInfo = styled(Stack)<{ state: number }>(({ theme, state }) => ({
  width: "28px",
  height: "28px",
  background: state
    ? theme.palette.primary["brand-orange"]
    : theme.palette.primary["grey-hotspot"],

  borderRadius: "100%",
  color: theme.palette.primary["grey-400"],

  cursor: "pointer",
  pointerEvents: "auto",
}));

const HotspotPoint = styled(Box)<{ x: number; y: number }>(
  ({ theme, x, y }) => ({
    position: "absolute",
    background: theme.palette.primary["grey-hotspot"],

    top: y,
    left: x,
    width: "6px",
    height: "6px",
    borderRadius: "100%",
  })
);

const HotspotSVG = styled("svg")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

const Line = styled("line")(({ theme }) => ({
  stroke: theme.palette.primary["grey-hotspot"],
  strokeWidth: "2px",
}));

const AccordionWrapper = styled(Stack)(({ theme }) => ({
  pointerEvents: "auto",
  background: theme.palette.primary["grey-100"],
  width: "450px",
  height: "fit-content",
  borderRadius: "16px",
  overflow: "hidden",
  padding: theme.spacing(6),
  color: theme.palette.text.primary,

  "&>div:not(:first-of-type) ": {
    borderTop: `1px solid ${theme.palette.primary["grey-300"]}`,
  },

  [theme.breakpoints.down("laptop")]: {
    position: "absolute",
    width: `calc(100% - ${theme.spacing(8)})`,
    bottom: theme.spacing(24),
    left: "0",
    margin: theme.spacing(4),
    padding: theme.spacing(2),
  },
}));
