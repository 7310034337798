import { MathUtils } from "three";

import SceneTransitionAnimation from "./SceneTransitionAnimation";

class SceneControlsTransitionAnimation extends SceneTransitionAnimation {
  constructor(sceneControls, sceneObjects, sceneRenderers) {
    super();

    this.sceneControls = sceneControls;
    this.sceneObjects = sceneObjects;
    this.sceneRenderers = sceneRenderers;
  }

  getStateFrom() {
    return {
      distance: this.sceneControls.orbitControl.getDistance(),
      azimuth: MathUtils.radToDeg(
        this.sceneControls.orbitControl.getAzimuthalAngle()
      ),
      polar: MathUtils.radToDeg(
        this.sceneControls.orbitControl.getPolarAngle()
      ),
      target: this.sceneControls.orbitControl.target.clone(),
    };
  }

  getStateTo() {
    const {
      orbitControl: { target, distance, azimuthAngleInDeg, polarAngleInDeg },
    } = this.stageData;

    const targetObjectInstance =
      target.objectName &&
      this.sceneObjects.getSceneObjectInstanceByName(target.objectName);

    const targetPosition = targetObjectInstance
      ? targetObjectInstance.initialBBoxCenter.clone().sub(target.subVector)
      : target.vector;

    return {
      distance: distance.value,
      azimuth: azimuthAngleInDeg.value,
      polar: polarAngleInDeg.value,
      target: targetPosition,
    };
  }

  onStart() {
    this.updateIsFinished = false;

    this.sceneControls.orbitControl.enabled = false;
    this.sceneControls.transformControl.disableControls();
  }

  onUpdate(updatedValue) {
    this.updateIsFinished = false;

    this.sceneControls.orbitControl.setOrbitCameraTarget(updatedValue.target);

    this.sceneControls.orbitControl.setCameraPosition(
      updatedValue.distance,
      updatedValue.azimuth,
      updatedValue.polar
    );

    this.sceneControls.orbitControl.update();
  }

  onComplete() {
    const {
      orbitControl: { distance, azimuthAngleInDeg, polarAngleInDeg },
    } = this.stageData;

    this.sceneControls.orbitControl.setMinMaxPolarAngle(
      polarAngleInDeg.min,
      polarAngleInDeg.max
    );

    this.sceneControls.orbitControl.setMinMaxAzimuthAngle(
      azimuthAngleInDeg.min,
      azimuthAngleInDeg.max
    );

    this.sceneControls.orbitControl.setMinMaxDistance(
      distance.min,
      distance.max
    );

    this.sceneControls.orbitControl.enabled =
      this.stageData.orbitControl.enabled;

    this.sceneControls.orbitControl.update();

    this.updateIsFinished = true;
  }
}

export default SceneControlsTransitionAnimation;
