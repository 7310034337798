import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import BuiltMinervaContext, {
  BuiltMinervasInterface,
} from "./BuiltMinervaContext";
import { CameraData } from "../../../pages/quizPage/data/CameraData";

const MinervaBuildsLocalStorageKey = "BuiltMinervas";

interface BuiltMinervaContextProviderInterface {
  children: React.ReactNode;
}

const BuiltMinervaContextProvider = ({
  children,
}: BuiltMinervaContextProviderInterface): JSX.Element => {
  const [builtMinervas, setBuiltMinervas] = useState<BuiltMinervasInterface[]>(
    []
  );
  const [noCameraConfig, setNoCameraConfig] = useState(false);

  const addMinervaBuilt = (quizData: any): void => {
    const cameraData = CameraData.filter(el => {
      if (!el.facility.includes(quizData[0].answer)) {
        return;
      }

      if (!el.technologies.includes("all")) {
        const answer = quizData[2].answer.join(" && ");
        const find = el.technologies.includes(answer);
        if (!find) {
          return;
        }
      }

      if (el.range !== quizData[3].answer) {
        return;
      }

      return el;
    });

    const Option = [
      { option: "Technologies:", value: quizData[2].answer.join(", ") },
      { option: "Monitoring range:", value: quizData[3].answer },
    ];

    if (!cameraData.length) {
      setNoCameraConfig(true);
    } else {
      const newMinervaBuilt = {
        id: uuid(),
        title: "Minerva",
        description: cameraData[0].type,
        quizData: quizData,
        optionData: Option,
        amount: 1,
        order:
          builtMinervas.length > 0
            ? builtMinervas[builtMinervas.length - 1].order + 1
            : 1,
      };

      const builds = [...builtMinervas, newMinervaBuilt];

      setBuiltMinervas(builds);
      setBuildsToStorage(builds);
    }
  };

  const editMinervaBuilt = (id: string, amount: number): void => {
    const builds = builtMinervas.map(
      (build: BuiltMinervasInterface): BuiltMinervasInterface => {
        if (build.id === id) {
          return {
            ...build,
            amount: amount,
          };
        }

        return build;
      }
    );

    setBuiltMinervas(builds);
    setBuildsToStorage(builds);
  };

  const deleteMinervaBuilt = (id: string): void => {
    const builds = builtMinervas.filter(
      (build: BuiltMinervasInterface): boolean => build.id !== id
    );

    setBuiltMinervas(builds);
    setBuildsToStorage(builds);
  };

  // Get items from local storage.
  const getBuildsfromStorage = (): void => {
    const storageData = localStorage.getItem(MinervaBuildsLocalStorageKey);

    if (storageData) {
      const builds = JSON.parse(storageData);
      setBuiltMinervas(builds);
    }
  };
  // Set items to local storage.
  const setBuildsToStorage = (builds: BuiltMinervasInterface[]): void => {
    localStorage.setItem(MinervaBuildsLocalStorageKey, JSON.stringify(builds));
  };

  useEffect((): void => {
    getBuildsfromStorage();
  }, []);

  return (
    <BuiltMinervaContext.Provider
      value={{
        builtMinervas,
        addMinervaBuilt,
        editMinervaBuilt,
        deleteMinervaBuilt,
        noCameraConfig,
        setNoCameraConfig,
      }}
    >
      {children}
    </BuiltMinervaContext.Provider>
  );
};

export default BuiltMinervaContextProvider;
