export const FrontViewPartData = [
  {
    id: 1,
    title: "1. Direct Drive Motors",
    description:
      "Minerva is equipped with a state-of-the-art direct drive motor for its pan and tilt system, providing exceptional precision and fluidity in movement.",
  },
  {
    id: 2,
    title: "2. Quick Release Lever",
    description:
      "Minerva's pan and tilt unit is engineered with a user-friendly quick release pull tab, designed for effortless housing replacement.",
  },
  {
    id: 3,
    title: "3. Modular Faceplate",
    description:
      "Minerva's cutting-edge, patent-pending faceplate design introduces unparalleled payload optionality, catering to diverse detection needs in the oil and gas industry",
  },
];
