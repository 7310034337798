export const CameraData = [
  {
    id: "1",
    type: "Halo Fixed lens",
    facility: ["Lower Producing"],
    technologies: [
      "Gas Leak",
      "Gate Guard",
      "Liquid Leak",
      "Tank Level Monitoring",

      "Gas Leak && Gate Guard",
      "Gas Leak && Tank Level Monitoring",
      "Gas Leak && Gate Guard && Tank Level Monitoring",

      "Gate Guard && Tank Level Monitoring",
      "Gate Guard && Liquid Leak",

      "Liquid Leak && Tank Level Monitoring",
      "Gate Guard && Liquid Leak && Tank Level Monitoring",
    ],

    range: "120m or less",
  },
  {
    id: "2",
    type: "Halo Zoom lens",
    facility: ["Lower Producing"],
    technologies: [
      "Gas Leak",
      "Gate Guard",
      "Liquid Leak",
      "Tank Level Monitoring",

      "Gas Leak && Gate Guard",
      "Gas Leak && Tank Level Monitoring",
      "Gas Leak && Gate Guard && Tank Level Monitoring",

      "Gate Guard && Tank Level Monitoring",
      "Gate Guard && Liquid Leak",

      "Liquid Leak && Tank Level Monitoring",
      "Gate Guard && Liquid Leak && Tank Level Monitoring",
    ],

    range: "more then 120m",
  },
  {
    id: "3",
    type: "Methane Fixed lens",
    facility: ["Lower Producing", "High Producing"],
    technologies: ["all"],
    range: "120m or less",
  },
  {
    id: "4",
    type: "Methane Zoom lens",
    facility: ["Lower Producing", "High Producing"],
    technologies: ["all"],
    range: "more then 120m",
  },
  {
    id: "5",
    type: "VOC Fixed lens",
    facility: ["High Producing & Highly Sensitive"],
    technologies: ["all"],
    range: "120m or less",
  },
  {
    id: "6",
    type: "VOC Zoom lens",
    facility: ["High Producing & Highly Sensitive"],
    technologies: ["all"],
    range: "more then 120m",
  },
];
