import { useEffect, useState } from "react";

type BrowsersType = {
  isSafari: boolean;
  isMobileDevice: boolean;
};

export const useBrowser = (): BrowsersType => {
  const [isSafari, setSafari] = useState(false);

  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const chromeAgent = navigator.userAgent.indexOf("CriOS") > -1;

    const safari = navigator.userAgent.indexOf("Apple") > -1;

    setSafari(!chromeAgent && safari);

    setIsMobileDevice(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
  }, []);

  return { isSafari, isMobileDevice };
};
