export const PagesList = [
  {
    pageName: "Home",
    link: "/",
  },
  {
    pageName: "360° Camera view",
    link: "/360-camera-view",
  },
  {
    pageName: "Gas camera",
    link: "/gas-camera",
  },
  {
    pageName: "Hazard alarm system",
    link: "/hazard-alarm-system",
  },
  //   {
  //     pageName: "Tower and mounting",
  //     link: "/preview",
  //   },
  {
    pageName: "Gimbal stabilisation",
    link: "/gimbal-stabilisation",
  },
  {
    pageName: "Nvidia powered",
    link: "/nvidia",
  },
  {
    pageName: "Passive cooling",
    link: "/temperature",
  },
  {
    pageName: "All in one communication",
    link: "/all-in-one-communication",
  },
  {
    pageName: "Enclosed camera casing/case IP66",
    link: "/enclosed-camera-casing",
  },
  {
    pageName: "Easy replace details",
    link: "/easy-replace",
  },
];
