import { Button, styled } from "@mui/material";

const CameraViewButton = ({ title, handleOnClick }) => {
  return (
    <StyledButton
      variant="text"
      color="button-tertiary"
      onClick={handleOnClick}
    >
      {title}
    </StyledButton>
  );
};

export default CameraViewButton;

const StyledButton = styled(Button)(({ theme, color }) => ({
  padding: "14px 32px",
  zIndex: 1,

  "&:hover": {
    color: theme.palette[color].main,
  },

  "&:active": {
    color: theme.palette[color].main,
  },
  [theme.breakpoints.down("tablet")]: {
    padding: theme.spacing(2),
  },
}));
