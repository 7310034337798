import { useEffect, useState } from "react";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import { useResponsive } from "../../../common/hooks/useResponsive";
import { MinusIcon, PlusIcon } from "../../../common/assets/svgIcons/icon";

type Hotspot = {
  x: number;
  y: number;
};

type Props = {
  hotspots: Array<Hotspot>;
};

const BottomViewPart = ({ hotspots }: Props): JSX.Element => {
  const { isExtraLargeDevice } = useResponsive();
  const [textStage, setTextStage] = useState<boolean>(isExtraLargeDevice);

  const toogleText = (): void => {
    setTextStage(!textStage);
  };

  useEffect(() => {
    setTextStage(isExtraLargeDevice);
  }, [isExtraLargeDevice]);

  return (
    <Wrapper>
      {hotspots.map((el, i) => (
        <Box key={i}>
          <HotspotInfo
            index={i}
            y={el.y}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="body-500-18">
              {i === 0 ? "Iris" : "Air jet Cooling"}
            </Typography>
          </HotspotInfo>
          <HotspotPoint x={el.x} y={el.y} />
          <HotspotSVG>
            {isExtraLargeDevice ? (
              <Line
                x1={i === 0 ? "15%" : "30%"}
                y1={el.y}
                x2={el.x + 3}
                y2={el.y + 3}
              />
            ) : (
              <Line
                x1={"30%"}
                y1={i === 0 ? "15%" : "55%"}
                x2={el.x + 3}
                y2={el.y + 3}
              />
            )}
          </HotspotSVG>
        </Box>
      ))}
      <InfoWrapper gap={4}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h5"> Sensor Fusion</Typography>
          {!isExtraLargeDevice && (
            <IconButton onClick={toogleText}>
              {textStage ? <MinusIcon /> : <PlusIcon />}
            </IconButton>
          )}
        </Stack>
        {textStage && (
          <Typography component={"p"} variant="body-400-18">
            Minerva integrates various types of sensor data, combining
            traditional sensor inputs with advanced visual data. This fusion
            creates a more comprehensive and accurate view of operations.
          </Typography>
        )}
      </InfoWrapper>
    </Wrapper>
  );
};

export default BottomViewPart;

const Wrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
}));

const HotspotInfo = styled(Stack)<{ index: number; y: number }>(
  ({ theme, index, y }) => ({
    position: "absolute",
    top: `${y - 40}px`,
    left: index === 0 ? "calc(15% - 146px)" : "calc(30% - 146px)",
    width: "146px",
    height: "64px",
    background: theme.palette["button-secondary"].main,

    borderRadius: "100px",

    [theme.breakpoints.down("laptop")]: {
      top: index === 0 ? "calc(15% - 64px)" : "55%",
      left: "calc(30% - 100px)",
    },
  })
);

const HotspotPoint = styled(Box)<{ x: number; y: number }>(
  ({ theme, x, y }) => ({
    position: "absolute",
    background: theme.palette.primary["grey-hotspot"],

    top: y,
    left: x,
    width: "6px",
    height: "6px",
    borderRadius: "100%",
  })
);

const HotspotSVG = styled("svg")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

const Line = styled("line")(({ theme }) => ({
  stroke: theme.palette.primary["grey-hotspot"],
  strokeWidth: "2px",
}));

const InfoWrapper = styled(Stack)(({ theme }) => ({
  width: "437px",
  position: "absolute",
  right: theme.spacing(6),
  bottom: theme.spacing(6),
  padding: theme.spacing(10),
  background: theme.palette.primary["grey-200"],
  borderRadius: "16px",
  pointerEvents: "auto",

  "& p": {
    color: theme.palette.primary["text-secondary"],
  },
  [theme.breakpoints.down("laptop")]: {
    width: "100%",
    position: "static",
    marginTop: "auto",

    marginBottom: "80px",
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    zIndex: "100",
  },
}));
