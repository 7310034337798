import { Easing, Tween } from "@tweenjs/tween.js";
import { MathUtils, Mesh } from "three";
import { Scene3DObjectsNames } from "../SceneObjectsPathData";

class Temperature {
  maxOpacity = 0;
  currentTemperatureValue = 0;
  tweenOpacitySide = 1;
  windShown = false;

  // Wind.
  showWindAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Quadratic.Out)
    .onUpdate((_, alpha) => {
      this.windObject.setObjectOpacity(this.maxOpacity * alpha);
    })
    .onComplete(() => {
      this.windShown = true;
    });

  temperatureAnimation = new Tween(null)
    .to({}, 1000)
    .easing(Easing.Sinusoidal.Out)
    .repeat(Infinity)
    .yoyo(true)
    .onUpdate((_, alpha) => {
      if (this.tweenOpacitySide < 0) alpha = 1 - alpha;

      let opacity = MathUtils.mapLinear(
        alpha,
        0,
        1,
        this.maxOpacity * 0.8,
        this.maxOpacity
      );
      opacity = MathUtils.clamp(opacity, 0, 0.999);

      this.temperatureObject.setObjectOpacity(opacity);
      if (opacity === 0) {
        this.stopTemperatureAnimation();
      }
    })

    .onRepeat(() => {
      this.tweenOpacitySide *= -1;
    });

  windAnimation = new Tween(null)
    .to({}, 10000)
    .easing(Easing.Linear.None)
    .repeat(Infinity)
    .onUpdate((_, alpha) => {
      alpha = 1 - alpha;
      this.windObject.object.traverse(child => {
        if (child instanceof Mesh) {
          child.material.emissiveMap.offset.y = alpha;
        }
      });

      if (this.windShown) {
        this.windObject.setObjectOpacity(this.maxOpacity);
      }
    });

  constructor(scene) {
    this.scene = scene;
  }

  // Temperature.
  init() {
    this.temperatureObject =
      this.scene.sceneObjects.getSceneObjectInstanceByName(
        Scene3DObjectsNames.MinervaTemperature
      );

    this.windObject = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.WindFlow
    );

    this.windObject.object.traverse(child => {
      if (child instanceof Mesh) {
        child.material.alphaMap = child.material.emissiveMap;
      }
    });

    this.setTemperatureValue(0);

    this.startTemperatureAnimation();
  }

  setTemperatureValue(value) {
    this.currentTemperatureValue = value;

    const color = value > 0 ? 0xff0000 : 0x0000ff;
    this.maxOpacity = Math.abs(value);

    const material = this.temperatureObject.object.children[0].material;
    material.emissive.setHex(color);

    this.temperatureAnimation.start();
  }

  startWindFlow() {
    this.showWindAnimation.start();
    this.windAnimation.start();
  }

  stopWindFlow() {
    this.windObject.setObjectOpacity(0);

    this.showWindAnimation &&
      this.showWindAnimation.isPlaying() &&
      this.showWindAnimation.stop();

    this.windAnimation &&
      this.windAnimation.isPlaying() &&
      this.windAnimation.stop();

    this.windShown = false;
  }

  startTemperatureAnimation() {
    this?.temperatureAnimation?.start();
  }

  stopTemperatureAnimation() {
    this.temperatureObject.setObjectOpacity(0);

    this.temperatureAnimation &&
      this.temperatureAnimation.isPlaying() &&
      this.temperatureAnimation.stop();
  }

  update() {
    // Wind.
    if (this.temperatureAnimation && this.temperatureAnimation.isPlaying()) {
      this.temperatureAnimation.update();

      this.scene.sceneRenderers.toggleMainViewRendering(true);
    }

    this.showWindAnimation &&
      this.showWindAnimation.isPlaying() &&
      this.showWindAnimation.update();

    if (this.windAnimation && this.windAnimation.isPlaying()) {
      this.windAnimation.update();

      this.scene.sceneRenderers.toggleMainViewRendering(true);
    }
  }
}

export default Temperature;
