import { RenderPass } from "three/addons/postprocessing/RenderPass";
import { EffectComposer } from "three/addons/postprocessing/EffectComposer";

class PostEffect {
  constructor(scene, camera, renderer) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;

    this.composer = new EffectComposer(this.renderer);
    this.insertRenderPass(this.scene, this.camera);
  }

  isRenderPassSetted() {
    return this.composer.passes.find(pass => pass instanceof RenderPass);
  }

  insertRenderPass(scene, camera) {
    this.composer.insertPass(new RenderPass(scene, camera), 0);
  }

  dispose() {
    this.composer.dispose();
  }

  resize(dimensions) {
    const { width, height } = dimensions;
    this.composer.setSize(width, height);
  }

  render(scene, camera) {
    if (this.isRenderPassSetted()) {
      this.composer.render();
    } else {
      this.insertRenderPass(scene, camera);
    }
  }
}

export default PostEffect;
