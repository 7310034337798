import { useContext, useEffect, useRef, useState } from "react";
import { Box, styled } from "@mui/material";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import PageTransition from "../../common/components/pageTransition/PageTransition";
import BottomViewPart from "./components/BottomViewPart";
import FrontViewPart from "./components/FrontViewPart";
import CameraViewToggler from "./components/CameraViewToggler";

import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";
import { getHotspotPoint } from "../../common/utils/getHotspotPoint";
import { useResponsive } from "../../common/hooks/useResponsive";
import {
  offsetPoints,
  offsetPointsMobile,
} from "../../common/assets/constants/offsetPoints";

const CameraView360PageDetails = () => {
  const { threeScene } = useContext(SceneAPIContext);
  const { isExtraLargeDevice } = useResponsive();

  const wrapper = useRef();

  const [hotspots, setHotspots] = useState([]);

  const [pageState, setPageState] = useState(0);
  const [offset, setOffset] = useState();

  const handleToggler = () => {
    setPageState(prev => (prev === 1 ? 0 : 1));
  };

  const onComplete360CameraStageTransitionAnimation2 = () => {
    setHotspots([]);

    const pointsArray = [];

    const iris = threeScene.sceneObjects.getObjectByName(
      Scene3DObjectsNames.PointsViews
    ).children[0].children[2].children[0];
    pointsArray.push(iris);

    const airJet = threeScene.sceneObjects.getObjectByName(
      Scene3DObjectsNames.PointsCameraBottomViews
    ).children[0].children[4];
    pointsArray.push(airJet);

    setHotspots(handleUpdateHotspotPoint(pointsArray));
  };

  const onComplete360CameraStageTransitionAnimation3 = () => {
    setHotspots([]);

    threeScene.sceneTransitionAnimation.opacity.start(1);

    const points = threeScene.sceneObjects.getObjectByName(
      Scene3DObjectsNames.PointsCameraViews
    ).children[0];

    setHotspots(handleUpdateHotspotPoint(points.children));
  };

  const handleUpdateHotspotPoint = points => {
    const newHotspotPointList = points.map(el => {
      const windowPosition =
        threeScene.sceneObjects.getObjectWindowPosition(el);

      return getHotspotPoint(windowPosition, wrapper.current.clientHeight);
    });

    return newHotspotPointList;
  };

  useEffect(() => {
    setOffset(isExtraLargeDevice ? offsetPoints : offsetPointsMobile);

    if (threeScene) {
      setHotspots([]);

      threeScene.loadSceneStage(
        pageState === 1 ? "cameraView2" : "cameraView3",
        isExtraLargeDevice ? "desktop" : "mobile",
        1000,
        pageState === 1
          ? onComplete360CameraStageTransitionAnimation2
          : onComplete360CameraStageTransitionAnimation3
      );
      threeScene.sceneTransitionAnimation.opacity.init(
        Scene3DObjectsNames.BlackCircle2
      );
    }
    return () => {
      setHotspots([]);

      threeScene.sceneTransitionAnimation.opacity.stop();
    };
  }, [isExtraLargeDevice, pageState]);

  useEffect(() => {
    const handleResize = () => {
      pageState === 1
        ? onComplete360CameraStageTransitionAnimation2()
        : onComplete360CameraStageTransitionAnimation3();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageTransition>
      <Wrapper ref={wrapper}>
        {pageState === 1 ? (
          <BottomViewPart hotspots={hotspots} />
        ) : (
          <FrontViewPart hotspots={hotspots} offset={offset} />
        )}
        <CameraViewToggler
          pageState={pageState}
          handleToggler={handleToggler}
        />
      </Wrapper>
    </PageTransition>
  );
};

export default CameraView360PageDetails;

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  padding: theme.spacing(6),
  "& h3": {
    color: theme.palette.primary.main,
  },
  position: "relative",
  [theme.breakpoints.down("laptop")]: {
    padding: theme.spacing(4),
  },
}));
