import { useContext } from "react";
import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";
import { GoogleMap, MarkerF } from "@react-google-maps/api";

import LocationMapContext, {
  LocationMapContextInterface,
} from "../../../common/context/locationMapContext/LocationMapContext";

const ChooseLocationMap = (): JSX.Element => {
  const {
    isMapLoaded: isLoaded,
    mapCenterCoordinates,
    onMapLoad,
    onMapUnmount,
    onMapCenterChanged,
  } = useContext(LocationMapContext) as LocationMapContextInterface;

  return (
    <Wrapper>
      {isLoaded && (
        <GoogleMap
          mapContainerClassName="map-container"
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          zoom={20}
          onLoad={onMapLoad}
          onCenterChanged={onMapCenterChanged}
          onUnmount={onMapUnmount}
        >
          {mapCenterCoordinates && (
            <MarkerF
              draggable={false}
              clickable={false}
              position={mapCenterCoordinates}
              icon={MapMarkerSVGIcon}
            />
          )}
        </GoogleMap>
      )}
    </Wrapper>
  );
};

export default ChooseLocationMap;

const Wrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: "16px",
    backgroundColor: theme.palette.primary["base-border"],
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  })
);

const MapMarkerSVGIcon = {
  path: "M0.7265599999999992,8.72656a8,8 0 1,0 16,0a8,8 0 1,0 -16,0",
  fillColor: "#CF7549",
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 2,
};
