import MainRenderer from "./MainRenderer";
import View360CameraRenderer from "./View360CameraRenderer";
import ViewGasCameraRenderer from "./ViewGasCameraRenderer";

class SceneRenderers {
  constructor(
    scene,
    sceneCamera,
    mainCanvasElement,
    mainViewportDimensions,
    viewGasRenderCanvasElement,
    viewGasViewportDimensions,
    view360RenderCanvasElements,
    view360ViewportDemensions
  ) {
    this.scene = scene;
    this.sceneCamera = sceneCamera;

    this.mainCanvasElement = mainCanvasElement;
    this.mainViewportDimensions = mainViewportDimensions;

    this.viewGasRenderCanvasElement = viewGasRenderCanvasElement;
    this.viewGasViewportDimensions = viewGasViewportDimensions;

    this.view360RenderCanvasElements = view360RenderCanvasElements;
    this.view360ViewportDemensions = view360ViewportDemensions;

    this.init();
  }

  init() {
    this.mainRenderer = new MainRenderer(
      this.scene,
      this.sceneCamera.mainCamera.camera,
      this.mainCanvasElement,
      this.mainViewportDimensions,
      0x000000,
      0
    );

    this.viewGasRenderer = new ViewGasCameraRenderer(
      this.scene,
      this.sceneCamera.gasCamera.camera,
      this.viewGasRenderCanvasElement,
      this.viewGasViewportDimensions,
      0xffffff,
      1
    );

    this.view360Renderers = this.view360RenderCanvasElements.map(
      (canvas, i) =>
        new View360CameraRenderer(
          this.scene,
          this.sceneCamera.view360Cameras[i].camera,
          canvas,
          this.view360ViewportDemensions[i],
          0xffffff,
          1
        )
    );
  }

  dispose() {
    this.mainRenderer?.dispose();
    this.viewGasRenderer?.dispose();

    this.view360Renderers?.forEach(renderer => renderer?.dispose());
  }

  resize(
    mainViewportDimensions,
    viewGasViewportDimensions,
    view360ViewportDemensions
  ) {
    this.mainViewportDimensions = mainViewportDimensions;
    this.viewGasViewportDimensions = viewGasViewportDimensions;
    this.view360ViewportDemensions = view360ViewportDemensions;

    this.mainRenderer?.resize(this.mainViewportDimensions);
    this.viewGasRenderer?.resize(this.viewGasViewportDimensions);

    this.view360Renderers?.forEach((renderer, i) =>
      renderer?.resize(this.view360ViewportDemensions[i])
    );
  }

  render(scene, mainCamera, gasCamera, view360Cameras) {
    this.mainRenderer?.render(scene, mainCamera.camera);

    this.viewGasRenderer?.render(scene, gasCamera.camera);

    this.view360Renderers?.forEach((renderer, i) =>
      renderer?.render(scene, view360Cameras[i].camera)
    );
  }

  toggleMainViewRendering(enabled) {
    this.mainRenderer.enabled = enabled;
  }

  toggleGasViewRendering(enabled) {
    this.viewGasRenderer.enabled = enabled;
  }

  toggle360ViewRendering(enabled) {
    this.view360Renderers?.forEach(renderer => (renderer.enabled = enabled));
  }
}

export default SceneRenderers;
