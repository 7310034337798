import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import IconPrevButton from "../../../common/components/navigationButtons/IconPrevButton";
import TextNextButton from "../../../common/components/navigationButtons/TextNextButton";

import QuizContext from "../context/quizContext/QuizContext";
import BuiltMinervaContext, {
  BuiltMinervaContextInterface,
} from "../../../common/context/builtMinervaContext/BuiltMinervaContext";

import { AnswerDataInterface, OptionDataInterface } from "./QuizTypes";

const QuizNavigation = (): JSX.Element => {
  const navigate = useNavigate();
  const { step, setStep, quizData, getQuizResult } = useContext(QuizContext);
  const { addMinervaBuilt } = useContext(
    BuiltMinervaContext
  ) as BuiltMinervaContextInterface;

  const onFinishQuiz = (): void => {
    const quizResultData = getQuizResult();
    addMinervaBuilt(quizResultData);

    navigate("/configurator/quiz-result");
  };

  const handlePrevButtonClick = (): void => {
    setStep((prev: number): number => --prev);
  };

  const handleNextButtonClick = (): void => {
    if (step === quizData.length - 1) {
      onFinishQuiz();
    } else {
      setStep((prev: number): number => ++prev);
    }
  };

  const disabledNextButton = quizData[step].answers.every(
    (a: AnswerDataInterface): boolean => {
      return a.type !== "custom"
        ? !a.selected
        : !!a.optionData?.every(
            (o: OptionDataInterface): boolean => !o.selected
          );
    }
  );

  return (
    <NavigationButtonsWrapper>
      {step !== 0 && <IconPrevButton onClick={handlePrevButtonClick} />}
      <TextNextButton
        disabled={disabledNextButton}
        onClick={handleNextButtonClick}
      />
    </NavigationButtonsWrapper>
  );
};

export default QuizNavigation;

const NavigationButtonsWrapper = styled(Box)(
  (): CSSProperties => ({
    display: "flex",
    gap: "16px",
  })
);
