import { Box } from "@mui/material";

const LayerContainer = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflowY: "hidden",
      overflowX: "hidden",
      pointerEvents: "none",
    }}
  >
    {children}
  </Box>
);

export default LayerContainer;
