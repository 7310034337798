import { useContext, useEffect } from "react";
import { Stack, Typography, styled } from "@mui/material";

import PageTransition from "../../common/components/pageTransition/PageTransition";
import { TemperatureSlider } from "./components/TemperatureSlider";

import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";
import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { useResponsive } from "../../common/hooks/useResponsive";

const TemperaturePage = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);

  const { isExtraLargeDevice } = useResponsive();

  const onComplete = (): void => {
    const object = threeScene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.BlackCircle1
    );

    object.setObjectScale(isExtraLargeDevice ? 3 : 2);
    threeScene.sceneTransitionAnimation.opacity.start(1);
  };

  useEffect((): any => {
    if (threeScene) {
      threeScene.loadSceneStage(
        "temperature",
        isExtraLargeDevice ? "desktop" : "mobile",
        1000,
        onComplete
      );

      threeScene.sceneAnimation.temperatureEffect.init();
      threeScene.sceneTransitionAnimation.opacity.init(
        Scene3DObjectsNames.BlackCircle1
      );
    }

    return (): void => {
      if (threeScene) {
        threeScene.sceneAnimation.temperatureEffect.stopTemperatureAnimation();
        threeScene.sceneAnimation.temperatureEffect.stopWindFlow();
        threeScene.sceneTransitionAnimation.opacity.stop();
      }
    };
  }, [isExtraLargeDevice]);

  return (
    <PageTransition>
      <Wrapper>
        <Typography variant="h3">Passive cooling</Typography>
        <TemperatureSlider />
        <TextBox>
          <Title variant="h5">Synthetic Jet Cooling</Title>
          <Text variant="body-400-18">
            Minerva is engineered with a cutting-edge cooling system, utilizing
            the principles of jet stream physics to maintain optimal electronic
            performance, even in the harshest environments.
          </Text>
        </TextBox>
      </Wrapper>
    </PageTransition>
  );
};

export default TemperaturePage;

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 80px)",
  pointerEvents: "auto",
  position: "relative",
  padding: theme.spacing(6),
  gap: theme.spacing(6),
  justifyContent: "flex-end",

  "& h3": {
    color: theme.palette.primary.main,
  },

  [theme.breakpoints.down("tablet")]: {
    padding: theme.spacing(4),
    gap: theme.spacing(2),
  },
}));

const TextBox = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  gap: theme.spacing(4),
  padding: theme.spacing(10),
  background: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(4),
  width: "400px",
  [theme.breakpoints.down("tablet")]: {
    position: "unset",
    marginTop: "auto",
    width: "100%",

    padding: theme.spacing(3),
    gap: theme.spacing(2),
  },
}));

const Title = styled(Typography)(() => ({}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  maxHeight: "300px",
  overflowY: "auto",
  pointerEvents: "auto",
}));
