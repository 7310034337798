import * as THREE from "three";

class Renderer {
  constructor(
    scene,
    camera,
    canvasElement,
    viewportDimensions,
    clearColor,
    clearOpacity
  ) {
    this.enabled = true;

    this.postEffect = null;

    this.scene = scene;
    this.camera = camera;
    this.canvasElement = canvasElement;
    this.viewportDimensions = viewportDimensions;
    this.clearColor = clearColor;
    this.clearOpacity = clearOpacity;

    this.init();
  }

  init() {
    const { width, height } = this.viewportDimensions;
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;

    const renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      canvas: this.canvasElement,
    });

    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.setClearColor(this.clearColor, this.clearOpacity);
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);

    this.renderer = renderer;
  }

  dispose() {
    this.renderer.dispose();
    this.postEffect?.dispose();
  }

  resize(dimensions) {
    const { width, height } = dimensions;

    this.renderer.setSize(width, height);
    this.postEffect?.resize(dimensions);
  }

  render(scene, camera) {
    if (this.enabled) {
      if (this.postEffect) {
        this.postEffect.render(scene, camera);
      } else {
        this.renderer.render(scene, camera);
      }
    }
  }
}

export default Renderer;
