import {
  WiFiIcon,
  CloudIcon,
  DashboardIcon,
  DirectionIcon,
  AlertNotificationIcon,
} from "../../../common/assets/svgIcons/icon";

export const FeatureData = [
  {
    title: " Dedicated Private Cloud",
    subtitle:
      "Our dedicated private cloud offers exclusive, secure data hosting for your organization.",
    icon: <CloudIcon />,
  },
  {
    title: "Built-in communications",
    subtitle: "No junction box needed.",
    icon: <WiFiIcon />,
  },
  {
    title: "Real Time Alerts",
    subtitle: "Stay in the know, act in the moment.",
    icon: <AlertNotificationIcon />,
  },
  {
    title: "Comprehensive Dashboard",
    subtitle: "Gain insights, make informed decisions.",
    icon: <DashboardIcon />,
  },
  {
    title: "Continuous Improvement",
    subtitle: "Seamless software updates.",
    icon: <DirectionIcon />,
  },
];
