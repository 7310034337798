import { ReactElement, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Box, Stack, Typography, styled } from "@mui/material";

import MenuButton from "./MenuButton";
import BuildMinervaButton from "./BuildMinervaButton";
import PreOrderMinervaButton from "./PreOrderMinervaButton";
import ARModeButton from "./ARModeButton";

import { useMenu } from "../../context/menuContext/MenuContext";
import { useResponsive } from "../../hooks/useResponsive";

const Menu = (): ReactElement => {
  const { menuState, closeMenu } = useMenu();
  const { isExtraLargeDevice } = useResponsive();
  const location = useLocation();

  useEffect(() => {
    menuState && closeMenu();
  }, [location]);

  return (
    <Wrapper active={menuState ? 1 : 0}>
      <CloseButton>
        <MenuButton type="close" handleOnClick={closeMenu} />
      </CloseButton>
      <MenuWrapper>
        <LinksWrapper>
          <StyledLink to="/">
            <Typography variant="h5">HOME</Typography>
          </StyledLink>
          <StyledLink to="/configurator/quiz">
            <Typography variant="h5">CONFIGURATOR</Typography>
          </StyledLink>
          <StyledLink to="https://www.cleanconnect.ai/">
            <Typography variant="h5">ABOUT</Typography>
          </StyledLink>
          <StyledLink to="/preorder/detail">
            <Typography variant="h5">CONTACT</Typography>
          </StyledLink>
        </LinksWrapper>

        {!isExtraLargeDevice && (
          <ButtonsWrapper gap={4}>
            <BuildMinervaButton />
            <PreOrderMinervaButton />
          </ButtonsWrapper>
        )}

        <MenuFooter gap={6}>
          <ARModeButton />

          <Typography
            component={"a"}
            variant="body-400-14"
            href="https://www.cleanconnect.ai/privacy-policy"
          >
            Privacy policy
          </Typography>
          <Typography variant="body-400-14">
            Copyright © 2023 Minerva. All rights reserved.
          </Typography>
        </MenuFooter>
      </MenuWrapper>
    </Wrapper>
  );
};

export default Menu;

const Wrapper = styled(Stack)<{ active: number }>(({ theme, active }) => ({
  position: "absolute",
  zIndex: 100,
  background: theme.palette.primary.contrastText,
  top: 0,
  right: 0,
  width: `calc(100% - ${theme.spacing(4)})`,
  [theme.breakpoints.up("laptop")]: {
    width: "500px",
  },

  height: `calc(100% - ${theme.spacing(4)})`,
  margin: theme.spacing(2),
  borderRadius: theme.spacing(4),
  transform: active
    ? "translateX(0px)"
    : `translateX(calc(100% + ${theme.spacing(4)}))`,
  transition: "1s",
}));

const MenuWrapper = styled(Stack)(() => ({
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  height: "100%",
  pointerEvents: "auto",
}));

const LinksWrapper = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(12, 8, 0),
}));

const ButtonsWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",

  margin: theme.spacing(8),

  "& button": {
    width: "100%",
  },
}));

const MenuFooter = styled(Stack)(({ theme }) => ({
  color: theme.palette.primary["text-secondary"],

  margin: theme.spacing("auto", 8, 10),
  "& a": {
    textDecoration: "none",
    color: theme.palette.primary["text-secondary"],
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary["text-primary"],
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(4),
  pointerEvents: "auto",
}));

const CloseButton = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: theme.spacing(4),
  top: theme.spacing(4),
  zIndex: 2,
  background: theme.palette.primary.contrastText,
  borderRadius: "100%",
}));
