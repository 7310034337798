import { Collapse, List, ListItem, Typography, styled } from "@mui/material";

const MinervaCardDetails = ({ open, optionData }) => {
  return (
    <Collapse in={open} timeout="auto">
      <DetailsWrapper>
        {optionData.map((data, i) => {
          return (
            <DetailsItem key={`${data.option}-${i}`}>
              <OptionText variant="body-400-16">{data.option}</OptionText>
              <ValueText variant="body-500-16">{data.value}</ValueText>
            </DetailsItem>
          );
        })}
      </DetailsWrapper>
    </Collapse>
  );
};

export default MinervaCardDetails;

const DetailsWrapper = styled(List)(() => ({
  width: "100%",
  gap: "8px",
  padding: "0px 0px 16px 0px",
}));

const DetailsItem = styled(ListItem)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  gap: "16px",
}));

const OptionText = styled(Typography)(({ theme }) => ({
  flex: "1",
  color: theme.palette.primary["text-secondary"],
}));

const ValueText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-primary"],
  flex: "3",
}));
