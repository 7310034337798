import { forwardRef } from "react";
import { Box } from "@mui/material";

import Canvas from "./Canvas";

const MainRendererViewport = forwardRef((_, ref) => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Canvas ref={ref} id="main-viewport-canvas" />
    </Box>
  );
});

export default MainRendererViewport;
