import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";

import IconPrevButton from "../../../common/components/navigationButtons/IconPrevButton";
import TextNextButton from "../../../common/components/navigationButtons/TextNextButton";

const MinervaPreviewNavigation = () => {
  const navigate = useNavigate();

  const handlePrevButtonClick = () => {
    navigate("/configurator/quiz-result");
  };

  const handleNextButtonClick = () => {
    navigate("/configurator/factory");
  };

  return (
    <NavigationButtonsWrapper>
      <IconPrevButton onClick={handlePrevButtonClick} />
      <TextNextButton onClick={handleNextButtonClick} />
    </NavigationButtonsWrapper>
  );
};

export default MinervaPreviewNavigation;

const NavigationButtonsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "16px",
}));
