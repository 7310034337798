import { PaletteColorOptions, createTheme } from "@mui/material";

import { ThemePalette } from "./ThemePalette";
import { ThemeTypography } from "./ThemeTypography";
import { ThemeBreakpoints } from "./ThemeBreakpoints";
import { ThemeMuiButton } from "./ThemeMuiButton";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    "button-primary": true;
    "button-primary-icon": true;
    "button-secondary": true;
    "button-secondary-icon": true;
    "button-tertiary": true;
    "button-tertiary-icon": true;
    "button-ghost": true;
    "button-ghost-icon": true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    primary: PaletteColor;
    "button-primary": PaletteColor;
    "button-primary-icon": PaletteColor;
    "button-secondary": PaletteColor;
    "button-secondary-icon": PaletteColor;
    "button-tertiary": PaletteColor;
    "button-tertiary-icon": PaletteColor;
    "button-ghost": PaletteColor;
    "button-ghost-icon": PaletteColor;
  }

  interface PaletteOptions {
    primary?: PaletteColorOptions;
    "button-primary"?: PaletteColorOptions;
    "button-primary-icon"?: PaletteColorOptions;
    "button-secondary"?: PaletteColorOptions;
    "button-secondary-icon"?: PaletteColorOptions;
    "button-tertiary"?: PaletteColorOptions;
    "button-tertiary-icon"?: PaletteColorOptions;
    "button-ghost"?: PaletteColorOptions;
    "button-ghost-icon"?: PaletteColorOptions;
  }
}

declare module "@mui/material/styles" {
  export interface PaletteColor {
    main: string;
    light: string;
    dark: string;
    contrastText: string;
    "grey-hotspot"?: string;
    "grey-100"?: string;
    "grey-200"?: string;
    "grey-300"?: string;
    "grey-400"?: string;
    "grey-500"?: string;
    "grey-1300"?: string;
    "text-primary"?: string;
    "text-secondary"?: string;
    "base-border"?: string;
    "base-background"?: string;
    "brand-orange"?: string;
    "text-accent"?: string;
    hot?: string;
    cold?: string;
    error?: string;
    alert?: string;
  }

  interface SimplePaletteColorOptions {
    main: string;
    light?: string;
    dark?: string;
    contrastText?: string;
    "grey-100"?: string;
    "grey-300"?: string;
    "grey-400"?: string;
    "grey-500"?: string;
    "grey-1300"?: string;
    "text-primary"?: string;
    "text-secondary"?: string;
    "base-border"?: string;
    "base-background"?: string;
    "brand-orange"?: string;
    "text-accent"?: string;
    error?: string;
  }
}
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    "body-600-20": React.CSSProperties;
    "body-600-18": React.CSSProperties;
    "body-600-16": React.CSSProperties;
    "body-600-14": React.CSSProperties;
    "body-600-12": React.CSSProperties;
    "body-500-20": React.CSSProperties;
    "body-500-18": React.CSSProperties;
    "body-500-16": React.CSSProperties;
    "body-500-14": React.CSSProperties;
    "body-500-12": React.CSSProperties;
    "body-400-20": React.CSSProperties;
    "body-400-18": React.CSSProperties;
    "body-400-16": React.CSSProperties;
    "body-400-14": React.CSSProperties;
    "body-400-12": React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    "body-600-20"?: React.CSSProperties;
    "body-600-18"?: React.CSSProperties;
    "body-600-16"?: React.CSSProperties;
    "body-600-14"?: React.CSSProperties;
    "body-600-12"?: React.CSSProperties;
    "body-500-20"?: React.CSSProperties;
    "body-500-18"?: React.CSSProperties;
    "body-500-16"?: React.CSSProperties;
    "body-500-14"?: React.CSSProperties;
    "body-500-12"?: React.CSSProperties;
    "body-400-20"?: React.CSSProperties;
    "body-400-18"?: React.CSSProperties;
    "body-400-16"?: React.CSSProperties;
    "body-400-14"?: React.CSSProperties;
    "body-400-12"?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    "body-600-20"?: true;
    "body-600-18"?: true;
    "body-600-16"?: true;
    "body-600-14"?: true;
    "body-600-12"?: true;
    "body-500-20"?: true;
    "body-500-18"?: true;
    "body-500-16"?: true;
    "body-500-14"?: true;
    "body-500-12"?: true;
    "body-400-20"?: true;
    "body-400-18"?: true;
    "body-400-16"?: true;
    "body-400-14"?: true;
    "body-400-12"?: true;
  }
}

export const theme = createTheme({
  palette: ThemePalette,
  typography: ThemeTypography,
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: ThemeMuiButton,
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  spacing: 4,
  breakpoints: { values: ThemeBreakpoints },
});
