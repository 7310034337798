import { useNavigate } from "react-router-dom";
import { Button, styled } from "@mui/material";

import { PlusIcon } from "../../../common/assets/svgIcons/icon";

const BuildOneMoreMinervaButton = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/configurator/quiz");
  };

  return (
    <StyledButton
      onClick={handleOnClick}
      variant="text"
      color="button-ghost"
      startIcon={<PlusIcon />}
    >
      Build one more Minerva
    </StyledButton>
  );
};

export default BuildOneMoreMinervaButton;

const StyledButton = styled(Button)(() => ({
  height: "unset",
  padding: "0px",
  gap: "8px",

  "& span": {
    margin: "0px",
  },

  "& svg": {
    transform: "translateY(-2px)",
    margin: "0px",
    fontSize: "20px",
  },
}));
