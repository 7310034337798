import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import { CheckMarkIcon } from "../../../common/assets/svgIcons/icon";

import { AnswerDataInterface, QuestionDataInterface } from "./QuizTypes";

interface QuizOptionItemInterface {
  questionData: QuestionDataInterface;
  answerData: AnswerDataInterface;
  onClick: (
    questionData: QuestionDataInterface,
    answerData: AnswerDataInterface
  ) => void;
}

interface StyledWrapperInterface {
  selected: boolean;
}

const QuizOptionItem = ({
  questionData,
  answerData,
  onClick,
}: QuizOptionItemInterface): JSX.Element => {
  const handleOnClick = (): void => {
    onClick(questionData, answerData);
  };

  return (
    <Wrapper onClick={handleOnClick} selected={answerData.selected}>
      <RadioButtonWrapper>
        {answerData.selected ? (
          <SelectedRadioButton>
            <CheckMarkIcon />
          </SelectedRadioButton>
        ) : (
          <DefaultRadioButton />
        )}
      </RadioButtonWrapper>
      <TextWrapper>
        <Title variant="body-500-18">{answerData.title}</Title>
        {answerData.description && (
          <Description variant="body-400-14">
            {answerData.description}
          </Description>
        )}
      </TextWrapper>
    </Wrapper>
  );
};
export default QuizOptionItem;

const Wrapper = styled(Box)<StyledWrapperInterface>(
  ({ theme, selected }): CSSProperties => ({
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "16px",
    gap: "8px",
    cursor: "pointer",

    borderRadius: "16px",
    border: `1px solid ${theme.palette.primary["base-border"]}`,
    backdropFilter: "blur(15px)",
    backgroundColor: "rgba(0, 0, 0, 0.02)",

    ...(selected && {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    }),
  })
);

const RadioButtonWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "24px",
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "3px",
  })
);

const TextWrapper = styled(Box)(
  (): CSSProperties => ({
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  })
);

const Title = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-primary"],
  })
);

const Description = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-secondary"],
  })
);

const DefaultRadioButton = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "18px",
    height: "18px",
    borderRadius: "100px",
    border: `2px solid ${theme.palette.primary["base-border"]}`,
  })
);

const SelectedRadioButton = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    backgroundColor: theme.palette.primary["brand-orange"],
  })
);
