import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import InfoCard from "./components/InfoCard";
import ThankYouNavigation from "./components/ThankYouNavigation";
import { useContext, useEffect } from "react";
import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";

const ThankYouPage = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);

  useEffect((): void => {
    if (threeScene) {
      threeScene.loadSceneStage("hide", "desktop");
    }
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <ColumnContainer>
          <Title variant="h4">Thank you for preordering your Minerva!</Title>
          <Description variant="body-400-18">
            We want to express our heartfelt gratitude for choosing Minerva.
            Your pre-order has been successfully confirmed!
          </Description>
        </ColumnContainer>
        <InfoCard />
        <BottomSection>
          <ThankYouNavigation />
        </BottomSection>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ThankYouPage;

const PageWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "40px 24px",
    alignItems: "flex-start",
    justifyContent: "center",
    pointerEvents: "auto",
  })
);

const ContentWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "676px",
    height: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "32px",
  })
);

const ColumnContainer = styled(Box)(
  (): CSSProperties => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
  })
);

const Title = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-accent"],
  })
);

const Description = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-secondary"],
  })
);

const BottomSection = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    marginTop: "auto",
  },
  width: "100%",
}));
