import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

const InfoIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.5936 7.53121C11.2926 7.53121 11.8592 6.96458 11.8592 6.2656C11.8592 5.56663 11.2926 5 10.5936 5C9.89467 5 9.32804 5.56663 9.32804 6.2656C9.32804 6.96458 9.89467 7.53121 10.5936 7.53121ZM9.66366 15C10.437 15 11.0331 14.7688 11.6245 14.249C11.6245 14.249 10.1343 14.639 10.1343 13.4461C10.1343 12.8064 10.3431 12.1955 10.5521 11.5842C10.7614 10.9719 10.9709 10.3593 10.9709 9.71685C10.9709 8.84107 10.1798 8.42196 9.40222 8.42196C8.37036 8.42196 7.44141 9.22479 7.44141 9.22479C7.44141 9.22479 8.87934 9.21282 8.87934 9.97583C8.87934 10.6153 8.67043 11.2266 8.4614 11.8382C8.25212 12.4506 8.04272 13.0633 8.04272 13.7051C8.04272 14.6006 8.87722 15 9.66366 15Z"
        fill="#87877F"
      />
    </SvgIcon>
  );
};

export default InfoIcon;
