import SceneMoveAnimation from "./Transitions/SceneMoveAnimation";
import SceneOpacityAnimation from "./Transitions/SceneOpacityAnimation";

class SceneTransitionAnimation {
  constructor(scene) {
    this.move = new SceneMoveAnimation(scene);
    this.opacity = new SceneOpacityAnimation(scene);
  }

  update() {
    this.move.update();
    this.opacity.update();
  }
}

export default SceneTransitionAnimation;
