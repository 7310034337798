import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

const ARIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 1.1499L17.75 5.5499V14.9499L9.5 19.3499L1.25 14.9499V5.5499L9.5 1.1499ZM2.75 7.2499L8.75 10.4499V17.2499L2.75 14.0499V7.2499ZM10.25 17.2499L16.25 14.0499V7.2499L10.25 10.4499V17.2499ZM9.5 9.1499L15.4062 5.9999L9.5 2.8499L3.59375 5.9999L9.5 9.1499Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ARIcon;
