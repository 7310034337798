import { useContext, useEffect } from "react";
import { Box, styled } from "@mui/material";

import { type CSSProperties } from "@mui/material/styles/createMixins";

import EnterDetailsBlock from "./components/EnterDetailsBlock";
import BuiltMinervas from "../../common/components/minervaCard/BuiltMinervas";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";

import DetailsFormContextProvider from "./context/detailsFormContext/DetailsFormContextProvider";

const PreorderPage = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);

  useEffect((): void => {
    if (threeScene) {
      threeScene.loadSceneStage("hide", "desktop");
    }
  }, []);

  return (
    <DetailsFormContextProvider>
      <PageWrapper>
        <DetailsBlockWrapper>
          <DetailsBlockContainer>
            <EnterDetailsBlock />
          </DetailsBlockContainer>
        </DetailsBlockWrapper>
        <BuiltMinervasBlockWrapper>
          <BuiltMinervas />
        </BuiltMinervasBlockWrapper>
      </PageWrapper>
    </DetailsFormContextProvider>
  );
};

export default PreorderPage;

const PageWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "40px 24px",
    justifyContent: "center",
    alignItems: "flex-start",

    gap: "40px",
    pointerEvents: "auto",
    overflow: "auto",
    [theme.breakpoints.down("laptop")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  })
);

const DetailsBlockWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flex: 1,
    [theme.breakpoints.down("laptop")]: {
      height: "auto",
    },
  })
);

const BuiltMinervasBlockWrapper = styled(Box)(
  (): CSSProperties => ({
    maxHeight: "100%",
    maxWidth: "557px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  })
);

const DetailsBlockContainer = styled(Box)(
  (): CSSProperties => ({
    display: "flex",
    maxWidth: "557px",
    height: "100%",
  })
);
