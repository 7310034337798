import { useMemo } from "react";
import countryList from "react-select-country-list";

export const useCountries = (): any => {
  const countries = useMemo((): any => {
    const countries = countryList();
    countries.setLabel("VN", "Vietnam");

    return countries;
  }, []);

  return { countries };
};
