import { useState } from "react";

interface Field {
  name: string;
  value: any;
}

interface DataType {
  fields: Array<Field>;
}

type HubspotFormProps = {
  isLoading: boolean;
  handleSubmit: (formData: FormData) => Promise<Response>;
};

const useHubspotForm = ({
  portalId,
  formId,
}: {
  portalId: string | undefined;
  formId: string | undefined;
}): HubspotFormProps => {
  const [isLoading, setIsLoading] = useState(false);

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const fetchData = async (formData: FormData): Promise<Response> => {
    setIsLoading(true);
    const data: DataType = {
      fields: [],
    };
    for (const pair of formData.entries()) {
      data.fields.push({ name: pair[0].toLowerCase(), value: pair[1] });
    }
    return await fetch(url, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const handleSubmit = async (formData: FormData): Promise<Response> => {
    const result = await fetchData(formData);
    setIsLoading(false);

    return result;
  };

  return { isLoading, handleSubmit };
};

export default useHubspotForm;
