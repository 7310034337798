import { styled } from "@mui/material";

import StyledIconButton from "../styledComponents/StyledIconButton";
import { CollapseArrowDown, CollapseArrowUp } from "../../assets/svgIcons/icon";

const MinervaCardCloseOpenButton = ({ open, setOpen }) => {
  const handleOnClick = () => {
    setOpen(prev => !prev);
  };

  return (
    <CloseOpenButton
      variant="outlined"
      color="button-tertiary-icon"
      onClick={handleOnClick}
    >
      {open ? <CollapseArrowUp /> : <CollapseArrowDown />}
    </CloseOpenButton>
  );
};

export default MinervaCardCloseOpenButton;

const CloseOpenButton = styled(StyledIconButton)(() => ({
  height: "40px",
  minWidth: "40px",
  padding: "8.5px",
}));
