import { useContext, useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";

import BuildOneMoreMinervaButton from "./components/BuildOneMoreMinervaButton";
import QuizResultNavigation from "./components/QuizResultNavigation";
import BuiltMinervas from "../../common/components/minervaCard/BuiltMinervas";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import ConfiguratorProgressBarContext from "../../common/context/configuratorProgressBarContext/ConfiguratorProgressBarContext";
import BuiltMinervaContext from "../../common/context/builtMinervaContext/BuiltMinervaContext";

const QuizResultPage = () => {
  const { threeScene } = useContext(SceneAPIContext);
  const { handleSetCurrentStep } = useContext(ConfiguratorProgressBarContext);
  const { builtMinervas, noCameraConfig } = useContext(BuiltMinervaContext);

  useEffect(() => {
    if (threeScene) {
      threeScene.loadSceneStage("hide", "desktop");
      handleSetCurrentStep("quiz");
    }
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <ContentHeader>
          <Title variant="h3">
            {noCameraConfig || !builtMinervas.length
              ? "Your own Minerva!"
              : " You have successfully built your own Minerva!"}
          </Title>
          <Description variant="body-500-18">
            {`If you require an additional Minerva with a different setup, kindly
            select the "Build one more Minerva" button located below.`}
          </Description>
        </ContentHeader>
        <BuiltMinervas />
        <ButtonWrapper>
          <BuildOneMoreMinervaButton />
        </ButtonWrapper>
        <ContentFooter>
          <QuizResultNavigation />
        </ContentFooter>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default QuizResultPage;

const PageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  padding: theme.spacing(10, 6, 6),
  alignItems: "flex-start",
  justifyContent: "center",
  pointerEvents: "auto",
  [theme.breakpoints.down("tablet")]: {
    padding: theme.spacing(10, 4, 4),
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: "676px",
  height: "100%",
  maxWidth: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),

  [theme.breakpoints.down("tablet")]: {
    gap: theme.spacing(2),
  },
}));

const ContentHeader = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-accent"],
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-secondary"],
  lineHeight: "28px",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  height: "min-content",
  justifyContent: "flex-start",
  padding: theme.spacing(0),
}));

const ContentFooter = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));
