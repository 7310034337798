import { useNavigate } from "react-router-dom";

import AnimatedTextButton from "../styledComponents/AnimatedTextButton";

const PreOrderMinervaButton = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/preorder/detail");
  };

  return (
    <AnimatedTextButton
      variant="contained"
      color="button-primary"
      onClick={handleOnClick}
    >
      Pre-order Minerva
    </AnimatedTextButton>
  );
};

export default PreOrderMinervaButton;
