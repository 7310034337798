import { useContext, useEffect, useState } from "react";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import ConfiguratorProgressBarContext from "../../common/context/configuratorProgressBarContext/ConfiguratorProgressBarContext";
import ChooseLocationAside from "./components/ChooseLocationAside";
import ChooseLocationMap from "./components/ChooseLocationMap";
import ChooseLocationNavigation from "./components/ChooseLocationNavigation";
import { CloseIcon } from "../../common/assets/svgIcons/icon";

import { useResponsive } from "../../common/hooks/useResponsive";

const ChooseLocationPage = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);
  const { handleSetCurrentStep } = useContext(ConfiguratorProgressBarContext);

  const { isSmallDevice } = useResponsive();

  const [mapState, setMapState] = useState(false);

  const openMap = (): void => {
    setMapState(true);
  };

  const closeMap = (): void => {
    setMapState(false);
  };

  useEffect((): void => {
    if (threeScene) {
      threeScene.loadSceneStage("hide", "desktop");
      handleSetCurrentStep("map");
    }
  }, [isSmallDevice]);

  return (
    <PageWrapper>
      <Typography variant="h3">Test it out on your own location</Typography>

      <MainInfoWrapper>
        {!isSmallDevice && (
          <LeftBlock>
            <ChooseLocationMap />
          </LeftBlock>
        )}
        <RightBlock>
          <ChooseLocationAside openMap={openMap} />
          {isSmallDevice && (
            <>
              <ChooseLocationNavigation />

              <LocationWrapper mapstate={mapState ? 1 : 0}>
                <ChooseLocationMap />
                <CancelButton onClick={closeMap}>
                  <CloseIcon />
                </CancelButton>
              </LocationWrapper>
            </>
          )}
        </RightBlock>
      </MainInfoWrapper>
    </PageWrapper>
  );
};

export default ChooseLocationPage;

const MainInfoWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    display: "flex",
    flex: 1,

    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "auto",
    gap: "40px",
    [theme.breakpoints.down("laptop")]: {
      gap: 0,
    },
  })
);

const PageWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: theme.spacing(0, 6, 0),
  gap: "24px",

  [theme.breakpoints.down("laptop")]: {
    padding: theme.spacing(6),
  },
}));

const LeftBlock = styled(Box)(
  (): CSSProperties => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  })
);

const RightBlock = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "437px",
    maxWidth: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("laptop")]: {
      width: "100%",
      gap: theme.spacing(4),
    },
  })
);

const LocationWrapper = styled(Box)<{ mapstate: number }>(({ mapstate }) => ({
  position: "absolute",
  zIndex: "1",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  display: "flex",
  justifyContent: "center",
  visibility: mapstate ? "visible" : "hidden",
}));

const CancelButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  zIndex: "2",
  bottom: "0",
  background: theme.palette.primary.main,
  margin: theme.spacing(4),
  padding: theme.spacing(3),

  "& svg": {
    color: theme.palette.primary.contrastText,
    fontSize: "20px",
  },
}));
