import { createContext } from "react";

export interface MapCenterCoordinateInterface {
  lat: number;
  lng: number;
}

export interface LocationMapContextInterface {
  isMapLoaded: boolean;
  mapCenterCoordinates: MapCenterCoordinateInterface | undefined;
  onMapLoad: (map: any) => void;
  onMapUnmount: () => void;
  onMapCenterChanged: () => void;
  onSearchMapLocation: (query: string) => void;
}

const LocationMapContext = createContext<LocationMapContextInterface | null>(
  null
);

export default LocationMapContext;
