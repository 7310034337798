import AnimatedIconButton from "../styledComponents/AnimatedIconButton";
import { ArrowLeftIcon } from "../../assets/svgIcons/icon";

const IconPrevButton = ({ onClick }) => {
  return (
    <AnimatedIconButton
      variant="outlined"
      color="button-tertiary"
      startIcon={<ArrowLeftIcon />}
      onClick={onClick}
    ></AnimatedIconButton>
  );
};

export default IconPrevButton;
