import { Color, Uniform } from "three";
import { ShaderPass } from "three/addons/postprocessing/ShaderPass";
import { ColorifyShader } from "three/addons/shaders/ColorifyShader";
import { GammaCorrectionShader } from "three/addons/shaders/GammaCorrectionShader";
import { FilmPass } from "three/addons/postprocessing/FilmPass";
import { VignetteShader } from "three/addons/shaders/VignetteShader";
import { BleachBypassShader } from "three/addons/shaders/BleachBypassShader";

import PostEffect from "./PostEffect";

class GasCameraPostEffect extends PostEffect {
  constructor(scene, camera, renderer) {
    super(scene, camera, renderer);

    this.addEffect();
  }

  addEffect() {
    const effectColorify1 = new ShaderPass(ColorifyShader);
    effectColorify1.uniforms["color"] = new Uniform(new Color(1.0, 1.0, 1.0));
    this.composer.addPass(effectColorify1);

    const effectColorify2 = new ShaderPass(ColorifyShader);
    effectColorify2.uniforms["color"] = new Uniform(
      new Color(0.5, 0.555, 0.515)
    );
    this.composer.addPass(effectColorify2);

    const gammaCorrection = new ShaderPass(GammaCorrectionShader);
    gammaCorrection.uniforms["tDiffuse"].value = 1.1;
    this.composer.addPass(gammaCorrection);

    const effectFilmBW = new FilmPass(0.1, 0.3, 2048, true);
    this.composer.addPass(effectFilmBW);

    const effectVignette = new ShaderPass(VignetteShader);
    effectVignette.uniforms["offset"].value = 0.97;
    effectVignette.uniforms["darkness"].value = 0.5;
    this.composer.addPass(effectVignette);

    const bleachEffect = new ShaderPass(BleachBypassShader);
    bleachEffect.uniforms["opacity"].value = 1.0;
    this.composer.addPass(bleachEffect);
  }
}

export default GasCameraPostEffect;
