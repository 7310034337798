import { Box, styled } from "@mui/material";

import MainBackgroundImage from "../../assets/images/main-background.png";

const Background = () => {
  return (
    <BackgroundWrapper>
      <BackgroundDots />
    </BackgroundWrapper>
  );
};

export default Background;

const BackgroundWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  padding: "24px 24px 0 24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.primary["base-background"],
}));

const BackgroundDots = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${MainBackgroundImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));
