import { MathUtils } from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

class CustomOrbitControls extends OrbitControls {
  constructor(camera, domElement) {
    super(camera, domElement);

    this.enableDamping = true;
    this.dampingFactor = 0.08;

    this.enablePan = false;
    this.screenSpacePanning = false;
  }

  setOrbitCameraTarget(targetPosition) {
    this.target.copy(targetPosition);
  }

  setCameraPosition(distance, azimuthInDeg, polarInDeg) {
    this.setMinMaxDistance(distance, distance);
    this.setMinMaxAzimuthAngle(azimuthInDeg, azimuthInDeg);
    this.setMinMaxPolarAngle(polarInDeg, polarInDeg);
  }

  setMinMaxPolarAngle(minPolarAngleInDeg, maxPolarAngleInDeg) {
    this.minPolarAngle = isFinite(minPolarAngleInDeg)
      ? MathUtils.degToRad(minPolarAngleInDeg)
      : minPolarAngleInDeg;

    this.maxPolarAngle = isFinite(maxPolarAngleInDeg)
      ? MathUtils.degToRad(maxPolarAngleInDeg)
      : maxPolarAngleInDeg;
  }

  setMinMaxAzimuthAngle(minAzimuthAngleInDeg, maxAzimuthAngleInDeg) {
    this.minAzimuthAngle = isFinite(minAzimuthAngleInDeg)
      ? MathUtils.degToRad(minAzimuthAngleInDeg)
      : minAzimuthAngleInDeg;

    this.maxAzimuthAngle = isFinite(maxAzimuthAngleInDeg)
      ? MathUtils.degToRad(maxAzimuthAngleInDeg)
      : maxAzimuthAngleInDeg;
  }

  setMinMaxDistance(minDistance, maxDistance) {
    this.minDistance = minDistance;
    this.maxDistance = maxDistance;
  }
}

export default CustomOrbitControls;
