import SceneTransitionAnimation from "./SceneTransitionAnimation";

class SceneObjectsTransitionAnimation extends SceneTransitionAnimation {
  constructor(sceneObjects, sceneRenderers) {
    super();

    this.sceneObjects = sceneObjects;
    this.sceneRenderers = sceneRenderers;
  }

  getStateFrom() {
    return { opacity: 0 };
  }

  getStateTo() {
    return { opacity: 1 };
  }

  onStart() {
    this.updateIsFinished = false;

    const { showObjects, hideObjects } = this.stageData;

    this.objectsToShow = showObjects.filter(objectName => {
      return !this.sceneObjects
        .getSceneObjectInstanceByName(objectName)
        .checkIsObjectVisible();
    });

    this.objectsToHide = hideObjects.filter(objectName => {
      return this.sceneObjects
        .getSceneObjectInstanceByName(objectName)
        .checkIsObjectVisible();
    });
  }

  onUpdate(updatedValue) {
    this.updateIsFinished = false;

    this.objectsToShow.forEach(objectName => {
      this.sceneObjects
        .getSceneObjectInstanceByName(objectName)
        .setObjectOpacity(updatedValue.opacity);
    });

    this.objectsToHide.forEach(objectName => {
      this.sceneObjects
        .getSceneObjectInstanceByName(objectName)
        .setObjectOpacity(1 - updatedValue.opacity);
    });
  }

  onRun() {
    this.animation && this.onComplete();
  }

  async onComplete() {
    for await (const objectName of this.objectsToShow) {
      this.sceneObjects
        .getSceneObjectInstanceByName(objectName)
        .setObjectOpacity(1);
    }

    for await (const objectName of this.objectsToHide) {
      this.sceneObjects
        .getSceneObjectInstanceByName(objectName)
        .setObjectOpacity(0);
    }

    this.updateIsFinished = true;
  }
}

export default SceneObjectsTransitionAnimation;
