import { useContext, useEffect, useState } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { degToRad } from "three/src/math/MathUtils";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { useResponsive } from "../../common/hooks/useResponsive";

import PageTransition from "../../common/components/pageTransition/PageTransition";
import GasCameraNotification from "./components/GasCameraNotification";

import { RendererViewModeTypes } from "../../common/webgl/renderer/SceneRendererData";

type StepType = Array<{ state: boolean }>;

const GasCameraPage = (): JSX.Element => {
  const { threeScene, setActiveRendererViewMode } = useContext(SceneAPIContext);

  const { isLargeDevice } = useResponsive();

  const [step, setStep] = useState<StepType>([]);

  const onComplete = (): void => {
    const camera = threeScene.sceneObjects.getObjectByName(
      "MinervaCameraHandle"
    );
    camera.position.set(-16, 20, 30);
    camera.rotateY(degToRad(90));

    threeScene.sceneRenderers.toggleGasViewRendering(true);
    setActiveRendererViewMode(RendererViewModeTypes.viewGas);
  };

  const restartCamera = (): void => {
    const camera = threeScene.sceneObjects.getSceneObjectInstanceByName(
      "MinervaCameraHandle"
    );

    camera.setObjectPosition(camera.initialPosition);

    camera.setObjectRotation(camera.initialRotation);

    threeScene.sceneRenderers.toggleGasViewRendering(false);
    setActiveRendererViewMode(RendererViewModeTypes.viewMain);
  };

  useEffect((): (() => void) => {
    if (threeScene) {
      threeScene.loadSceneStage(
        "gasCamera",
        isLargeDevice ? "desktop" : "mobile",
        1000,
        onComplete
      );
      threeScene.sceneAnimation.gasEffect.init();
      threeScene.sceneAnimation.gasEffect.start(setStep);
    }
    return (): void => {
      restartCamera();
      threeScene.sceneAnimation.gasEffect.stop();
      setStep([]);
    };
  }, [isLargeDevice]);

  return (
    <PageTransition>
      <Wrapper>
        <Typography variant="h3">Real time alerting</Typography>
      </Wrapper>
      <Notifications alignItems={"center"} justifyContent={"center"}>
        {step.map((el, i) => (
          <GasCameraNotification key={i} index={i} state={el.state} />
        ))}
      </Notifications>
    </PageTransition>
  );
};

export default GasCameraPage;

const Notifications = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: "100px",
  left: "calc(78% - 24px)",
  gap: "-300px",
  width: "20%",
  "& .visible:last-child": {
    bottom: "12px",
    width: "122%",
  },
  "& .visible:not(:last-child)": {
    background: "linear-gradient(180deg, #E8E8E8 0%, #F9F9F9 100%)",
    backdropFilter: " blur(25px)",
  },

  [theme.breakpoints.down("laptop")]: {
    width: "80%",
    left: "0",
    right: 0,
    margin: "auto",
    height: "300px",
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  padding: theme.spacing(6),
  "& h3": {
    color: theme.palette.primary.main,
  },
  position: "relative",
}));
