import { SyntheticEvent } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
} from "@mui/material";

import { MinusIcon, PlusIcon } from "../../assets/svgIcons/icon";

type Props = {
  title: string;
  description: string;
  isExpanded: boolean;
  onChange: (event: SyntheticEvent, isExpanded: boolean) => void;
};

const AccordionItem = ({
  title,
  description,
  isExpanded,
  onChange,
}: Props): JSX.Element => {
  return (
    <AccordionStyled expanded={isExpanded} onChange={onChange}>
      <AccordionSummary expandIcon={isExpanded ? <MinusIcon /> : <PlusIcon />}>
        <Typography variant="body-600-18">{title}</Typography>
      </AccordionSummary>
      <AccordionDetailsStyled>
        <Typography variant="body-500-14">{description}</Typography>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

export default AccordionItem;

const AccordionStyled = styled(Accordion)(() => ({
  boxShadow: "none",
  "&::before": {
    display: "none",
  },

  "&.MuiPaper-root": {
    margin: "0",
    backgroundColor: "inherit",
    boxShadow: "none",
  },

  "& svg": {
    fontSize: "20px",
    color: "black",
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  color: theme.palette.primary["grey-500"],
  maxHeight: "300px",
  overflowY: "auto",
}));
