import { useState } from "react";

import QuizContext from "./QuizContext";

import { QuizData } from "../../data/QuizData";

import { chackIsArraysAreEqual } from "../../../../common/utils/ArrayUtils";

const QuizContextProvider = ({ children }) => {
  const [step, setStep] = useState(0);
  const [quizData, setQuizData] = useState(
    JSON.parse(JSON.stringify(QuizData))
  );

  const handleSelectSinglePredefinedOption = (questionId, answerId) => {
    setQuizData(prev => {
      return prev.map(q => {
        if (q.id === questionId) {
          q.answers.forEach(a => (a.selected = a.id === answerId));
        }

        return q;
      });
    });
  };

  const handleSelectMultiplePredefinedOption = (questionId, answerId) => {
    setQuizData(prev => {
      return prev.map(q => {
        if (q.id === questionId) {
          q.answers.forEach(a => {
            a.selected = a.id === answerId;

            if (a.type === "custom") {
              const predefinedIdList = q.answers.find(
                pa => pa.id === answerId
              )?.predefinedOptionsIdList;

              if (predefinedIdList) {
                a.optionData.forEach(
                  o => (o.selected = predefinedIdList.includes(o.id))
                );
              }
            }
          });
        }

        return q;
      });
    });
  };

  const handleSelectMultipleCustomOption = (
    questionId,
    answerId,
    optionId,
    selected
  ) => {
    setQuizData(prev => {
      return prev.map(q => {
        if (q.id === questionId) {
          q.answers
            .find(a => a.id === answerId)
            ?.optionData.forEach(o => {
              if (o.id === optionId) {
                o.selected = selected;
              }
            });

          const customOptionIdList = q.answers
            .find(a => a.id === answerId)
            ?.optionData.filter(o => o.selected)
            .map(o => o.id);

          if (customOptionIdList) {
            q.answers.forEach(a => {
              if (a.type === "predefined") {
                a.selected = chackIsArraysAreEqual(
                  customOptionIdList,
                  a.predefinedOptionsIdList
                );
              }
            });
          }
        }

        return q;
      });
    });
  };

  const handleOnOptionSelected = (questionData, answerData, optionData) => {
    handleSelectMultipleCustomOption(
      questionData.id,
      answerData.id,
      optionData.id,
      optionData.selected
    );
  };

  const handleOnAnswerSelect = (questionData, answerData) => {
    if (questionData.type === "multiple") {
      handleSelectMultiplePredefinedOption(questionData.id, answerData.id);
    } else {
      handleSelectSinglePredefinedOption(questionData.id, answerData.id);
    }
  };

  const getQuizResult = () => {
    return quizData.map(q => {
      const answer =
        q.type === "single"
          ? q.answers.find(a => a.selected).title
          : q.answers
              .find(a => a.type === "custom")
              .optionData.filter(o => o.selected)
              .map(o => o.title);

      return { question: q.question, answer: answer };
    });
  };

  return (
    <QuizContext.Provider
      value={{
        step,
        setStep,
        quizData,
        handleOnOptionSelected,
        handleOnAnswerSelect,
        getQuizResult,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};

export default QuizContextProvider;
