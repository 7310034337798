import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import { ChevronDIcon } from "../../assets/svgIcons/icon";

interface SelectOptionInterface {
  value: string | number;
  label: string;
  name: string;
}

interface SelectInputPropsInterface {
  handleOnChange: (value: string) => void;
  value: string;
  name: string;
  placeholder: string;
  options: SelectOptionInterface[];
  isError: boolean;
  errorMessage: string;
}

interface StyledFormControlPropsInterface {
  iserror: string;
}

const SelectInput = ({
  options,
  value,
  name,
  placeholder,
  isError,
  errorMessage,
  handleOnChange,
}: SelectInputPropsInterface): JSX.Element => {
  const theme = useTheme();

  const handleSelectChange = (event: SelectChangeEvent<unknown>): void => {
    const value = event.target.value as string;
    handleOnChange(value);
  };

  return (
    <StyledFormControl iserror={isError ? "true" : "false"} fullWidth>
      <StyledSelect
        value={value}
        name={name}
        displayEmpty={true}
        onChange={handleSelectChange}
        IconComponent={ChevronDIcon}
        MenuProps={{
          PaperProps: {
            style: {
              marginTop: "8px",
              backgroundColor: theme.palette.primary["grey-300"],
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "unset",
            },
          },
          MenuListProps: {
            style: {
              height: "70%",
              maxHeight: "350px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              padding: "0px",
              overflowY: "auto",
            },
          },
        }}
      >
        <PlaceholderItem disabled value="">
          <Typography variant="body-400-16">{placeholder}</Typography>
        </PlaceholderItem>
        {options.map(
          (option, i): JSX.Element => (
            <OptionItem key={`${option}-${i}`} value={option.value}>
              <Typography variant="body-400-16">{option.label}</Typography>
            </OptionItem>
          )
        )}
      </StyledSelect>
      {isError && <ErrorText variant="body-400-12">{errorMessage}</ErrorText>}
    </StyledFormControl>
  );
};

export default SelectInput;

const StyledFormControl = styled(FormControl)<StyledFormControlPropsInterface>(
  ({ theme, iserror }): CSSProperties => ({
    position: "relative",
    height: "48px",
    padding: "14px 0px",
    borderBottom: `1px solid ${theme.palette.primary["base-border"]}`,

    ...(iserror === "true" && {
      borderBottom: `1px solid ${theme.palette.primary.error}`,
    }),
  })
);

const StyledSelect = styled(Select)(
  ({ theme, value }): CSSProperties => ({
    color:
      value !== ""
        ? theme.palette.primary["text-primary"]
        : theme.palette.primary["text-secondary"],

    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-select": {
      padding: 0,
    },
  })
);

const OptionItem = styled(MenuItem)(
  ({ theme }): CSSProperties => ({
    padding: "0px",
    backgroundColor: "unset",
    color: theme.palette.primary["text-secondary"],

    "&.Mui-selected": {
      backgroundColor: "unset",
      color: theme.palette.primary["text-primary"],

      "&:focus": {
        backgroundColor: "unset",
        color: theme.palette.primary["text-primary"],
      },

      "&:hover": {
        backgroundColor: "unset",
        color: theme.palette.primary["text-primary"],
      },

      "&:active": {
        backgroundColor: "unset",
        color: theme.palette.primary["text-primary"],
      },

      "&:active::after": {
        backgroundColor: "unset",
        color: theme.palette.primary["text-primary"],
      },
    },

    "&:focus": {
      backgroundColor: "unset",
      color: theme.palette.primary["text-secondary"],
    },

    "&:hover": {
      backgroundColor: "unset",
      color: theme.palette.primary["text-primary"],
    },

    "&:active": {
      backgroundColor: "unset",
      color: theme.palette.primary["text-primary"],
    },

    "&:active::after": {
      backgroundColor: "unset",
      color: theme.palette.primary["text-primary"],
    },
  })
);

const PlaceholderItem = styled(OptionItem)(
  (): CSSProperties => ({
    display: "none",
  })
);

const ErrorText = styled(Typography)(
  ({ theme }): CSSProperties => ({
    position: "absolute",
    bottom: "-20px",
    color: theme.palette.primary.error,
  })
);
