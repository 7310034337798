// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const ThemeMuiButton: any = {
  root: () => ({
    pointerEvents: "auto",
    borderRadius: 100,
    borderWidth: "1.5px",
    textTransform: "initial",
    height: "48px",
    minWidth: "48px",
    padding: "12.5px 22.5px",
    gap: "8px",
    boxShadow: "unset",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
    boxSizing: "border-box",

    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",

    "&:active": {
      boxShadow: "unset",
    },

    "&:hover": {
      boxShadow: "unset",
    },

    "&:active::after": {
      boxShadow: "unset",
    },
  }),
  contained: ({ theme, ownerState }) => {
    return {
      backgroundColor: theme.palette[ownerState.color].main,
      color: theme.palette[ownerState.color].contrastText,

      "& svg path": {
        fill: theme.palette[ownerState.color].contrastText,
      },

      "&:hover": {
        backgroundColor: theme.palette[ownerState.color].light,
        color: theme.palette[ownerState.color].contrastText,

        "& svg path": {
          fill: theme.palette[ownerState.color].contrastText,
        },
      },

      "&:active": {
        backgroundColor: theme.palette[ownerState.color].dark,
        color: theme.palette[ownerState.color].contrastText,

        "& svg path": {
          fill: theme.palette[ownerState.color].contrastText,
        },
      },

      "&.Mui-disabled": {
        backgroundColor: theme.palette[ownerState.color].main,
        color: theme.palette[ownerState.color].contrastText,
        opacity: "0.3",

        "& svg path": {
          fill: theme.palette[ownerState.color].contrastText,
        },
      },
    };
  },
  outlined: ({ theme, ownerState }) => ({
    borderColor: theme.palette[ownerState.color].main,
    color: theme.palette[ownerState.color].main,
    backgroundColor: "unset",

    "& svg path": {
      fill: theme.palette[ownerState.color].main,
    },

    "&:hover": {
      borderWidth: "1.5px",
      borderColor: theme.palette[ownerState.color].light,
      color: theme.palette[ownerState.color].light,
      backgroundColor: "unset",

      "& svg path": {
        fill: theme.palette[ownerState.color].light,
      },
    },

    "&:active": {
      borderColor: theme.palette[ownerState.color].dark,
      color: theme.palette[ownerState.color].dark,
      backgroundColor: "unset",

      "& svg path": {
        fill: theme.palette[ownerState.color].dark,
      },
    },

    "&.Mui-disabled": {
      borderColor: theme.palette[ownerState.color].main,
      color: theme.palette[ownerState.color].main,
      opacity: "0.3",

      "& svg path": {
        fill: theme.palette[ownerState.color].main,
      },
    },
  }),
  text: ({ theme, ownerState }) => ({
    color: theme.palette[ownerState.color].main,
    backgroundColor: "unset",

    "& svg path": {
      fill: theme.palette[ownerState.color].main,
    },

    "&:hover": {
      color: theme.palette[ownerState.color].light,
      backgroundColor: "unset",

      "& svg path": {
        fill: theme.palette[ownerState.color].light,
      },
    },

    "&:active": {
      color: theme.palette[ownerState.color].dark,
      backgroundColor: "unset",

      "& svg path": {
        fill: theme.palette[ownerState.color].dark,
      },
    },

    "&.Mui-disabled": {
      color: theme.palette[ownerState.color].main,
      backgroundColor: "unset",
      opacity: "0.3",

      "& svg path": {
        fill: theme.palette[ownerState.color].main,
      },
    },
  }),
};
