import { forwardRef, useContext, useEffect } from "react";
import { Box, styled } from "@mui/material";

import Canvas from "./Canvas";

import SceneAPIContext from "../../context/sceneAPIContext/SceneAPIContext";

import { RendererViewModeTypes } from "../../webgl/renderer/SceneRendererData";
import { useLocation } from "react-router-dom";

const ViewGasRendererViewport = forwardRef((_, ref) => {
  const { activeRendererViewMode, setGasRenderVisibility } =
    useContext(SceneAPIContext);
  const location = useLocation();

  useEffect(() => {
    activeRendererViewMode === RendererViewModeTypes.viewGas &&
      setGasRenderVisibility(true);
  }, [activeRendererViewMode]);

  return (
    <Wrapper mode={activeRendererViewMode}>
      <CanvasWrapper
        isconfiguratormode={location.pathname.includes("configurator") ? 1 : 0}
      >
        <Canvas ref={ref} id="view-gas-viewport-canvas" />
      </CanvasWrapper>
    </Wrapper>
  );
});

export default ViewGasRendererViewport;

const Wrapper = styled(Box)(({ mode }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "rgb(255, 255, 255, 0.4)",
  pointerEvents: "auto",
  visibility: "hidden",
  padding: "20px",

  ...(mode === RendererViewModeTypes.viewGas && {
    visibility: "visible",
  }),
}));

const CanvasWrapper = styled(Box)(({ theme, isconfiguratormode }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "572px",
  height: "408px",
  borderRadius: "20px",
  border: "1px solid #FFFFFF",
  backgroundColor: theme.palette.primary["grey-400"],
  overflow: "hidden",

  [theme.breakpoints.down("laptop")]: {
    maxHeight: "50%",
    maxWidth: "100%",
  },

  [theme.breakpoints.down("tablet")]: {
    top: !isconfiguratormode ? "160px" : "auto",
    maxWidth: "80%",
    maxHeight: !isconfiguratormode ? "35%" : "40%",
  },
}));
