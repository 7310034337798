import { Easing, Tween } from "@tweenjs/tween.js";
import { MathUtils, Mesh } from "three";
import { Scene3DObjectsNames } from "../SceneObjectsPathData";

class Dust {
  tweenOpacitySide = 1;
  dustShown = false;

  MIN_ANIM_DUST_OPACITY = 0.6;
  MAX_DUST_OPACITY = 0.75;

  // Dust.
  showDustAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Quadratic.Out)
    .onUpdate((_, alpha) => {
      alpha = MathUtils.clamp(alpha, 0, this.MAX_DUST_OPACITY);
      this.dustObject.setObjectOpacity(alpha);
    })
    .onComplete(() => {
      this.dustShown = true;
    });

  hideDustAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Quadratic.In)
    .onStart(() => {
      this.dustShown = false;
    })
    .onUpdate((_, alpha) => {
      alpha = MathUtils.clamp(alpha, 0, this.MAX_DUST_OPACITY);
      this.dustObject.setObjectOpacity(this.MAX_DUST_OPACITY - alpha);
    })
    .onComplete(() => {
      this.dustAnimation.isPlaying() && this.dustAnimation.stop();
    });

  dustAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Linear.None)
    .repeat(Infinity)
    .onUpdate((_, alpha) => {
      const yoyoAlpha = this.tweenOpacitySide < 0 ? 1 - alpha : alpha;

      this.dustObject.object.traverse(child => {
        if (child instanceof Mesh) {
          child.material.map.offset.x = alpha;
        }
      });

      if (this.dustShown) {
        const opacity = MathUtils.mapLinear(
          yoyoAlpha,
          0,
          1,
          this.MIN_ANIM_DUST_OPACITY,
          this.MAX_DUST_OPACITY
        );

        this.dustObject.setObjectOpacity(opacity);
      }
    })
    .onRepeat(() => {
      this.tweenOpacitySide *= -1;
    });

  constructor(scene) {
    this.scene = scene;
  }

  init() {
    this.dustObject = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.DustScroll
    );
  }

  start() {
    this.showDustAnimation.isPlaying() && this.showDustAnimation.stop();
    this.hideDustAnimation.isPlaying() && this.hideDustAnimation.stop();
    !this.dustAnimation.isPlaying() && this.dustAnimation.start();

    this.showDustAnimation.start();
  }

  stop(isFinal = false) {
    this.showDustAnimation.isPlaying() && this.showDustAnimation.stop();

    if (isFinal) {
      this.dustAnimation.isPlaying() && this.dustAnimation.stop();
      this.dustObject.setObjectOpacity(0);
    } else {
      this.hideDustAnimation.start();
    }
  }

  update() {
    this.showDustAnimation &&
      this.showDustAnimation.isPlaying() &&
      this.showDustAnimation.update();

    this.hideDustAnimation &&
      this.hideDustAnimation.isPlaying() &&
      this.hideDustAnimation.update();

    if (this.dustAnimation && this.dustAnimation.isPlaying()) {
      this.dustAnimation.update();

      this.scene.sceneRenderers.toggleMainViewRendering(true);
    }
  }
}

export default Dust;
