import { createContext } from "react";

export interface MinervaOptionItemInterface {
  option: string;
  value: string;
}

export interface BuiltMinervasInterface {
  id: string;
  title: string;
  description: string;
  quizData: any;
  optionData: MinervaOptionItemInterface[];
  amount: number;
  order: number;
}

export interface BuiltMinervaContextInterface {
  builtMinervas: BuiltMinervasInterface[] | [];
  addMinervaBuilt: (quizData: any) => void;
  editMinervaBuilt: (id: string, amount: number) => void;
  deleteMinervaBuilt: (id: string) => void;
  noCameraConfig: boolean;
  setNoCameraConfig: (value: boolean) => void;
}

const BuiltMinervaContext = createContext<BuiltMinervaContextInterface | null>(
  null
);

export default BuiltMinervaContext;
