import { Vector3, Euler, Material, Mesh } from "three";
import { getBoundingBoxAndCenterByObject3d } from "../utils/GeometryUtils";

const transparentExceptions = [
  "M_Logo_big",
  "M_animation_rain_scroll",
  "M_animation_rain_scroll.001",
  "M_effect_dust",
  "M_effect_wind_scroll_a.001",
  "M_effect_wind_scroll_b.001",
  "Material_0",
  "M_effect_firegas_a01",
  "M_effect_firegas_a02.001",
  "M_effect_firegas_a03",
  "circle",
];

class SceneObject {
  constructor(
    object,
    initialPosition = new Vector3(0, 0, 0),
    initialRotation = new Euler(0, 0, 0, "XYZ"),
    animations
  ) {
    this.object = object;
    this.initialPosition = initialPosition;
    this.initialRotation = initialRotation;
    this.opacity = 0;

    this.animations = animations;

    this.setInitialPositionAndRotation();
    this.setObjectOpacity(0);

    this.initialBBoxCenter =
      this.getObjectBoundingBoxAndCenter().center.clone();
  }

  setObjectScale(value) {
    this.object.scale.set(value, value, value);
  }

  setObjectRotation(euler) {
    this.object.rotation.copy(euler);
  }

  setObjectPosition(vector) {
    this.object.position.copy(vector);
  }

  getObjectWorldPosition() {
    const worldPosition = new Vector3();
    this.object.getWorldPosition(worldPosition);

    return worldPosition;
  }

  setObjectOpacity(value) {
    const setOpacityValue = (material, value) => {
      material.transparent =
        value !== 1 || transparentExceptions.includes(material.name);
      material.opacity = value;
    };

    const object = this.object.children[0];

    if (value === 0) {
      object.visible = false;
    } else if (value > 0) {
      object.visible = true;
    }

    object.traverse(child => {
      if (child instanceof Mesh) {
        if (child.material instanceof Array) {
          child.material.forEach(m => setOpacityValue(m, value));
        } else if (child.material && child.material instanceof Material) {
          setOpacityValue(child.material, value);
        }

        if (value === 0) {
          child.visible = false;
        } else if (value > 0) {
          child.visible = true;
        }
      }
    });
    this.opacity = value;
  }

  checkIsObjectVisible() {
    return this.object.children[0].visible;
  }

  getObjectBoundingBoxAndCenter() {
    return getBoundingBoxAndCenterByObject3d(this.object);
  }

  setInitialPositionAndRotation() {
    this.setObjectPosition(this.initialPosition);
    this.setObjectRotation(this.initialRotation);
  }
}

export default SceneObject;
