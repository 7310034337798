import { useContext } from "react";
import { Box, styled } from "@mui/material";

import TransformControlWrapper from "./transformControl/TransformControlWrapper";
import CameraViewTogglerWrapper from "./cameraViewToggler/CameraViewTogglerWrapper";

import SceneAPIContext from "../../../common/context/sceneAPIContext/SceneAPIContext";

import { RendererViewModeTypes } from "../../../common/webgl/renderer/SceneRendererData";

const FactoryToolbar = () => {
  const { activeRendererViewMode } = useContext(SceneAPIContext);

  return (
    <ToolBarWrapper>
      {activeRendererViewMode === RendererViewModeTypes.viewMain && (
        <TransformControlWrapper />
      )}
      {activeRendererViewMode !== RendererViewModeTypes.viewMain && (
        <CameraViewTogglerWrapper />
      )}
    </ToolBarWrapper>
  );
};

export default FactoryToolbar;

const ToolBarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("laptop")]: {
    position: "absolute",
    bottom: "50px",
  },
}));
