import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactElement } from "react";

const MenuIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon
      viewBox="0 0 18 18"
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1797_747)">
        <circle cx="16" cy="16" r="2" fill="currentColor" />
        <circle cx="9" cy="16" r="2" fill="currentColor" />
        <circle cx="2" cy="16" r="2" fill="currentColor" />
        <circle cx="16" cy="9" r="2" fill="currentColor" />
        <circle cx="9" cy="9" r="2" fill="currentColor" />
        <circle cx="2" cy="9" r="2" fill="currentColor" />
        <circle cx="2" cy="2" r="2" fill="currentColor" />
        <circle cx="9" cy="2" r="2" fill="currentColor" />
        <circle cx="16" cy="2" r="2" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1797_747">
          <rect width="18" height="18" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default MenuIcon;
