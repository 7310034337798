import { useLocation } from "react-router-dom";
import { Box, styled, useMediaQuery } from "@mui/material";

import HeaderLogo from "./HeaderLogo";
import BuildMinervaButton from "./BuildMinervaButton";
import PreOrderMinervaButton from "./PreOrderMinervaButton";
import CloseConfiguratorButton from "./CloseConfiguratorButton";
import ConfiguratorProgressBar from "./ConfiguratorProgressBar/ConfiguratorProgressBar";
import MenuButton from "./MenuButton";
import { useMenu } from "../../context/menuContext/MenuContext";

const AppHeader = () => {
  const { pathname } = useLocation();

  const isConfigurator = pathname.includes("configurator");
  const isPreorder = pathname.includes("preorder");
  const isDetails = pathname.includes("details");

  const matches = useMediaQuery(theme => theme.breakpoints.up("laptop"));

  const { openMenu } = useMenu();

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLogo />
        {isConfigurator && <ConfiguratorProgressBar />}
        <ButtonWrapper>
          {!isConfigurator && !isPreorder && matches && !isDetails && (
            <BuildMinervaButton />
          )}
          {!isConfigurator && !isPreorder && matches && !isDetails && (
            <PreOrderMinervaButton />
          )}
          {isConfigurator || isPreorder || isDetails ? (
            <CloseConfiguratorButton />
          ) : (
            <MenuButton type="open" handleOnClick={openMenu} />
          )}
        </ButtonWrapper>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default AppHeader;

const HeaderContainer = styled("header")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  pointerEvents: "auto",
  position: "relative",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(4, 4, 4, 6),
  borderRadius: "100px",
  border: `1px solid ${theme.palette.primary["base-border"]}`,
  backgroundColor: theme.palette.primary["base-background"],
  [theme.breakpoints.down("laptop")]: {
    padding: theme.spacing(2, 2, 2, 6),
  },
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
}));
