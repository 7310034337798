import { ReactNode } from "react";
import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import StyledIconButton from "../../../common/components/styledComponents/StyledIconButton";
import { MinusIcon } from "../../../common/assets/svgIcons/icon";

interface MuneTitleProps {
  orientation: "start" | "end";
  isHovered: string;
  title: string;
}

const MuneTitle = ({
  orientation,
  isHovered,

  title,
}: MuneTitleProps): JSX.Element => {
  return (
    <TitleWrapper orientation={orientation}>
      {orientation === "start" ? (
        <>
          <TitleIconButton>
            <MinusIcon />
            <AnimatedIconWrapper ishovered={isHovered}>
              <MinusIcon />
            </AnimatedIconWrapper>
          </TitleIconButton>
          <TitleTypography variant="h4">{title}</TitleTypography>
        </>
      ) : (
        <>
          <TitleTypography variant="h4">{title}</TitleTypography>
          <TitleIconButton>
            <MinusIcon />
            <AnimatedIconWrapper ishovered={isHovered}>
              <MinusIcon />
            </AnimatedIconWrapper>
          </TitleIconButton>
        </>
      )}
    </TitleWrapper>
  );
};

export default MuneTitle;

const TitleWrapper = styled(Box)(
  ({ orientation }: { orientation: string }): CSSProperties => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "24px",

    ...(orientation === "start" && {
      justifyContent: "flex-start",
      paddingLeft: "20px",
    }),

    ...(orientation === "end" && {
      justifyContent: "flex-end",
      paddingRight: "20px",
    }),
  })
);

const TitleTypography = styled(Typography)(
  ({ theme }): CSSProperties => ({
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.64px",
    color: theme.palette.primary["text-primary"],
  })
);

const TitleIconButton = styled(
  ({ children }: { children: ReactNode }): JSX.Element => (
    <StyledIconButton variant="contained" color="button-secondary-icon">
      {children}
    </StyledIconButton>
  )
)(
  (): CSSProperties => ({
    padding: "8px",
    backdropFilter: "blur(15px)",
    width: "48px",
    height: "48px",
    "& svg": {
      fontSize: "20px",
    },
  })
);

const AnimatedIconWrapper = styled(Box)(
  ({ ishovered }: { ishovered: string }): CSSProperties => ({
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    transform: ishovered === "hovered" ? "rotateZ(0deg)" : "rotateZ(90deg)",
    transition: "transform 0.3s",
  })
);
