import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import AnimatedIconButton from "../styledComponents/AnimatedIconButton";

import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/svgIcons/icon";

import { PagesList } from "./PagesList";
import { useNavigationContext } from "../../context/navigationContext/NavigationContext";

const FooterNavigation = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isScroll, setScroll] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("");

  const { activeIndex, setActiveIndex } = useNavigationContext();

  const goToPage = (path: string): void => {
    navigate(path);
    setActiveIndex(PagesList.findIndex(el => el.link === path));
  };

  const toFirstPage = (): void => {
    goToPage(PagesList[0].link);
  };

  const onNextButton = (): void => {
    goToPage(PagesList[activeIndex + 1].link);
  };

  const onPrevButton = (): void => {
    goToPage(PagesList[activeIndex - 1].link);
  };

  useEffect(() => {
    let scrollTimeout: any;

    const handleScrollStart = (): void => {
      setScroll(true);
    };

    const handleScrollEnd = (): void => {
      setScroll(false);
    };

    const handleScroll = (delta: number): void => {
      scrollTimeout = setTimeout(() => {
        handleScrollEnd();
      }, 50);

      if (delta === -1) {
        setScrollDirection("top");
      } else if (delta === 1) {
        setScrollDirection("bottom");
      }
      handleScrollStart();
    };

    const handleWheel = (event: WheelEvent): void => {
      clearTimeout(scrollTimeout);

      if (event.deltaMode !== 0) {
        handleScrollEnd();
      } else {
        const delta = Math.sign(event.deltaY);
        handleScroll(delta);
      }
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);

      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    if (!isScroll) {
      return;
    }

    if (scrollDirection === "top") {
      activeIndex !== 0 && onPrevButton();
    } else {
      activeIndex !== PagesList.length - 1 && onNextButton();
    }
  }, [isScroll, setScrollDirection]);

  useEffect(() => {
    setActiveIndex(PagesList.findIndex(el => el.link === location.pathname));
  }, [location]);

  return (
    <FooterWrapper>
      <AnimatedIconButton
        variant="contained"
        color="button-primary-icon"
        startIcon={<ArrowLeftIcon />}
        endIcon={false}
        opacityIcon={false}
        disabled={activeIndex === 0}
        onClick={onPrevButton}
      ></AnimatedIconButton>

      <ButtonsWrapper gap={4} direction={"row"} alignItems={"center"}>
        {PagesList.map((el, i) => (
          <PageButtonWrapper
            key={el.pageName}
            className={location.pathname === el.link ? "active" : ""}
          >
            <PageButton
              onClick={(): void => goToPage(el.link)}
              onTouchStart={(): void => goToPage(el.link)}
            >
              <Typography component="p" variant="body-500-14">
                {i + 1}
              </Typography>
            </PageButton>
          </PageButtonWrapper>
        ))}
      </ButtonsWrapper>

      <AnimatedIconButton
        variant="contained"
        color="button-primary-icon"
        endIcon={<ArrowRightIcon />}
        startIcon={false}
        opacityIcon={false}
        disabled={activeIndex === PagesList.length - 1}
        onClick={onNextButton}
      ></AnimatedIconButton>
      <TopButton active={activeIndex !== 0 ? 1 : 0}>
        <AnimatedIconButton
          variant="outlined"
          color="button-primary-icon"
          endIcon={<ArrowLeftIcon />}
          startIcon={false}
          opacityIcon={false}
          onClick={toFirstPage}
          disabled={activeIndex === 0}
        ></AnimatedIconButton>
      </TopButton>
    </FooterWrapper>
  );
};

export default FooterNavigation;

const FooterWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "24px",
  left: 0,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(4),

  [theme.breakpoints.down("tablet")]: {
    width: "calc(100% - 32px)",

    gap: theme.spacing(2),
    justifyContent: "space-between",
    left: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const ButtonsWrapper = styled(Stack)(({ theme }) => ({
  "&:has(:hover).active": {
    width: "8px",
    height: "8px",
    "& button": {
      opacity: 0,
    },
  },

  [theme.breakpoints.up("mobile")]: {
    "&:hover.active:hover": {
      width: "32px",
      height: "32px",

      "& button": {
        opacity: 1,
      },
    },
  },

  [theme.breakpoints.down("tablet")]: {
    gap: theme.spacing(2),

    justifyContent: "space-between",
  },
}));

const PageButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "100%",
  aspectRatio: 1 / 1,
  opacity: 0,
  left: 0,
  margin: "0",
  padding: "0",
  transition: "all 0.5s",
  overflow: "hidden",

  "& p": {
    color: theme.palette.primary["text-secondary"],
    width: "100%",

    userSelect: "none",
  },

  userSelect: "none",
}));

const PageButtonWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.contrastText,
  position: "relative",

  width: "8px",
  height: "8px",
  borderRadius: "100%",
  padding: "0",
  cursor: "pointer",
  pointerEvents: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "all 0.5s",
  "& button": {
    width: "100%",
    height: "100%",
  },

  [theme.breakpoints.up("laptop")]: {
    "&:hover": {
      width: "32px",
      height: "32px",
      "& button": {
        opacity: 1,
        userSelect: "none",
      },
      userSelect: "none",
    },
  },

  "&.active ": {
    width: "32px",
    height: "32px",
    "& button": {
      opacity: 1,
    },
  },
}));

const TopButton = styled(Box)<{ active: number }>(({ theme, active }) => ({
  position: "absolute",
  opacity: active,
  right: "24px",
  top: "0",
  height: "100%",
  transform: "rotate(90deg) !important",
  transition: "all 0.5s",

  [theme.breakpoints.down("tablet")]: {
    position: "unset",
    display: active ? "flex" : "none",
  },
}));
