import { useResponsive } from "../../../hooks/useResponsive";

import DesktopConfiguratorProgressBar from "./DesktopConfiguratorProgressBar";
import MobileConfiguratorProgressBar from "./MobileConfiguratorProgressBar";

const ConfiguratorProgressBar = () => {
  const { mobile } = useResponsive();

  return (
    <>
      {mobile ? (
        <MobileConfiguratorProgressBar />
      ) : (
        <DesktopConfiguratorProgressBar />
      )}
    </>
  );
};

export default ConfiguratorProgressBar;
