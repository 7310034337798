import StyledIconButton from "../../../../common/components/styledComponents/StyledIconButton";

import { CloseIcon } from "../../../../common/assets/svgIcons/icon";

const CameraViewCloseButton = ({ handleOnClick }) => {
  return (
    <StyledIconButton
      variant="contained"
      color="button-primary-icon"
      onClick={handleOnClick}
    >
      <CloseIcon />
    </StyledIconButton>
  );
};

export default CameraViewCloseButton;
