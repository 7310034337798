export const ThemeTypography = {
  fontFamily: "Hubot-Sans-Medium-500",
  fontSize: 18,
  h1: {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "56px",
    fontStyle: "normal",
    lineHeight: "120%",
    letterSpacing: "-1.68px",
  },
  h2: {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "48px",
    fontStyle: "normal",
    lineHeight: "120%",
    letterSpacing: "-1.44px",
  },
  h3: {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "40px",
    fontStyle: "normal",
    lineHeight: "120%",
    letterSpacing: "-1.2px",

    "@media (max-width:640px),(max-height:500px)": {
      fontSize: "24px",
    },
  },

  h4: {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "32px",
    fontStyle: "normal",
    lineHeight: "125%",
    letterSpacing: "-0.96px",

    "@media (max-width:640px), (max-height:500px)": {
      fontSize: "20px",
    },
  },
  h5: {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "24px",
    fontStyle: "normal",
    lineHeight: "140%",
    letterSpacing: "-0.72px",

    "@media (max-width:640px), (max-height:500px)": {
      fontSize: "18px",
    },
    "@media (max-width:400px) and (max-height: 800px)": {
      fontSize: "16px",
    },
  },
  h6: {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "130%",
    letterSpacing: "-0.6px",

    "@media (max-width:640px), (max-height:500px)": {
      fontSize: "18px",
    },
    "@media (max-width:400px),(max-height: 800px)": {
      fontSize: "14px",
    },
  },
  "body-600-20": {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "150%",
  },
  "body-600-18": {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:640px),(max-height:500px)": {
      fontSize: "14px",
    },
  },
  "body-600-16": {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:640px), (max-height:500px)": {
      fontSize: "12px",
    },
  },
  "body-600-14": {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:400px) and (max-height: 800px)": {
      fontSize: "12px",
    },
  },
  "body-600-12": {
    fontFamily: "Hubot-Sans-Semi-bold-600",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "150%",
  },
  "body-500-20": {
    fontFamily: "Hubot-Sans-Medium-500",
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "150%",
  },
  "body-500-18": {
    fontFamily: "Hubot-Sans-Medium-500",
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:640px),(max-height:500px)": {
      fontSize: "14px",
    },

    "@media (max-width:400px and max-height: 800px)": {
      fontSize: "12px",
    },
  },
  "body-500-16": {
    fontFamily: "Hubot-Sans-Medium-500",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:640px), (max-height:500px)": {
      fontSize: "12px",
    },
  },
  "body-500-14": {
    fontFamily: "Hubot-Sans-Medium-500",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:400px) and (max-height: 800px)": {
      fontSize: "12px",
    },
  },
  "body-500-12": {
    fontFamily: "Hubot-Sans-Medium-500",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "150%",
  },
  "body-400-20": {
    fontFamily: "Hubot-Sans-Normal-400",
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "150%",
  },
  "body-400-18": {
    fontFamily: "Hubot-Sans-Normal-400",
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:640px), (max-height:500px)": {
      fontSize: "14px",
    },
    "@media (max-width:400px) and (max-height: 800px)": {
      fontSize: "12px",
    },
  },
  "body-400-16": {
    fontFamily: "Hubot-Sans-Normal-400",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:640px), (max-height:500px)": {
      fontSize: "12px",
    },
  },
  "body-400-14": {
    fontFamily: "Hubot-Sans-Normal-400",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "150%",
    "@media (max-width:400px) and (max-height: 800px)": {
      fontSize: "12px",
    },
  },
  "body-400-12": {
    fontFamily: "Hubot-Sans-Normal-400",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "150%",
  },
  button: {
    fontFamily: "Hubot-Sans-Medium-500",
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "24px",
    "@media (max-width:400px) and (max-height: 800px)": {
      fontSize: "14px",
    },
  },
};
