import { Box, styled } from "@mui/material";

import Background from "./Background";
import UILayer from "../layers/UILayer";
import WebGLLayer from "../layers/WebGLLayer";

import SceneAPIContextProvider from "../../context/sceneAPIContext/SceneAPIContextProvider";
import BuiltMinervaContextProvider from "../../context/builtMinervaContext/BuiltMinervaContextProvider";
import ConfiguratorProgressBarContextProvider from "../../context/configuratorProgressBarContext/ConfiguratorProgressBarContextProvider";
import LocationMapContextProvider from "../../context/locationMapContext/LocationMapContextProvider";
import { MenuContextProvider } from "../../context/menuContext/MenuContext";
import { NavigationContextProvider } from "../../context/navigationContext/NavigationContext";

const Layout = () => {
  return (
    <LayoutWrapper>
      <SceneAPIContextProvider>
        <LocationMapContextProvider>
          <ConfiguratorProgressBarContextProvider>
            <BuiltMinervaContextProvider>
              <MenuContextProvider>
                <NavigationContextProvider>
                  <Background />
                  <WebGLLayer />
                  <UILayer />
                </NavigationContextProvider>
              </MenuContextProvider>
            </BuiltMinervaContextProvider>
          </ConfiguratorProgressBarContextProvider>
        </LocationMapContextProvider>
      </SceneAPIContextProvider>
    </LayoutWrapper>
  );
};

export default Layout;

const LayoutWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
}));
