import { Button, styled } from "@mui/material";

const StyledIconButton = styled(Button)({
  padding: "12.5px",
  "& svg": {
    fontSize: "20px",
  },
});

export default StyledIconButton;
