import { Easing, Tween } from "@tweenjs/tween.js";
import { AnimationMixer, MathUtils, Mesh } from "three";
import { Scene3DObjectsNames } from "../SceneObjectsPathData";

const clipsNames = {
  skydrops: "anim_effect_rain_skydrops",
  waterdrops: "anim_rain-waterdrops",
};

class Rain {
  tweenOpacitySide = 1;
  rainShown = false;
  flashEnabled = false;
  animationActions = {};
  intensity = 0;
  TRANSPARENT_THRESHOLD = 0.001;

  // Rain.
  showRainAnimation = new Tween(null)
    .to({}, 100)
    .easing(Easing.Quadratic.Out)
    .onStart(() => {
      this.animationActions[clipsNames.skydrops].reset().play();
      this.animationActions[clipsNames.waterdrops].reset().play();
    })
    .onUpdate((_, alpha) => {
      const waterdropsMaxOpacity = MathUtils.clamp(this.intensity, 0, 0.3);

      this.rainObject.setObjectOpacity(alpha * this.intensity);
      this.rainSkydropsObject.setObjectOpacity(alpha);
      this.rainWaterdropsObject.setObjectOpacity(alpha * waterdropsMaxOpacity);
    })
    .onComplete(() => {
      this.rainShown = true;
    });

  hideRainAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Quadratic.In)
    .onStart(() => {
      this.rainShown = false;
    })
    .onUpdate((_, alpha) => {
      const waterdropsMaxOpacity = MathUtils.clamp(this.intensity, 0, 0.3);

      this.rainObject.setObjectOpacity((1 - alpha) * this.intensity);
      this.rainSkydropsObject.setObjectOpacity((1 - alpha) * this.intensity);
      this.rainWaterdropsObject.setObjectOpacity(
        (1 - alpha) * waterdropsMaxOpacity
      );
    })
    .onComplete(() => {
      this.rainAnimation.isPlaying() && this.rainAnimation.stop();
    });

  rainAnimation = new Tween(null)
    .to({}, 5000)
    .easing(Easing.Linear.None)
    .repeat(Infinity)
    .onUpdate((_, alpha) => {
      this.rainObject.object.traverse(child => {
        if (child instanceof Mesh) {
          child.material.map.offset.y = -alpha;
          child.material.normalMap.offset.y = -alpha;
        }
      });
    })
    .onRepeat(() => {
      this.tweenOpacitySide *= -1;
    });

  constructor(scene) {
    this.scene = scene;
  }

  init(flashEnabled = false) {
    this.rainObject = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.RainScroll
    );

    this.rainSkydropsObject =
      this.scene.sceneObjects.getSceneObjectInstanceByName(
        Scene3DObjectsNames.RainSkydrops
      );

    this.flashEnabled = flashEnabled;

    this.flashObject =
      this.rainSkydropsObject.object.getObjectByName("effect_rain_flash");

    this.rainWaterdropsObject =
      this.scene.sceneObjects.getSceneObjectInstanceByName(
        Scene3DObjectsNames.RainWaterdrops
      );

    this.skydropsMixer = new AnimationMixer(this.rainSkydropsObject.object);

    // Raindrops clips.
    for (const clip of this.rainSkydropsObject.animations) {
      this.animationActions[clip.name] = this.skydropsMixer.clipAction(clip);
      this.animationActions[clip.name].timeScale = 0.005;
    }

    this.waterdropsMixer = new AnimationMixer(this.rainWaterdropsObject.object);

    // Waterdrops clips.
    for (const clip of this.rainWaterdropsObject.animations) {
      this.animationActions[clip.name] = this.waterdropsMixer.clipAction(clip);
      this.animationActions[clip.name].timeScale = 0.0025;
    }

    this.setIntensity(this.intensity);
  }

  setIntensity(intensity) {
    this.intensity = intensity;

    const opacity = MathUtils.clamp(
      intensity - this.TRANSPARENT_THRESHOLD,
      0,
      1
    );

    this.rainObject.setObjectOpacity(opacity);
    const waterdropsMaxOpacity = MathUtils.clamp(this.intensity, 0, 0.3);
    this.rainWaterdropsObject.setObjectOpacity(waterdropsMaxOpacity);

    const scrollDuration = MathUtils.mapLinear(intensity, 0, 1, 7000, 3000);

    this.rainAnimation.isPlaying() &&
      this.rainAnimation.duration(scrollDuration);
  }

  start() {
    this.intensity = 1;

    this.showRainAnimation.isPlaying() && this.showRainAnimation.stop();
    this.hideRainAnimation.isPlaying() && this.hideRainAnimation.stop();
    !this.rainAnimation.isPlaying() && this.rainAnimation.start();

    this.showRainAnimation.start();
  }

  stop(isFinal = false) {
    this.showRainAnimation.isPlaying() && this.showRainAnimation.stop();
    this.animationActions[clipsNames.skydrops].stop();
    this.animationActions[clipsNames.waterdrops].stop();

    if (isFinal) {
      this.rainAnimation.isPlaying() && this.rainAnimation.stop();
      this.intensity = 0;
    } else {
      this.hideRainAnimation.start();
    }
  }

  update(dt) {
    if (this.flashObject) {
      this.flashObject.visible = this.intensity > 0.8 && this.flashEnabled;
      this.rainSkydropsObject.object.visible = this.intensity > 0.1;
    }

    this.showRainAnimation &&
      this.showRainAnimation.isPlaying() &&
      this.showRainAnimation.update();

    this.hideRainAnimation &&
      this.hideRainAnimation.isPlaying() &&
      this.hideRainAnimation.update();

    if (this.rainAnimation && this.rainAnimation.isPlaying()) {
      this.rainAnimation.update();

      this.skydropsMixer.update(dt);
      this.waterdropsMixer.update(dt);

      this.scene.sceneRenderers.toggleMainViewRendering(true);
    }
  }
}

export default Rain;
