import { initScene } from "./scene/Scene";
import SceneLights from "./light/SceneLight";
import SceneRenderers from "./renderer/SceneRenderers";
import SceneObjects from "./sceneObjects/SceneObjects";
import SceneControls from "./controls/SceneControls";
import MapTilesRenderer from "./mapTiles/MapTilesRenderer";
import SceneStages from "./stage/SceneStages";
import SceneCameras from "./camera/SceneCameras";
import SceneAnimation from "./sceneObjects/SceneAnimation";
import { Clock } from "three";
import SceneTransitionAnimation from "./sceneObjects/SceneTransitionAnimation";

class ThreeScene {
  clock = new Clock();

  constructor(
    mainRenderCanvasElement,
    viewGasRenderCanvasElement,
    view360RenderCanvasElements
  ) {
    this.mainRenderCanvasElement = mainRenderCanvasElement;
    this.viewGasRenderCanvasElement = viewGasRenderCanvasElement;
    this.view360RenderCanvasElements = view360RenderCanvasElements;

    this.mainViewportDimensions = {
      width: this.mainRenderCanvasElement.clientWidth,
      height: this.mainRenderCanvasElement.clientHeight,
    };

    this.viewGasViewportDimensions = {
      width: this.viewGasRenderCanvasElement.clientWidth,
      height: this.viewGasRenderCanvasElement.clientHeight,
    };

    this.view360ViewportDemensions = this.view360RenderCanvasElements.map(
      element => {
        return {
          width: element.clientWidth,
          height: element.clientHeight,
        };
      }
    );

    this.scene = initScene();

    this.sceneObjects = new SceneObjects(this);

    this.sceneCameras = new SceneCameras(
      this.scene,
      this.sceneObjects,
      this.mainViewportDimensions,
      this.viewGasViewportDimensions,
      this.view360RenderCanvasElements,
      this.view360ViewportDemensions
    );

    this.lights = new SceneLights(this.scene);

    this.sceneRenderers = new SceneRenderers(
      this.scene,
      this.sceneCameras,
      this.mainRenderCanvasElement,
      this.mainViewportDimensions,
      this.viewGasRenderCanvasElement,
      this.viewGasViewportDimensions,
      this.view360RenderCanvasElements,
      this.view360ViewportDemensions
    );

    this.sceneControls = new SceneControls(
      this.scene,
      this.sceneObjects,
      this.sceneCameras,
      this.sceneRenderers,

      this.mainRenderCanvasElement,
      this.viewGasRenderCanvasElement,
      this.view360RenderCanvasElements
    );

    this.mapTilesRenderer = new MapTilesRenderer(
      this.scene,
      this.sceneCameras.mainCamera.camera,
      this.sceneRenderers.mainRenderer.renderer,
      this.sceneControls,
      this.sceneObjects
    );

    this.sceneStages = new SceneStages(
      this.scene,
      this.sceneCameras.mainCamera.camera,
      this.lights,
      this.sceneObjects,
      this.sceneControls,
      this.sceneRenderers
    );

    this.sceneAnimation = new SceneAnimation(this);
    this.sceneTransitionAnimation = new SceneTransitionAnimation(this);
  }

  dispose() {
    this.sceneRenderers?.dispose();
    this.sceneControls?.dispose();
  }

  onWindowResize() {
    this.mainViewportDimensions = {
      width: this.mainRenderCanvasElement.clientWidth,
      height: this.mainRenderCanvasElement.clientHeight,
    };

    this.viewGasViewportDimensions = {
      width: this.viewGasRenderCanvasElement.clientWidth,
      height: this.viewGasRenderCanvasElement.clientHeight,
    };

    this.view360ViewportDemensions = this.view360RenderCanvasElements.map(
      element => {
        return {
          width: element.clientWidth,
          height: element.clientHeight,
        };
      }
    );

    this.sceneCameras.resize(
      this.mainViewportDimensions,
      this.viewGasViewportDimensions,
      this.view360ViewportDemensions
    );

    this.sceneRenderers.resize(
      this.mainViewportDimensions,
      this.viewGasViewportDimensions,
      this.view360ViewportDemensions
    );
  }

  async initSceneComponents(proggresCallBack) {
    await this.sceneObjects.load(proggresCallBack);

    this.sceneCameras.addCameraToScene();
    this.sceneObjects.setAnisotropy();
  }

  loadSceneStage(name, device, duration = 1000, CB = () => {}) {
    if (
      this.sceneStages.loadedSceneStageName === name &&
      this.sceneStages.loadedSceneStageDevice === device
    ) {
      return;
    }

    this.sceneStages.loadStage(
      name,
      device,
      this.sceneStages.isFirstStageAlreadyLoaded ? duration : 0,
      CB
    );

    this.sceneStages.isFirstStageAlreadyLoaded = true;
  }

  update() {
    const dt = this.clock.getDelta() * 1000;

    this.sceneControls?.update();
    this.sceneRenderers?.render(
      this.scene,
      this.sceneCameras.mainCamera,
      this.sceneCameras.gasCamera,
      this.sceneCameras.view360Cameras
    );
    this.sceneStages?.update();
    this.sceneAnimation?.update(dt);
    this.sceneTransitionAnimation?.update();

    this.mapTilesRenderer?.render(
      this.sceneCameras.mainCamera.camera,
      this.sceneRenderers.mainRenderer.renderer
    );

    if (this.sceneRenderers.viewGasRenderer.enabled) {
      this.mapTilesRenderer?.render(
        this.sceneCameras.gasCamera.camera,
        this.sceneRenderers.viewGasRenderer.renderer
      );
    }

    this.sceneRenderers.view360Renderers.forEach(({ renderer, enabled }, i) => {
      if (enabled) {
        this.mapTilesRenderer?.render(
          this.sceneCameras.view360Cameras[i].camera,
          renderer
        );
      }
    });
  }
}

export default ThreeScene;
