import SceneControlsTransitionAnimation from "./sceneTransitionAnimation/SceneControlsTransitionAnimation";
import SceneObjectsTransitionAnimation from "./sceneTransitionAnimation/SceneObjectsTransitionAnimation";

import { SceneStageData } from "./SceneStageData";

class SceneStages {
  constructor(
    scene,
    mainCamera,
    sceneLights,
    sceneObjects,
    sceneControls,
    sceneRenderers
  ) {
    this.scene = scene;
    this.mainCamera = mainCamera;
    this.sceneLights = sceneLights;
    this.sceneObjects = sceneObjects;
    this.sceneControls = sceneControls;
    this.sceneRenderers = sceneRenderers;

    this.isFirstStageAlreadyLoaded = false;
    this.loadedSceneStageName = null;
    this.loadedSceneStageDevice = null;

    this.sceneControlsAnimation = new SceneControlsTransitionAnimation(
      this.sceneControls,
      this.sceneObjects,
      this.sceneRenderers
    );

    this.sceneObjectsAnimation = new SceneObjectsTransitionAnimation(
      this.sceneObjects,
      this.sceneRenderers
    );
  }

  loadStage(name, device, animationDuration, CB) {
    this.loadedSceneStageName = name;
    this.loadedSceneStageDevice = device;

    const stageData = SceneStageData[name];

    const { sceneControls, sceneObjects } = stageData;

    this.sceneControlsAnimation.run(
      sceneControls[device],
      animationDuration,
      CB
    );
    this.sceneObjectsAnimation.run(sceneObjects, animationDuration, CB);
  }

  update() {
    this.sceneControlsAnimation.update();
    this.sceneObjectsAnimation.update();
  }
}

export default SceneStages;
