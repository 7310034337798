import { createContext, ReactNode, useContext, useState } from "react";

interface MenuContext {
  menuState: boolean;
  openMenu: () => void;
  closeMenu: () => void;
}

const MenuContext = createContext<MenuContext | null>(null);

interface MenuContextProviderProps {
  children: ReactNode;
}

export const MenuContextProvider = ({
  children,
}: MenuContextProviderProps): any => {
  const [menuState, setMenuState] = useState(false);

  const openMenu = (): void => {
    setMenuState(true);
  };
  const closeMenu = (): void => {
    setMenuState(false);
  };

  const contextValue = {
    menuState,
    openMenu,
    closeMenu,
  };

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
};

export const useMenu = (): MenuContext => {
  const context = useContext(MenuContext);

  if (context == null) {
    throw new Error("useMenu cannot be used without its provider");
  }

  return context;
};
