import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography, styled } from "@mui/material";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "../../../common/assets/svgIcons/icon";

const MenuItem = ({ title, describe, navigationPathName, orientation }) => {
  const navigate = useNavigate();

  const [isItemHovered, setIsItemHovered] = useState("default");

  const handleOnMouseEnter = () => {
    setIsItemHovered("hovered");
  };

  const handleOnMouseLeave = () => {
    setIsItemHovered("default");
  };

  const handleOnclick = () => {
    navigate(navigationPathName);
  };

  return (
    <MenuItemWrapper
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnclick}
      orientation={orientation}
    >
      {orientation === "start" && (
        <IconAnimationWrapper orientation={orientation}>
          <IconWrapper isitemhovered={isItemHovered} orientation={orientation}>
            <ArrowLeftIcon />
          </IconWrapper>
          <IconWrapper isitemhovered={isItemHovered} orientation={orientation}>
            <ArrowLeftIcon />
          </IconWrapper>
        </IconAnimationWrapper>
      )}

      <Stack alignItems={"flex-end"}>
        <ItemTitle
          variant="body-500-18"
          isitemhovered={isItemHovered}
          orientation={orientation}
        >
          {title}
        </ItemTitle>
        <ItemText variant="body-400-14">{describe}</ItemText>
      </Stack>

      {orientation === "end" && (
        <IconAnimationWrapper orientation={orientation}>
          <IconWrapper isitemhovered={isItemHovered} orientation={orientation}>
            <ArrowRightIcon />
          </IconWrapper>
          <IconWrapper isitemhovered={isItemHovered} orientation={orientation}>
            <ArrowRightIcon />
          </IconWrapper>
        </IconAnimationWrapper>
      )}
    </MenuItemWrapper>
  );
};

export default MenuItem;

const MenuItemWrapper = styled(Box)(({ theme, orientation }) => ({
  position: "relative",
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  padding: "4px 0px",

  ...(orientation === "start" && {
    justifyContent: "flex-start",
    paddingLeft: "20px",
  }),

  ...(orientation === "end" && {
    justifyContent: "flex-end",
    paddingRight: "20px",
  }),

  color: theme.palette.primary["text-secondary"],

  "&:hover": {
    color: theme.palette.primary["text-primary"],
  },
}));

const ItemTitle = styled(Typography)(({ isitemhovered, orientation }) => ({
  transition: "all 0.3s",
  width: "100%",

  ...(orientation === "start" && {
    textAlign: "left",
    transform:
      isitemhovered === "hovered" ? "translateX(8px)" : "translateX(0px)",
  }),

  ...(orientation === "end" && {
    textAlign: "right",
    transform:
      isitemhovered === "hovered" ? "translateX(-8px)" : "translateX(0px)",
  }),
}));

const ItemText = styled(Typography)(() => ({
  textAlign: "right",
}));

const IconAnimationWrapper = styled(Box)(({ orientation }) => ({
  pointerEvents: "none",
  position: "absolute",
  top: "6px",

  width: "20px",
  height: "20px",
  overflow: "hidden",
  display: "flex",

  ...(orientation === "start" && {
    left: 0,
  }),

  ...(orientation === "end" && {
    right: 0,
  }),
}));

const IconWrapper = styled(Box)(({ isitemhovered, orientation }) => ({
  width: "100%",
  height: "100%",
  transition: "all 0.3s",
  "& svg": {
    fontSize: "20px",
  },

  ...(orientation === "start" && {
    transform:
      isitemhovered === "hovered" ? "translateX(-100%)" : "translateX(0%)",
    opacity: isitemhovered === "hovered" ? 1 : 0,
  }),

  ...(orientation === "end" && {
    transform:
      isitemhovered === "hovered" ? "translateX(0%)" : "translateX(-100%)",
    opacity: isitemhovered === "hovered" ? 1 : 0,
  }),
}));
