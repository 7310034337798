import { MathUtils } from "three";

class LookCameraControls {
  constructor(domElement) {
    this.enabled = true;

    this.enableZoom = true;
    this.minZoom = 1;
    this.maxZoom = 5;

    this.minPolarAngle = MathUtils.degToRad(0);
    this.maxPolarAngle = MathUtils.degToRad(180);

    this.rotationSpeed = 0.006;
    this.zoomSpeed = 0.002;

    this.domElement = domElement;

    this.horizontalRotationObject = null;
    this.verticalRotationObject = null;
    this.camera = null;

    this.domElement.addEventListener(
      "mousedown",
      this.handleMouseDown.bind(this)
    );
    this.domElement.addEventListener(
      "mousemove",
      this.handleMouseMove.bind(this)
    );
    this.domElement.addEventListener("wheel", this.handOnWheel.bind(this));
    document.body.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  dispose() {
    this.domElement.removeEventListener(
      "mousedown",
      this.handleMouseDown.bind(this)
    );
    this.domElement.removeEventListener(
      "mousemove",
      this.handleMouseMove.bind(this)
    );
    this.domElement.removeEventListener("wheel", this.handOnWheel.bind(this));
    document.body.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  attach(camera, horizontalRotationObject, verticalRotationObject) {
    this.camera = camera;
    this.horizontalRotationObject = horizontalRotationObject;
    this.verticalRotationObject = verticalRotationObject;
  }

  detach() {
    this.camera = null;
    this.horizontalRotationObject = null;
    this.verticalRotationObject = null;
  }

  handleMouseDown(event) {
    if (!this.enabled || !this.camera) {
      return;
    }

    if (event.button === 0) {
      this.leftDown = true;
    }
  }

  handleMouseMove(event) {
    if (!this.enabled || !this.camera) {
      return;
    }

    event.preventDefault();

    if (this.leftDown) {
      const dx =
        event.movementX || event.mozMovementX || event.webkitMovementX || 0;
      const dy =
        event.movementY || event.mozMovementY || event.webkitMovementY || 0;

      this.rotateCamera(dx, dy);
    }
  }

  handleMouseUp() {
    this.leftDown = false;
  }

  handOnWheel(event) {
    if (!this.enabled || !this.camera || !this.enableZoom) {
      return;
    }

    event.preventDefault();

    this.zoomCamera(event.deltaY);
  }

  rotateCamera(dx, dy) {
    this.horizontalRotationObject.rotation.y += dx * this.rotationSpeed;
    this.verticalRotationObject.rotation.x -= dy * this.rotationSpeed;

    this.verticalRotationObject.rotation.x = MathUtils.clamp(
      this.verticalRotationObject.rotation.x,
      Math.PI / 2 - this.maxPolarAngle,
      Math.PI / 2 - this.minPolarAngle
    );
  }

  zoomCamera(delta) {
    this.camera.zoom -= delta * this.zoomSpeed;
    this.camera.zoom = MathUtils.clamp(
      this.camera.zoom,
      this.minZoom,
      this.maxZoom
    );

    this.camera.updateProjectionMatrix();
  }
}

export default LookCameraControls;
