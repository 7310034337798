import { styled } from "@mui/material";

import { DeleteIcon } from "../../assets/svgIcons/icon";
import StyledIconButton from "../styledComponents/StyledIconButton";

const MinervaCardDeleteButton = ({ builtId, handleOnDelete }) => {
  const handleOnClick = () => {
    handleOnDelete(builtId);
  };

  return (
    <DeleteButton
      onClick={handleOnClick}
      variant="text"
      color="button-ghost-icon"
    >
      <DeleteIcon />
    </DeleteButton>
  );
};

export default MinervaCardDeleteButton;

const DeleteButton = styled(StyledIconButton)(() => ({
  height: "40px",
  minWidth: "40px",
  padding: "10px",
}));
