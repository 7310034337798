import { Box, Stack, Typography, styled } from "@mui/material";
import { TransparentDirectionIcon } from "../../../common/assets/svgIcons/icon";

type Props = {
  title: string;
  subtitle: string;
  icon: JSX.Element;
};

const FeatureLine = ({ title, subtitle, icon }: Props): JSX.Element => {
  return (
    <Feature>
      <TextWrapper>
        <Typography variant="h6">{title}</Typography>
        <Typography component={"p"} variant="body-400-14">
          {subtitle}
        </Typography>
      </TextWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <TransparentDirectionIcon />
    </Feature>
  );
};

export default FeatureLine;

const Feature = styled(Stack)(({ theme }) => ({
  height: "100%",
  flexDirection: "row",
  justifyContent: "center",
  gap: theme.spacing(6),

  "&>svg": {
    width: "150px",
    [theme.breakpoints.down("laptop")]: {
      display: "none",
    },
  },

  [theme.breakpoints.down("laptop")]: {
    flexDirection: "row-reverse",
    gap: theme.spacing(4),
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.primary["brand-orange"],
  borderRadius: "100%",
  aspectRatio: "1/1",
  "& svg": {
    width: "48px",
  },

  [theme.breakpoints.down("laptop")]: {
    width: "40px",
    height: "40px",
    "& svg": {
      fontSize: "20px",
      width: "40px",
    },
  },
}));

const TextWrapper = styled(Stack)(({ theme }) => ({
  alignItems: "flex-end",

  "& p": {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("tablet")]: {
      textAlign: "right",
    },
  },

  [theme.breakpoints.down("laptop")]: {
    alignItems: "flex-start",
  },
}));
