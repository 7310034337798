import { AnimationMixer, LoopOnce } from "three";
import { Scene3DObjectsNames } from "../SceneObjectsPathData";

const clipsNames = {
  out: "anim_sensor_out",
  cameraToogle: "anim_cam_out",
};

class ReplaceSensor {
  animationActions = {};

  constructor(scene) {
    this.scene = scene;
    this.timeouts = [];
    this.isPlaying = false;
  }

  init() {
    this.sensorAnim = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.MinervaCameraSingleCamera
    );

    this.sensorAnimMixer = new AnimationMixer(this.sensorAnim.object);

    for (const clip of this.sensorAnim.animations) {
      if (clip.name !== clipsNames.out) {
        return;
      }

      this.animationActions[clip.name] = this.sensorAnimMixer.clipAction(clip);
      this.animationActions[clip.name].setLoop(LoopOnce);

      this.animationActions[clip.name].clampWhenFinished = true;

      this.animationActions[clip.name].timeScale = 1;
      this.animationActions[clip.name].reset();
    }
  }

  start(CB = () => {}) {
    this.stop();
    this.isPlaying = true;
    this.animationActions[clipsNames.out].play();

    this.sensorAnimMixer.addEventListener("finished", event => {
      this.animationActions[clipsNames.out].timeScale = -1;
      this.animationActions[clipsNames.out].paused = false;

      event.direction === -1 && this.stop();
      event.direction === -1 && CB();
    });
  }

  stop() {
    this.animationActions[clipsNames.out].stop();
    this.animationActions[clipsNames.out].timeScale = 1;
    this.isPlaying = false;

    this.scene.sceneRenderers.toggleMainViewRendering(false);
  }

  update(dt) {
    this.sensorAnimMixer && this.sensorAnimMixer.update(0.001 * dt);

    this.isPlaying && this.scene.sceneRenderers.toggleMainViewRendering(true);
  }
}

export default ReplaceSensor;
