import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Stack, Typography, styled } from "@mui/material";

import {
  MoveIcon,
  RotateIcon,
  TranslateYIcon,
  PlayIcon,
} from "../../../../common/assets/svgIcons/icon";

import SceneAPIContext from "../../../../common/context/sceneAPIContext/SceneAPIContext";
import { useResponsive } from "../../../../common/hooks/useResponsive";

import {
  TransformControlsMode,
  TransformControlsObjectType,
} from "../../../../common/webgl/controls/TransformControlData";
import { RendererViewModeTypes } from "../../../../common/webgl/renderer/SceneRendererData";
import TransformControlButton from "./TransformControlButton";

const TransformControlWrapper = (): JSX.Element => {
  enum ToolsType {
    Camera = 1,
    Tower,
  }

  const { threeScene, setActiveRendererViewMode } = useContext(SceneAPIContext);
  const location = useLocation();

  const { isExtraLargeDevice } = useResponsive();

  const [activeTools, setActiveTools] = useState(ToolsType.Tower);

  const toggleActiveTools = (): void => {
    setActiveTools(prev =>
      prev === ToolsType.Tower ? ToolsType["Camera"] : ToolsType["Tower"]
    );
  };

  const getTowerControledObjectType = (): string => {
    return location.pathname.includes("factory")
      ? TransformControlsObjectType.towerOnFactory
      : TransformControlsObjectType.towerOnFacility;
  };

  const onTowerRotateControlActivate = (): void => {
    const controlObjectType = getTowerControledObjectType();

    threeScene.sceneControls.enableTransformControls(
      controlObjectType,
      TransformControlsMode.rotateObject
    );
  };

  const onTowerTranslateControlActivate = (): void => {
    const controlObjectType = getTowerControledObjectType();

    threeScene.sceneControls.enableTransformControls(
      controlObjectType,
      TransformControlsMode.translateObject
    );
  };

  const onCameraTranslateControlActivate = (): void => {
    threeScene.sceneControls.enableTransformControls(
      TransformControlsObjectType.camera,
      TransformControlsMode.translateObject
    );
  };

  const onCameraViewActivate = (): void => {
    setActiveRendererViewMode(RendererViewModeTypes.viewGas);
  };

  useEffect(() => {
    return () => {
      threeScene.sceneControls.disableTransformControls();
    };
  }, []);

  return isExtraLargeDevice ? (
    <Container>
      <ControlWrapper>
        <ControlTypeLabel variant="body-400-18">Tower:</ControlTypeLabel>
        <TransformControlButton handleOnClick={onTowerRotateControlActivate}>
          <RotateIcon />
        </TransformControlButton>
        <TransformControlButton handleOnClick={onTowerTranslateControlActivate}>
          <MoveIcon />
        </TransformControlButton>
      </ControlWrapper>
      <ControlWrapper>
        <ControlTypeLabel variant="body-400-18">Camera:</ControlTypeLabel>
        <TransformControlButton
          handleOnClick={onCameraTranslateControlActivate}
        >
          <TranslateYIcon />
        </TransformControlButton>
        <TransformControlButton handleOnClick={onCameraViewActivate}>
          <PlayIcon />
        </TransformControlButton>
      </ControlWrapper>
    </Container>
  ) : (
    <Container>
      <Stack direction={"row"} gap={0}>
        <ControlTypeLabelToggle
          onClick={toggleActiveTools}
          active={activeTools === ToolsType.Tower ? 1 : 0}
          variant="body-500-18"
        >
          Tower
        </ControlTypeLabelToggle>
        <ControlTypeLabelToggle
          onClick={toggleActiveTools}
          active={activeTools === ToolsType.Camera ? 1 : 0}
          variant="body-500-18"
        >
          Camera
        </ControlTypeLabelToggle>
      </Stack>
      {activeTools === ToolsType.Tower ? (
        <ControlWrapper>
          <TransformControlButton handleOnClick={onTowerRotateControlActivate}>
            <RotateIcon />
          </TransformControlButton>
          <TransformControlButton
            handleOnClick={onTowerTranslateControlActivate}
          >
            <MoveIcon />
          </TransformControlButton>
        </ControlWrapper>
      ) : (
        <ControlWrapper>
          <TransformControlButton
            handleOnClick={onCameraTranslateControlActivate}
          >
            <TranslateYIcon />
          </TransformControlButton>
          <TransformControlButton handleOnClick={onCameraViewActivate}>
            <PlayIcon />
          </TransformControlButton>
        </ControlWrapper>
      )}
    </Container>
  );
};

export default TransformControlWrapper;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px 8px 8px 32px",
  gap: "32px",
  borderRadius: "100px",
  backgroundColor: theme.palette.primary["grey-300"],
  [theme.breakpoints.down("laptop")]: {
    padding: theme.spacing(2),
    gap: theme.spacing(4),
    justifyContent: "space-between",
    width: "100%",
    margin: theme.spacing(4),
  },
  [theme.breakpoints.down("tablet")]: {
    padding: theme.spacing(1.5),
  },
}));

const ControlWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const ControlTypeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary["text-secondary"],
  lineHeight: "normal",
}));

const ControlTypeLabelToggle = styled(Typography)<{ active: number }>(
  ({ theme, active }) => ({
    color: theme.palette.primary.main,
    pointerEvents: "auto",
    padding: theme.spacing(4),
    borderRadius: "100px",
    backgroundColor: active
      ? theme.palette["button-secondary"].main
      : "transparent",
  })
);
