import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import AccordionItem from "../../common/components/styledComponents/AccordionItem";
import { HotspotData } from "./components/HotspotsData";

import { offsetPoints } from "../../common/assets/constants/offsetPoints";
import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { getHotspotPoint } from "../../common/utils/getHotspotPoint";
import { useResponsive } from "../../common/hooks/useResponsive";

type HotspotItem = {
  x: number;
  y: number;
};

const WindPageDetails = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);
  const { isExtraLargeDevice } = useResponsive();

  const wrapperRef = useRef<HTMLBaseElement>();

  const [expanded, setExpanded] = useState<number | boolean>(false);
  const [hotspots, setHotspots] = useState<HotspotItem[]>([]);
  const [isComplete, setComplete] = useState(false);

  const handleChangeAccordion =
    (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
      isExpanded ? changeExpandedComponent(panel) : setExpanded(0);
    };

  const changeExpandedComponent = (panel: number): void => {
    setExpanded(panel);
  };

  const getHotspotPosition = (): void => {
    if (!wrapperRef.current || !wrapperRef) {
      return;
    }

    setHotspots([]);
    const points = threeScene.sceneObjects.getObjectByName(
      Scene3DObjectsNames.PointsMountingPointers
    ).children[0];

    points.children.forEach((el: any) => {
      const windowPosition =
        threeScene.sceneObjects.getObjectWindowPosition(el);
      const position = getHotspotPoint(
        windowPosition,
        wrapperRef.current?.clientHeight
      );
      setHotspots(prev => [position, ...prev]);
    });
  };

  const onAnimationComplete = (): void => {
    setComplete(true);

    getHotspotPosition();
  };

  useEffect((): void => {
    if (threeScene) {
      setHotspots([]);
      setComplete(false);

      threeScene.loadSceneStage(
        "wind2",
        isExtraLargeDevice ? "desktop" : "mobile",
        1000,
        onAnimationComplete
      );
    }
  }, [isExtraLargeDevice]);

  useEffect((): void => {
    getHotspotPosition();
  }, [wrapperRef.current]);

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      setHotspots([]);
      setComplete(false);

      onAnimationComplete();
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      {isComplete &&
        hotspots.map((el, i) => (
          <Box key={i}>
            <HotspotWrapper
              justifyContent={"center"}
              alignItems={"center"}
              x={
                isExtraLargeDevice
                  ? el.x + offsetPoints[i === 0 ? i : 1]?.x
                  : el.x
              }
              y={
                isExtraLargeDevice
                  ? el.y + offsetPoints[i === 0 ? i : 1]?.y
                  : el.y
              }
            >
              <HotspotInfo
                justifyContent={"center"}
                alignItems={"center"}
                state={expanded === (i === 0 ? 1 : 2) ? 1 : 0}
                onClick={(): void => changeExpandedComponent(i === 0 ? 1 : 2)}
              >
                <Typography variant="h6">{i === 0 ? 1 : 2}</Typography>
              </HotspotInfo>
            </HotspotWrapper>
            <HotspotPoint x={el.x} y={el.y} />
            {isExtraLargeDevice && (
              <HotspotSVG>
                <Line
                  x1={
                    el.x +
                    offsetPoints[i === 0 ? i : 1]?.x +
                    (offsetPoints[i === 0 ? i : 1]?.x >= -18 ? 0 : 18)
                  }
                  y1={
                    el.y +
                    offsetPoints[i === 0 ? i : 1]?.y +
                    (offsetPoints[i === 0 ? i : 1]?.y > -18 ? -18 : 9)
                  }
                  x2={el.x + 3}
                  y2={el.y + 3}
                />
              </HotspotSVG>
            )}
          </Box>
        ))}
      <AccordionWrapper>
        {HotspotData.map(component => (
          <AccordionItem
            key={component.id}
            isExpanded={expanded === component.id}
            title={component.title}
            description={component.description}
            onChange={handleChangeAccordion(component.id)}
          />
        ))}
      </AccordionWrapper>
      <Background />
    </Wrapper>
  );
};

export default WindPageDetails;

const Wrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",
}));

const HotspotWrapper = styled(Stack)<{ x: number; y: number }>(
  ({ theme, x, y }) => ({
    position: "absolute",
    top: `${y - 18}px`,
    left: `${x - 18}px`,

    width: "36px",
    height: "36px",
    border: `2px solid ${theme.palette["button-secondary"].main} `,

    borderRadius: "100%",
  })
);

const HotspotInfo = styled(Stack)<{ state: number }>(({ theme, state }) => ({
  width: "28px",
  height: "28px",
  background: state
    ? theme.palette.primary["brand-orange"]
    : theme.palette.primary["grey-hotspot"],

  borderRadius: "100%",
  color: theme.palette.primary["grey-400"],
  cursor: "pointer",
  pointerEvents: "auto",
}));

const HotspotPoint = styled(Box)<{ x: number; y: number }>(
  ({ theme, x, y }) => ({
    position: "absolute",
    background: theme.palette.primary["grey-hotspot"],

    top: y,
    left: x,
    width: "6px",
    height: "6px",
    borderRadius: "100%",

    [theme.breakpoints.down("laptop")]: {
      backgroundColor: "transparent",
    },
  })
);

const HotspotSVG = styled("svg")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

const Line = styled("line")(({ theme }) => ({
  stroke: theme.palette.primary["grey-hotspot"],
  strokeWidth: "2px",
}));

const AccordionWrapper = styled(Stack)(({ theme }) => ({
  pointerEvents: "auto",
  background: theme.palette.primary["grey-100"],
  width: "450px",
  height: "fit-content",
  borderRadius: "16px",
  overflow: "hidden",
  padding: theme.spacing(6),
  color: theme.palette.text.primary,

  "&>div:not(:first-of-type) ": {
    borderTop: `1px solid ${theme.palette.primary["grey-300"]}`,
  },

  [theme.breakpoints.down("laptop")]: {
    width: `calc(100% - ${theme.spacing(8)})`,

    margin: theme.spacing(4),

    padding: theme.spacing(2),
  },
}));

const Background = styled(Box)(() => ({
  width: "100%",
  height: "30%",

  position: "absolute",
  bottom: "0",
  left: 0,
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)  ",
}));
