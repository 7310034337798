import { useContext, useEffect, useRef } from "react";
import { Box } from "@mui/material";

import SceneAPIContext from "../../context/sceneAPIContext/SceneAPIContext";

import ThreeScene from "../../webgl/ThreeScene";
import MainRendererViewport from "./MainRendererViewport";
import View360RendererViewport from "./View360RendererViewport";
import ViewGasRendererViewport from "./ViewGasRendererViewport";

const ThreeViewport = () => {
  const canvasMainRef = useRef(null);
  const canvasView360Ref = useRef([]);
  const canvasViewGasRef = useRef(null);
  const animationFrameIdRef = useRef(null);

  const {
    setPercentageOfLoadedScene,
    threeScene,
    setThreeScene,
    setIsSceneReady,
  } = useContext(SceneAPIContext);

  const resizeViewport = () => {
    threeScene?.onWindowResize();
  };

  useEffect(() => {
    const threeScene = new ThreeScene(
      canvasMainRef.current,
      canvasViewGasRef.current,
      canvasView360Ref.current
    );
    setThreeScene(threeScene);
  }, []);

  useEffect(() => {
    if (!threeScene) {
      return;
    }

    const render = () => {
      animationFrameIdRef.current = requestAnimationFrame(render);
      threeScene.update();
    };

    const proggresCallBack = objectsLoadedInPercentage => {
      setPercentageOfLoadedScene(objectsLoadedInPercentage);
    };

    const loadScene = async () => {
      await threeScene.initSceneComponents(proggresCallBack);

      resizeViewport();
      requestAnimationFrame(render);

      setIsSceneReady(true);
    };

    loadScene();

    window.addEventListener("resize", resizeViewport);

    return () => {
      window.removeEventListener("resize", resizeViewport);
      cancelAnimationFrame(animationFrameIdRef.current);
    };
  }, [threeScene]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <MainRendererViewport ref={canvasMainRef} />
      <View360RendererViewport ref={canvasView360Ref} />
      <ViewGasRendererViewport ref={canvasViewGasRef} />
    </Box>
  );
};

export default ThreeViewport;
