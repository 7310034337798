export const chackIsArraysAreEqual = (a1: Array<any>, a2: Array<any>) => {
  if (a1.length !== a2.length) {
    return false;
  }

  if (a1.length === 0 && a2.length === 0) {
    return true;
  }

  const cloneA1 = [...a1].sort();
  const cloneA2 = [...a2].sort();

  for (let i = 0; i < cloneA1.length; ++i) {
    if (cloneA1[i] !== cloneA2[i]) return false;
  }

  return true;
};
