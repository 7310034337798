import { useContext } from "react";
import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import QuizContext from "../context/quizContext/QuizContext";

interface ProgressItemInterface {
  step: number;
  order: number;
}

const QuizProgressBar = (): JSX.Element => {
  const { step, quizData } = useContext(QuizContext);

  const stepText = `${step + 1}/${quizData.length}`;

  return (
    <Wrapper>
      <StepText variant="body-400-16">{stepText}</StepText>
      <ProgressWrapper>
        {quizData.map(
          (_: any, i: number): JSX.Element => (
            <ProgressItem key={i} step={step} order={i} />
          )
        )}
      </ProgressWrapper>
    </Wrapper>
  );
};

export default QuizProgressBar;

const Wrapper = styled(Box)(
  (): CSSProperties => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: "18px",
  })
);

const ProgressWrapper = styled(Box)(
  (): CSSProperties => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: "9px",
  })
);

const ProgressItem = styled(Box)<ProgressItemInterface>(
  ({ theme, step, order }): CSSProperties => ({
    width: "2px",
    height: "8px",
    backgroundColor: `${
      step >= order
        ? theme.palette.primary["grey-1300"]
        : theme.palette.primary["grey-500"]
    }`,
  })
);

const StepText = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-secondary"],
  })
);
