import { useContext, useEffect } from "react";
import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { Box, Stack, Typography, styled } from "@mui/material";
import PageTransition from "../../common/components/pageTransition/PageTransition";

import { useResponsive } from "../../common/hooks/useResponsive";

import ImageSrc1 from "../../common/assets/images/screen-1.jpeg";
import ImageSrc2 from "../../common/assets/images/screen-2.jpeg";

const HazardAlarmSystem = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);

  const { isExtraLargeDevice } = useResponsive();

  useEffect((): void => {
    if (threeScene) {
      threeScene.loadSceneStage("hide", "desktop");
    }
  }, []);

  return (
    <PageTransition>
      <Wrapper p={6} gap={6}>
        <Typography variant="h3">Purpose built for Autonomous365</Typography>
        <ContentWrapper>
          <ScreenBox>
            <Screen1 src={ImageSrc1} alt="Screen1" />
            <Screen2 src={ImageSrc2} alt="Screen2" />
          </ScreenBox>
          <Text>
            <Stack gap={isExtraLargeDevice ? 4 : 2}>
              <SecondaryText variant={"body-400-18"}>
                Minerva is expertly crafted to be the cornerstone of the
                Autonomous365 suite, bringing unmatched precision and
                versatility to oil and gas operations.
              </SecondaryText>
            </Stack>
          </Text>
        </ContentWrapper>
      </Wrapper>
    </PageTransition>
  );
};

export default HazardAlarmSystem;

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 80px)",

  pointerEvents: "auto",

  "& h3": {
    color: theme.palette.primary.main,
  },
  position: "relative",
  [theme.breakpoints.down("tablet")]: {
    overflow: "auto",
  },
}));

const ContentWrapper = styled(Stack)(({ theme }) => ({
  position: "relative",
  flexDirection: "row",
  gap: theme.spacing(10),
  height: "100%",

  [theme.breakpoints.down("laptop")]: {
    flexDirection: "column",
    gap: theme.spacing(0),

    justifyContent: "flex-end",
  },
}));

const ScreenBox = styled(Box)(({ theme }) => ({
  width: "60%",
  height: "100%",

  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "start",

  [theme.breakpoints.down("laptop")]: {
    width: "100%",
    maxHeight: "40%",
    height: "auto",
  },
}));

const Screen1 = styled("img")(({ theme }) => ({
  position: "absolute",
  top: 0,
  maxWidth: "100%",
  maxHeight: "80%",
  objectFit: "fill",
  borderRadius: "16px",
  [theme.breakpoints.down("laptop")]: {
    maxWidth: "calc(100% - 64px)",
  },
}));

const Screen2 = styled("img")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: "200px",
  maxWidth: "45%",

  objectFit: "fill",
  borderRadius: "16px",
  [theme.breakpoints.down("laptop")]: {
    left: "100px",
    top: "25%",
    maxWidth: "calc(100% - 124px)",
  },
}));

const Text = styled(Stack)(({ theme }) => ({
  flex: 1,
  height: "fit-content",
  borderRadius: "16px",

  background: theme.palette.primary["grey-200"],
  padding: theme.spacing(10),
  gap: theme.spacing(12),

  [theme.breakpoints.down("laptop")]: {
    flex: "unset",
    maxHeight: "40%",

    gap: theme.spacing(7),
    padding: theme.spacing(6),
  },
  [theme.breakpoints.down("tablet")]: {
    maxHeight: "60%",
  },
}));

const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
