import { useNavigate } from "react-router-dom";
import { Box, Button, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import {
  ArrowRightIcon,
  CheckFacilityIcon,
} from "../../../common/assets/svgIcons/icon";

const CheckAtYourFacilityButton = (): JSX.Element => {
  const navigate = useNavigate();

  const handleOnClick = (): void => {
    navigate("/configurator/facility");
  };

  return (
    <Wrapper>
      <StyledButton
        onClick={handleOnClick}
        variant="text"
        color="button-ghost"
        startIcon={<CheckFacilityIcon />}
        endIcon={<ArrowRightIcon />}
      >
        Check at your facility
        <Separator />
      </StyledButton>
    </Wrapper>
  );
};

export default CheckAtYourFacilityButton;

const Wrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    display: "flex",
    padding: "8px 0px",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "auto",

    borderTop: `1px solid ${theme.palette.primary["base-border"]}`,
    borderBottom: `1px solid ${theme.palette.primary["base-border"]}`,
  })
);

const StyledButton = styled(Button)(
  (): CSSProperties => ({
    width: "100%",
    height: "unset",
    padding: "12px 0px",
    gap: "8px",

    "& span": {
      margin: "0px",
    },

    "& svg": {
      transform: "translateY(-2px)",
      margin: "0px",
    },
  })
);

const Separator = styled(Box)(
  (): CSSProperties => ({
    flex: 1,
  })
);
