import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import AnimatedTextButton from "../../../common/components/styledComponents/AnimatedTextButton";

import DetailsFormContext, {
  DetailsFormContextType,
  DetailsFormDataInterface,
} from "../context/detailsFormContext/DetailsFormContext";

import BuiltMinervaContext, {
  BuiltMinervaContextInterface,
} from "../../../common/context/builtMinervaContext/BuiltMinervaContext";

import useHubspotForm from "../hooks/useHubspot";
import { useCountries } from "../hooks/useCountries";

const SecureSpotButton = (): JSX.Element => {
  const navigate = useNavigate();
  const { handleSubmit } = useHubspotForm({
    portalId: process.env.REACT_APP_FORM_PORTAL_ID,
    formId: process.env.REACT_APP_FORM_ID,
  });
  const { countries } = useCountries();

  const { detailsData, toggleShowInputError } = useContext(
    DetailsFormContext
  ) as DetailsFormContextType;
  const { builtMinervas } = useContext(
    BuiltMinervaContext
  ) as BuiltMinervaContextInterface;

  const checkIsAnyFormError = (): boolean => {
    return Object.values(detailsData).some(
      (inputData): boolean => inputData.error
    );
  };

  const getFormDetailsDataToSend = (): DetailsFormDataInterface => {
    const countryText = detailsData.country.error
      ? detailsData.country.value
      : countries.getLabel(detailsData.country.value);

    const details = Object.values(detailsData).reduce(
      (object: any, inputData: any): any => {
        return { ...object, [inputData.dataKey]: inputData.value };
      },
      {}
    );

    details.country = countryText;

    return details;
  };

  const getBuiltminervasDataToSend = (): string => {
    return builtMinervas
      .map((data: any, i: number): string => {
        return `${data.title}-${i + 1} ${data.description}\n${data.optionData
          .map((el: any) => `${el.option} ${el.value}`)
          .join("\n")}\nAmount: ${data.amount}\n`;
      })
      .join("\n\n");
  };

  const sendContactData = async (): Promise<Response> => {
    const details = getFormDetailsDataToSend();
    const minervas = getBuiltminervasDataToSend();

    const formData = new FormData();

    for (const pair of Object.entries(details)) {
      formData.append(pair[0], pair[1]);
    }
    formData.append("hide", minervas);

    return await handleSubmit(formData);
  };

  const handleOnClick = async (): Promise<void> => {
    const isAnyErrors = checkIsAnyFormError();

    if (isAnyErrors) {
      toggleShowInputError(true);
    } else {
      const response = await sendContactData();
      if (response.status === 200) {
        navigate("/preorder/thankyou");
      } else {
        toggleShowInputError(true);
      }
    }
  };

  return (
    <AnimatedTextButton
      variant="contained"
      color="button-primary"
      onClick={handleOnClick}
      endIcon={null}
    >
      Secure a spot
    </AnimatedTextButton>
  );
};

export default SecureSpotButton;
