import { useContext, useEffect } from "react";

import { type CSSProperties } from "@mui/material/styles/createMixins";
import { Box, Stack, Typography, styled } from "@mui/material";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { useResponsive } from "../../common/hooks/useResponsive";

import PageTransition from "../../common/components/pageTransition/PageTransition";
import MenuBlock from "./components/MenuBlock";

import { menuData } from "./menuData";
import InfoIcon from "../../common/assets/svgIcons/InfoIcon";

const HomePage = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);

  const { isLargeDevice } = useResponsive();

  useEffect((): void => {
    if (threeScene) {
      threeScene.loadSceneStage("home", isLargeDevice ? "desktop" : "mobile");
    }
  }, [isLargeDevice]);

  return (
    <PageTransition>
      <PageWrapper>
        {isLargeDevice && (
          <MenuColumnWrapper>
            <MunuBlockWrapper>
              <MenuBlock orientation="end" navData={menuData.safe} />
            </MunuBlockWrapper>
            <MunuBlockWrapper>
              <MenuBlock orientation="end" navData={menuData.reliable} />
            </MunuBlockWrapper>
          </MenuColumnWrapper>
        )}
        {isLargeDevice && (
          <MenuColumnWrapper>
            <MunuBlockWrapper>
              <MenuBlock orientation="start" navData={menuData.rugged} />
            </MunuBlockWrapper>
            <MunuBlockWrapper>
              <MenuBlock orientation="start" navData={menuData.modular} />
            </MunuBlockWrapper>
          </MenuColumnWrapper>
        )}
        <InfoWrapper direction={"row"} gap={2} alignItems={"center"}>
          <InfoIcon />
          <Typography variant="body-400-12">
            Click or scroll for a full product tour
          </Typography>
        </InfoWrapper>
      </PageWrapper>
    </PageTransition>
  );
};

export default HomePage;

const PageWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "100px 24px 0",
    justifyContent: "space-between",
    [theme.breakpoints.down("laptop")]: {
      padding: "80px 24px 0px",
    },
  })
);

const MenuColumnWrapper = styled(Box)(
  (): CSSProperties => ({
    display: "flex",
    flexDirection: "column",
  })
);

const MunuBlockWrapper = styled(Box)(
  (): CSSProperties => ({
    height: "40%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  })
);

const InfoWrapper = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: "100px",
  left: 0,
  right: 0,
  margin: "auto",
  width: "max-content",
  background: theme.palette.primary["grey-1300"],
  color: theme.palette.primary["grey-100"],

  padding: theme.spacing(2, 2.5),
  borderRadius: "8px",

  [theme.breakpoints.down("laptop")]: {
    display: "none",
  },
}));
