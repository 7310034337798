import { LoadingManager, REVISION } from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";

const MANAGER = new LoadingManager();
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;
const DRACO_LOADER = new DRACOLoader(MANAGER).setDecoderPath(
  `${THREE_PATH}/examples/jsm/libs/draco/gltf/`
);
const KTX2_LOADER = new KTX2Loader(MANAGER).setTranscoderPath(
  `${THREE_PATH}/examples/jsm/libs/basis/`
);

const load = (loader, path, logProgress = false) => {
  return new Promise((resolve, reject) => {
    loader.load(
      path,
      data => {
        resolve(data);
      },
      xhr => {
        logProgress && console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      error => {
        reject(error);
      }
    );
  });
};

export const loadGltfAsset = path => {
  const loader = new GLTFLoader(MANAGER)
    .setCrossOrigin("anonymous")
    .setDRACOLoader(DRACO_LOADER)
    .setKTX2Loader(KTX2_LOADER)
    .setMeshoptDecoder(MeshoptDecoder);

  return load(loader, path);
};
