import CustomOrbitControls from "./CustomOrbitControls";
import CustomTransformControls from "./CustomTransformControls";
import LookCameraControls from "./LookCameraControls";

import { Scene3DObjectsNames } from "../sceneObjects/SceneObjectsPathData";
import { TransformControlsSettings } from "./TransformControlData";

class SceneControls {
  constructor(
    scene,
    sceneObjects,
    sceneCameras,
    sceneRenderers,
    mainRenderCanvasElement,
    viewGasRenderCanvasElement,
    view360RenderCanvasElements
  ) {
    this.scene = scene;
    this.sceneObjects = sceneObjects;
    this.sceneCameras = sceneCameras;
    this.sceneRenderers = sceneRenderers;

    this.mainRenderCanvasElement = mainRenderCanvasElement;
    this.viewGasRenderCanvasElement = viewGasRenderCanvasElement;
    this.view360RenderCanvasElements = view360RenderCanvasElements;

    this.init();
  }

  init() {
    this.initOrbitControl();
    this.initTransformControl();
    this.initCameraLookControl();
  }

  dispose() {
    this.orbitControl?.dispose();
    this.transformControl?.dispose();
  }

  initOrbitControl() {
    this.orbitControl = new CustomOrbitControls(
      this.sceneCameras.mainCamera.camera,
      this.mainRenderCanvasElement
    );

    this.orbitControl.addEventListener("start", () =>
      this.sceneRenderers.toggleMainViewRendering(true)
    );

    this.orbitControl.addEventListener("change", () =>
      this.sceneRenderers.toggleMainViewRendering(true)
    );

    this.orbitControl.addEventListener("end", () =>
      this.sceneRenderers.toggleMainViewRendering(false)
    );

    this.orbitControl.update();

    this.mainRenderCanvasElement.addEventListener("wheel", () => {
      this.orbitControl.enabled &&
        this.sceneRenderers.toggleMainViewRendering(true);
    });
  }

  initTransformControl() {
    this.transformControl = new CustomTransformControls(
      this.sceneCameras.mainCamera.camera,
      this.mainRenderCanvasElement
    );

    this.transformControl.addEventListener("mouseDown", () => {
      this.orbitControl.enabled = false;
      this.sceneRenderers.toggleMainViewRendering(true);
    });

    this.transformControl.addEventListener("mouseUp", () => {
      this.orbitControl.enabled = true;
      this.sceneRenderers.toggleMainViewRendering(false);
    });

    this.transformControl.addEventListener("objectChange", () => {
      this.sceneRenderers.toggleMainViewRendering(true);
    });

    this.scene.add(this.transformControl);
  }

  initCameraLookControl() {
    this.lookCameraControls = new LookCameraControls(
      this.viewGasRenderCanvasElement
    );

    this.lookCameraControls.enableZoom = true;
  }

  enableTransformControls(controlObjectType, controlMode) {
    this.sceneRenderers.toggleMainViewRendering(true);

    const { controledObjectName, settings } =
      TransformControlsSettings[controlObjectType];

    const controledObject =
      this.sceneObjects.getSceneObjectInstanceByName(
        controledObjectName
      ).object;

    this.transformControl.enableControls(
      controledObject,
      settings,
      controlMode
    );
  }

  disableTransformControls() {
    this.transformControl.disableControls();
    this.sceneRenderers.toggleMainViewRendering(false);
  }

  enableLookCameraControl() {
    this.sceneRenderers.toggleMainViewRendering(true);
    const horizontalRotationObject =
      this.sceneObjects.getSceneObjectInstanceByName(
        Scene3DObjectsNames.GasCameraHandle
      ).object;
    const verticalRotationObject =
      this.sceneObjects.getSceneObjectInstanceByName(
        Scene3DObjectsNames.GasCamera35mm
      ).object;

    this.lookCameraControls.attach(
      this.sceneCameras.gasCamera.camera,
      horizontalRotationObject,
      verticalRotationObject
    );

    this.lookCameraControls.enabled = true;
  }

  disableLookCameraControl() {
    this.lookCameraControls.enabled = false;
    this.lookCameraControls.detach();
    this.sceneRenderers.toggleMainViewRendering(false);
  }

  update() {
    if (this.orbitControl && this.orbitControl.enabled) {
      this.orbitControl.update();
    }
  }
}

export default SceneControls;
