import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import QuizCustomOptionItem from "./QuizCustomOptionItem";

import {
  AnswerDataInterface,
  OptionDataInterface,
  QuestionDataInterface,
} from "./QuizTypes";

interface QuizCustomOptionItemInterface {
  questionData: QuestionDataInterface;
  answerData: AnswerDataInterface;
  optionsData?: OptionDataInterface[];
  onOptionClick: (
    questionData: QuestionDataInterface,
    answerData: AnswerDataInterface,
    optionsData: OptionDataInterface
  ) => void;
}

const QuizSelectItem = ({
  questionData,
  answerData,
  optionsData,
  onOptionClick,
}: QuizCustomOptionItemInterface): JSX.Element => {
  const handleOnOptionClick = (optionData: OptionDataInterface): void => {
    onOptionClick(questionData, answerData, optionData);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title variant="body-500-18">{answerData.title}</Title>
      </TitleWrapper>

      <OptionListWrapper>
        {optionsData &&
          optionsData.map(
            (data: any): JSX.Element => (
              <QuizCustomOptionItem
                key={`custom-option-${questionData.id}-${answerData.id}-${data.id}`}
                optionData={data}
                onClick={handleOnOptionClick}
              />
            )
          )}
      </OptionListWrapper>
    </Wrapper>
  );
};

export default QuizSelectItem;

const Wrapper = styled(Box)(
  (): CSSProperties => ({
    position: "relative",
    width: "100%",
  })
);

const OptionListWrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",

    display: "flex",
    flexDirection: "column",

    borderRadius: "16px",
    gap: theme.spacing(2),
  })
);

const TitleWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(8, 0, 2),
}));

const Title = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-primary"],
  })
);
