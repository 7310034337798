import { Vector3 } from "three";

export const SceneName = "Scene";

export const Scene3DObjectsNames = {
  Factory: "Factory",
  Tower: "Tower",
  Circle: "Circle",
  MinervaCameraCase: "MinervaCameraCase",
  MinervaCameraTopCover: "MinervaCameraTopCover",
  MinervaCameraGimbalSystem: "MinervaCameraGimbalSystem",
  MinervaCameraSingleCamera: "MinervaCameraSingleCamera",
  MinervaCameraInvidiaGPU: "MinervaCameraInvidiaGPU",
  MinervaCameraHandle: "MinervaCameraHandle",
  GasCameraHandle: "GasCameraHandle",
  GasCamera35mm: "GasCamera35mm",
  GasCamera55mm: "GasCamera55mm",
  GasCamera75mm: "GasCamera75mm",
  MinervaTemperature: "MinervaTemperature",
  WindFlow: "WindFlow",
  PlacingZone: "PlacingZone",
  CameraPlacingZone: "CameraPlacingZone",
  PointsViews: "PointsViews",
  PointsCameraViews: "PointsCameraViews",
  PointsCameraBottomViews: "PointsCameraBottomViews",
  PointsMountingPointers: "PointsMountingPointers",
  DustScroll: "DustScroll",
  RainScroll: "RainScroll",
  RainSkydrops: "RainSkydrops",
  RainWaterdrops: "RainWaterdrops",
  WindScroll: "WindScroll",
  Firegas01: "Firegas01",
  Firegas02: "Firegas02",
  Firegas03: "Firegas03",
  BlackCircle: "BlackCircle",
  BlackCircle1: "BlackCircle1",
  BlackCircle2: "BlackCircle2",

  NvidiaHotspot: "NvidiaHotspot",
};

export const Scene3DObjectsData = [
  // Factory.
  {
    order: 1,
    name: Scene3DObjectsNames.Factory,
    path: require("../../assets/3dmodel/FACTORIE/SM_plant-environment.glb"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: SceneName,
  },
  // Placing zone.
  {
    order: 2,
    name: Scene3DObjectsNames.PlacingZone,
    path: require("../../assets/3dmodel/FACTORIE/SM_plant-placing_zone.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.Factory,
  },
  // Tower.
  {
    order: 3,
    name: Scene3DObjectsNames.Tower,
    path: require("../../assets/3dmodel/FACTORIE/SM_plant-tower.gltf"),
    visible: true,
    position: new Vector3(0, 0, 15),
    parentName: Scene3DObjectsNames.PlacingZone,
  },
  // Minerva camera placing zone on the tower.
  {
    order: 4,
    name: Scene3DObjectsNames.CameraPlacingZone,
    path: require("../../assets/3dmodel/Camera/SM_plant-tower_camera_zone.gltf"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.Tower,
  },
  // Minerva camera handle.
  {
    order: 5,
    name: Scene3DObjectsNames.MinervaCameraHandle,
    path: require("../../assets/3dmodel/Camera/SM_Camera_handle.gltf"),
    visible: true,
    position: new Vector3(0, 27, 1),
    parentName: Scene3DObjectsNames.CameraPlacingZone,
  },

  // Minerva parts.
  {
    order: 6,
    name: Scene3DObjectsNames.MinervaCameraCase,
    path: require("../../assets/3dmodel/Camera/case-base.gltf"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },
  {
    order: 7,
    name: Scene3DObjectsNames.MinervaCameraTopCover,
    path: require("../../assets/3dmodel/Camera/case-cap.gltf"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },
  {
    order: 7,
    name: Scene3DObjectsNames.MinervaCameraGimbalSystem,
    path: require("../../assets/3dmodel/Camera/gimbal.gltf"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },
  {
    order: 7,
    name: Scene3DObjectsNames.MinervaCameraSingleCamera,
    path: require("../../assets/3dmodel/Camera/sensor-anim.glb"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },
  {
    order: 7,
    name: Scene3DObjectsNames.MinervaCameraInvidiaGPU,
    path: require("../../assets/3dmodel/Camera/nvidiagpu.glb"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },
  // Gas camera parts.
  {
    order: 7,
    name: Scene3DObjectsNames.GasCameraHandle,
    path: require("../../assets/3dmodel/Camera/SM_Gas_camera_handle.gltf"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },
  {
    order: 8,
    name: Scene3DObjectsNames.GasCamera35mm,
    path: require("../../assets/3dmodel/Camera/SM_Cam35-out.gltf"),
    visible: true,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.GasCameraHandle,
  },

  // Minerva temperature.
  {
    order: 8,
    name: Scene3DObjectsNames.MinervaTemperature,
    path: require("../../assets/3dmodel/Camera/SM_Temperature.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },
  // Wind flow.
  {
    order: 8,
    name: Scene3DObjectsNames.WindFlow,
    path: require("../../assets/3dmodel/EFFECTS/effect_cooling_scroll.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },
  // Dust.
  {
    order: 8,
    name: Scene3DObjectsNames.DustScroll,
    path: require("../../assets/3dmodel/EFFECTS/effect_dust_scroll.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },
  // Rain scroll.
  {
    order: 8,
    name: Scene3DObjectsNames.RainScroll,
    path: require("../../assets/3dmodel/EFFECTS/effect_rain_scroll.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },
  // Rain skydrops.
  {
    order: 8,
    name: Scene3DObjectsNames.RainSkydrops,
    path: require("../../assets/3dmodel/EFFECTS/effect_rain_skydrops.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },
  // Rain waterdrops.
  {
    order: 8,
    name: Scene3DObjectsNames.RainWaterdrops,
    path: require("../../assets/3dmodel/EFFECTS/effect_rain_waterdrops.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },

  // Rain waterdrops.
  {
    order: 8,
    name: Scene3DObjectsNames.WindScroll,
    path: require("../../assets/3dmodel/EFFECTS/effect_wind_scroll.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },

  // Circle.
  {
    order: 8,
    name: Scene3DObjectsNames.Circle,
    path: require("../../assets/3dmodel/FACTORIE/circle.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },
  // points_views
  {
    order: 7,
    name: Scene3DObjectsNames.PointsViews,
    path: require("../../assets/3dmodel/Camera/points_views.gltf"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },

  //PointsCameraViews
  {
    order: 7,
    name: Scene3DObjectsNames.PointsCameraViews,
    path: require("../../assets/3dmodel/Camera/points_cam-pointers.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },

  {
    order: 7,
    name: Scene3DObjectsNames.PointsCameraBottomViews,
    path: require("../../assets/3dmodel/Camera/points_cam-pointers-bottom.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },
  //PointsMountingPointers
  {
    order: 7,
    name: Scene3DObjectsNames.PointsMountingPointers,
    path: require("../../assets/3dmodel/Camera/points_mounting-pointers.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },

  //Firegas01
  {
    order: 2,
    name: Scene3DObjectsNames.Firegas01,
    path: require("../../assets/3dmodel/EFFECTS/effect_firegas_a01.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.Factory,
  },

  //Firegas02
  {
    order: 2,
    name: Scene3DObjectsNames.Firegas02,
    path: require("../../assets/3dmodel/EFFECTS/effect_firegas_a02.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.Factory,
  },

  //Firegas03
  {
    order: 2,
    name: Scene3DObjectsNames.Firegas03,
    path: require("../../assets/3dmodel/EFFECTS/effect_firegas_a03.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.Factory,
  },

  //BlackCircles
  {
    order: 8,

    name: Scene3DObjectsNames.BlackCircle,
    path: require("../../assets/3dmodel/Camera/blackcircle_a02-half-end.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },

  {
    order: 8,
    name: Scene3DObjectsNames.BlackCircle1,
    path: require("../../assets/3dmodel/Camera/blackcircle_a01-back-end.glb"),
    visible: false,
    position: new Vector3(0, 0.3, 0),
    parentName: Scene3DObjectsNames.MinervaCameraHandle,
  },

  {
    order: 8,
    name: Scene3DObjectsNames.BlackCircle2,
    path: require("../../assets/3dmodel/Camera/blackcircle_a03-cam-end.glb"),
    visible: false,
    position: new Vector3(0, 0, 0),
    parentName: Scene3DObjectsNames.MinervaCameraSingleCamera,
  },
  //Hotspots

  {
    order: 8,
    name: Scene3DObjectsNames.NvidiaHotspot,
    path: require("../../assets/3dmodel/hotspots/hotspot.gltf"),
    visible: true,
    position: new Vector3(0.15, 0, -0.1),
    parentName: Scene3DObjectsNames.MinervaCameraCase,
  },
];
