import { AnimationMixer, LoopRepeat } from "three";
import { Scene3DObjectsNames } from "../SceneObjectsPathData";

const clipsNames = {
  out: "anim_sensor_out",
  cameraToogle: "anim_cam_out",
};

class ReplaceDetails {
  animationActions = {};

  constructor(scene) {
    this.scene = scene;
    this.timeouts = [];
    this.isPlaying = false;
  }

  init() {
    this.cameraToogle = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.GasCamera35mm
    );

    this.cameraToogleAnimMixer = new AnimationMixer(this.cameraToogle.object);
    this.animationActions[clipsNames.cameraToogle] =
      this.cameraToogleAnimMixer.clipAction(this.cameraToogle.animations[0]);
    this.animationActions[clipsNames.cameraToogle].setLoop(LoopRepeat, 2);

    this.animationActions[clipsNames.cameraToogle].timeScale = 0.5;

    this.scene.sceneTransitionAnimation.opacity.init(
      Scene3DObjectsNames.GasCamera35mm
    );
  }

  start(CB = () => {}) {
    this.animationActions[clipsNames.cameraToogle].reset();
    this.animationActions[clipsNames.cameraToogle].play();
    this.isPlaying = true;

    this.cameraToogleAnimMixer.addEventListener("loop", () => {
      const position = {
        x: this.cameraToogle.initialPosition.x + 0.5,
        y: this.cameraToogle.initialPosition.y,
        z: this.cameraToogle.initialPosition.z,
      };

      this.scene.sceneTransitionAnimation.move.start(
        Scene3DObjectsNames.GasCamera35mm,
        position,
        3000
      );

      const CBTimeout = setTimeout(() => {
        this.isPlaying = true;
        CB();
      }, 2000);
      this.timeouts.push(CBTimeout);

      const returnTimeout = setTimeout(() => {
        this.scene.sceneTransitionAnimation.move.stop(3000);

        this.isPlaying = false;
      }, 3000);

      this.timeouts.push(returnTimeout);
    });
  }

  stop() {
    this.animationActions[clipsNames.cameraToogle].stop();
    this.scene.sceneTransitionAnimation.move.stop(100);
    this.scene.sceneTransitionAnimation.opacity.stop();
    this.timeouts.forEach(timeout => clearTimeout(timeout));
    this.isPlaying = false;

    this.timeouts = [];
  }

  update(dt) {
    this.cameraToogleAnimMixer && this.cameraToogleAnimMixer.update(0.001 * dt);
    this.isPlaying && this.scene.sceneRenderers.toggleMainViewRendering(true);
  }
}

export default ReplaceDetails;
