import { useContext, useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";

import PreviewInfoCard from "./components/PreviewInfoCard";
import MinervaPreviewNavigation from "./components/MinervaPreviewNavigation";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import ConfiguratorProgressBarContext from "../../common/context/configuratorProgressBarContext/ConfiguratorProgressBarContext";
import { useResponsive } from "../../common/hooks/useResponsive";

const MinervaPreviewPage = () => {
  const { threeScene } = useContext(SceneAPIContext);
  const { handleSetCurrentStep } = useContext(ConfiguratorProgressBarContext);
  const { isExtraLargeDevice } = useResponsive();

  useEffect(() => {
    if (threeScene) {
      threeScene.loadSceneStage(
        "preview",
        isExtraLargeDevice ? "desktop" : "mobile"
      );
      handleSetCurrentStep("preview");
    }
  }, [isExtraLargeDevice]);

  return (
    <PageWrapper>
      <Typography variant="h3">Confirm everything looks correct</Typography>
      <PageMain>
        <PreviewInfoCard />
      </PageMain>
      <PageFooter>
        <MinervaPreviewNavigation />
      </PageFooter>
    </PageWrapper>
  );
};

export default MinervaPreviewPage;

const PageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",

  justifyContent: "flex-end",
  position: "relative",
  padding: theme.spacing(6, 6, 6),
  gap: theme.spacing(4),
  [theme.breakpoints.down("laptop")]: {
    "& h3": {
      position: "absolute",
      top: theme.spacing(6),
      left: theme.spacing(6),
      right: theme.spacing(6),
    },
  },
}));

const PageMain = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  [theme.breakpoints.down("laptop")]: {
    display: "flex",
    flex: "0",
  },
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}));

const PageFooter = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));
