import { useContext } from "react";
import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import QuizOptionItem from "./QuizOptionItem";
import QuizSelectItem from "./QuizSelectItem";

import QuizContext from "../context/quizContext/QuizContext";

import { AnswerDataInterface } from "./QuizTypes";

const QuizQuestionWrapper = (): JSX.Element => {
  const { step, quizData, handleOnOptionSelected, handleOnAnswerSelect } =
    useContext(QuizContext);

  const questionData = quizData[step];

  return (
    <Wrapper>
      <Title variant="h3">{questionData.question}</Title>
      <OptionsWrapper>
        {questionData.answers.map(
          (answerData: AnswerDataInterface): JSX.Element =>
            answerData.type === "custom" ? (
              <QuizSelectItem
                key={`quiz-answer-${questionData.id}-${answerData.id}`}
                questionData={questionData}
                answerData={answerData}
                optionsData={answerData.optionData}
                onOptionClick={handleOnOptionSelected}
              />
            ) : (
              <QuizOptionItem
                key={`quiz-answer-${questionData.question.id}-${answerData.id}`}
                questionData={questionData}
                answerData={answerData}
                onClick={handleOnAnswerSelect}
              />
            )
        )}
      </OptionsWrapper>
    </Wrapper>
  );
};

export default QuizQuestionWrapper;

const Wrapper = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflowY: "hidden",
    gap: "32px",
  })
);

const Title = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-accent"],
    fontSize: "36px",
    lineHeight: "48px",
    letterSpacing: "-1.08px",
  })
);

const OptionsWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    overflowY: "auto",
  })
);
