import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { RainIcon, WindIcon } from "../../../common/assets/svgIcons/icon";

export const WindRainToggle = ({ onChange, isWind }) => {
  const handleToggler = () => {
    onChange(!isWind);
  };
  return (
    <Wrapper>
      <TogglerBackground translate={isWind ? 1 : 0} />
      <StyledButton
        variant="text"
        color="button-tertiary"
        onClick={handleToggler}
      >
        <WindIcon />
        <Typography variant="body-500-18">Wind</Typography>
      </StyledButton>
      <StyledButton
        variant="text"
        color="button-tertiary"
        onClick={handleToggler}
      >
        <RainIcon />
        <Typography variant="body-500-18">Rain</Typography>
      </StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(() => ({
  flex: 1,
  height: "100%",

  flexDirection: "row",
  position: "relative",
}));

const StyledButton = styled(Button)(({ theme, color }) => ({
  zIndex: 1,
  textAlign: "center",
  flex: 1,

  "&:hover": {
    color: theme.palette[color].main,
  },

  "&:active": {
    color: theme.palette[color].main,
  },
  "& svg": {
    fontSize: "20px",
  },

  [theme.breakpoints.down("tablet")]: {},
}));

const TogglerBackground = styled(Box)(({ theme, translate }) => ({
  position: "absolute",

  top: 0,
  left: 0,

  width: "50%",
  height: "100%",
  borderRadius: "100px",
  backgroundColor: theme.palette["button-secondary"].main,
  transform: `translateX(${translate ? "0%" : "100%"})`,
  transition: "transform 0.3s",
}));
