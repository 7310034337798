export const menuData = {
  safe: {
    title: "Powerful",
    navItems: [
      {
        title: "Stable videos",
        navigationPathName: "/gimbal-stabilisation",
      },
      {
        title: "360 degree views",
        navigationPathName: "/360-camera-view",
      },
      {
        title: "Specialty camera on a tour",
        navigationPathName: "/360-camera-view/details",
      },
    ],
  },
  reliable: {
    title: "Simple",
    navItems: [
      {
        title: "Unified Power:",
        describe: "One power source for easy install",
        navigationPathName: "/all-in-one-communication",
      },
      {
        title: "Onboard Storage:",
        describe: "Quick and secure data access",

        navigationPathName: "/gas-camera",
      },
      {
        title: "Integrated Communication:",
        describe: "Built-in module for easy setup",
        navigationPathName: "/nvidia",
      },
      {
        title: "Embedded Compute:",
        describe: "Integrated Compute purpose built for A365 suite.",
        navigationPathName: "/nvidia",
      },
    ],
  },
  rugged: {
    title: "Rugged",
    navItems: [
      {
        title: "Gimbal stabilisation",
        navigationPathName: "/gimbal-stabilisation",
      },
      {
        title: "Industrial Grade",
        navigationPathName: "/gimbal-stabilisation",
      },

      {
        title: "-25 - 85+ C°",
        navigationPathName: "/temperature",
      },
    ],
  },
  modular: {
    title: "Modular",
    navItems: [
      {
        title: "Pay for only what you need",
        navigationPathName: "/easy-replace",
      },
      {
        title: "Easily scale and maintain",
        navigationPathName: "/easy-replace",
      },
      {
        title: "Low barrier to entry",
        navigationPathName: "/easy-replace",
      },
    ],
  },
};
