import { Box, Link, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import { TooltipIcon } from "../../../common/assets/svgIcons/icon";

const InfoCard = (): JSX.Element => {
  return (
    <Wrapper>
      <IconWrapper>
        <TooltipIcon color="#6290D4" />
      </IconWrapper>
      <Hint variant="body-400-16">
        If you have any questions or need assistance with your pre-order, our
        dedicated{" "}
        <Link href="#" color="inherit">
          customer support team
        </Link>{" "}
        is here to help.
      </Hint>
    </Wrapper>
  );
};

export default InfoCard;

const Wrapper = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    borderRadius: "16px",
    padding: "16px",
    gap: "12px",
    backgroundColor: "rgba(128, 174, 242, 0.20)",
  })
);

const IconWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })
);

const Hint = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-primary"],
  })
);
