import { useContext, useEffect, useState } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { DustRainToggle } from "./components/DustRainToggle";

import PageTransition from "../../common/components/pageTransition/PageTransition";
import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { useResponsive } from "../../common/hooks/useResponsive";

const EnclosedCameraCasingPage = (): JSX.Element => {
  const { threeScene } = useContext(SceneAPIContext);
  const { isExtraLargeDevice, isLargeDevice } = useResponsive();

  const [isDust, setIsDust] = useState<boolean>(true);

  const onChange = (): void => {
    setIsDust(!isDust);
    setEffect(!isDust);
  };

  const onComplete = (): void => {
    setEffect(isDust);
  };

  useEffect((): (() => void) => {
    if (threeScene) {
      threeScene.loadSceneStage(
        "dust",
        isExtraLargeDevice ? "desktop" : isLargeDevice ? "tablet" : "mobile",
        1000,
        onComplete
      );
      threeScene.sceneAnimation.rainEffect.init();
      threeScene.sceneAnimation.dustEffect.init();
    }

    return (): void => {
      if (threeScene) {
        threeScene.sceneAnimation.rainEffect.setIntensity(0);
        threeScene.sceneAnimation.dustEffect.stop(true);
        threeScene.sceneAnimation.rainEffect.stop(true);
      }
    };
  }, [isExtraLargeDevice, isLargeDevice]);

  const setEffect = (isDust: boolean): void => {
    if (isDust) {
      threeScene.sceneAnimation.dustEffect.start();
      threeScene.sceneAnimation.rainEffect.stop();
    } else {
      threeScene.sceneAnimation.dustEffect.stop();
      threeScene.sceneAnimation.rainEffect.start();
    }
  };

  return (
    <PageTransition>
      <PageWrapper>
        <Typography variant="h3">Engineered For Resilience</Typography>
        <ToggleWrapper>
          <DustRainToggle isDust={isDust} onChange={onChange} />
        </ToggleWrapper>
        <InfoBlock>
          <Typography variant="h5"> Rugged All-Weather Design</Typography>
          <Typography variant="body-400-18" component={"p"}>
            Minerva is meticulously designed to withstand the toughest
            environments, ensuring reliability and performance in any condition.
          </Typography>
        </InfoBlock>
      </PageWrapper>
    </PageTransition>
  );
};

export default EnclosedCameraCasingPage;

const PageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 80px)",
  display: "flex",
  pointerEvents: "auto",
  position: "relative",
  padding: theme.spacing(6),
  gap: theme.spacing(4),

  "& h3": {
    color: theme.palette.primary.main,
  },

  [theme.breakpoints.down("laptop")]: {
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    flexDirection: "column",
  },
}));

const InfoBlock = styled(Stack)(({ theme }) => ({
  background: theme.palette.primary["grey-200"],
  padding: theme.spacing(10),
  width: "30%",
  height: "fit-content",
  marginLeft: "auto",

  borderRadius: "16px",
  gap: theme.spacing(4),
  "& p": {
    color: theme.palette.primary["text-secondary"],
  },

  [theme.breakpoints.down("laptop")]: {
    width: "100%",
    gap: theme.spacing(2),
    padding: theme.spacing(6),
  },
}));

const ToggleWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  alignItems: "center",
  position: "absolute",
  bottom: "0",
  left: "0",
  right: 0,
  margin: "auto",
  [theme.breakpoints.down("laptop")]: {
    position: "relative",
    margin: "unset",
    marginTop: "auto",
  },
}));
