import {
  DirectionalLight,
  HemisphereLight,
  AmbientLight,
  Vector3,
} from "three";

class SceneLights {
  constructor(scene) {
    this.scene = scene;

    this.init();
  }

  init() {
    this.initDirectionalLight(
      "directionalLight1",
      0xffffff,
      0.8,
      new Vector3(145, 75, 12)
    );

    this.initDirectionalLight(
      "directionalLight2",
      0xffffff,
      0.8,
      new Vector3(-20, 80, 0)
    );

    this.initHemisphereLight(
      "hemisphereLight1",
      0xffffff,
      0xffffff,
      1,
      new Vector3(0, 50, 0)
    );

    this.initAmbientLight(
      "ambientLight1",
      0xffffff,
      0.4,
      new Vector3(130, 100, 130)
    );
  }

  initDirectionalLight(name, color, intensity, position) {
    this[name] = new DirectionalLight(color, intensity);
    this[name].position.copy(position);
    this.scene.add(this[name]);
  }

  initHemisphereLight(name, color1, color2, intensity, position) {
    this[name] = new HemisphereLight(color1, color2, intensity);
    this[name].position.copy(position);
    this.scene.add(this[name]);
  }

  initAmbientLight(name, color, intensity, position) {
    this[name] = new AmbientLight(color, intensity);
    this[name].position.copy(position);
    this.scene.add(this[name]);
  }
}

export default SceneLights;
