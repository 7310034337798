import { useContext, useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  List,
  ListItem,
  Typography,
  styled,
} from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import BuiltMinervaContext, {
  BuiltMinervaContextInterface,
  MinervaOptionItemInterface,
} from "../../../common/context/builtMinervaContext/BuiltMinervaContext";
import { useResponsive } from "../../../common/hooks/useResponsive";

import { MinusIcon, PlusIcon } from "../../../common/assets/svgIcons/icon";

const PreviewInfoCard = (): JSX.Element => {
  const { builtMinervas } = useContext(
    BuiltMinervaContext
  ) as BuiltMinervaContextInterface;
  const { isExtraLargeDevice } = useResponsive();

  const [isCollapse, setCollapse] = useState(true);

  return (
    <>
      {builtMinervas.length > 0 && (
        <Wrapper>
          <Stack
            width={"100%"}
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Title variant="h4">Minerva</Title>
            {!isExtraLargeDevice && (
              <IconWrapButton onClick={(): any => setCollapse(!isCollapse)}>
                {isCollapse ? <PlusIcon /> : <MinusIcon />}
              </IconWrapButton>
            )}
          </Stack>
          {(!isCollapse || isExtraLargeDevice) && (
            <DetailsWrapper>
              {builtMinervas[builtMinervas.length - 1].optionData.map(
                (data: MinervaOptionItemInterface, i: number): JSX.Element => (
                  <DetailsItem key={`${data.option}-${i}`}>
                    <OptionText variant="body-400-14">{data.option}</OptionText>
                    <ValueText variant="body-500-14">{data.value}</ValueText>
                  </DetailsItem>
                )
              )}
            </DetailsWrapper>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default PreviewInfoCard;

const Wrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "324px",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "16px",
    padding: theme.spacing(8),
    gap: theme.spacing(3),

    [theme.breakpoints.down("laptop")]: {
      width: "100%",

      padding: theme.spacing(6),
      gap: theme.spacing(2),
    },
    backgroundColor: theme.palette.primary["grey-300"],
    backdropFilter: "blur(10px)",
  })
);

const Title = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-primary"],
    [theme.breakpoints.down("laptop")]: {
      fontSize: "18px",
    },
  })
);

const IconWrapButton = styled(IconButton)(({ theme }) => ({
  pointerEvents: "auto",
  "& svg": {
    fontSize: "20px",
    color: theme.palette.primary.main,
  },
}));

const DetailsWrapper = styled(List)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    padding: "0px",
    borderTop: `1px solid ${theme.palette.primary["base-border"]}`,
  })
);

const DetailsItem = styled(ListItem)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "8px 0px",
    gap: "4px",
    borderBottom: `1px solid ${theme.palette.primary["base-border"]}`,
  })
);

const OptionText = styled(Typography)(
  ({ theme }): CSSProperties => ({
    width: "138px",
    color: theme.palette.primary["text-secondary"],
  })
);

const ValueText = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-primary"],
  })
);
