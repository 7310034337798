import {
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

interface TextInputPropsInterface {
  handleOnChange: (value: string) => void;
  value: string;
  name: string;
  placeholder: string;
  type: string;
  isError: boolean;
  errorMessage: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

interface StyledFormControlPropsInterface {
  iserror: string;
}

const TextInput = ({
  value,
  placeholder,
  name,
  type,
  isError,
  errorMessage,
  handleOnChange,
  startIcon,
  endIcon,
}: TextInputPropsInterface): JSX.Element => {
  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value as string;
    handleOnChange(value);
  };

  return (
    <StyledFormControl iserror={isError ? "true" : "false"} fullWidth>
      <StyledTextField
        autoComplete={name}
        onChange={handleTextChange}
        name={name}
        hiddenLabel
        value={value}
        placeholder={placeholder}
        variant="standard"
        type={type}
        InputProps={{
          required: true,
          startAdornment: startIcon && (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
          disableUnderline: true,
        }}
      ></StyledTextField>
      {isError && <ErrorText variant="body-400-12">{errorMessage}</ErrorText>}
    </StyledFormControl>
  );
};

export default TextInput;

const StyledFormControl = styled(FormControl)<StyledFormControlPropsInterface>(
  ({ theme, iserror }): CSSProperties => ({
    position: "relative",
    flex: 1,
    padding: "14px 0px",
    borderBottom: `1px solid ${theme.palette.primary["base-border"]}`,

    ...(iserror === "true" && {
      borderBottom: `1px solid ${theme.palette.primary.error}`,
    }),
  })
);

const StyledTextField = styled(TextField)(
  ({ theme }): CSSProperties => ({
    border: 0,

    "& input": {
      padding: "0px",

      color: theme.palette.primary["text-primary"],
      fontFamily: "Hubot-Sans-Normal-400",
      fontSize: "16px",
      fontStyle: "normal",
      lineHeight: "150%",
      "@media (max-width:640px) or (max-height:500px)": {
        fontSize: "12px",
      },

      "&::placeholder": {
        color: theme.palette.primary["text-secondary"],
        opacity: 1,
      },

      "&:-webkit-autofill": {
        backgroundColor: "unset !important",
        color: `${theme.palette.primary["text-primary"]} !important`,
        fontSize: "16px !important",
        transition: "background-color 0s 600000s, color 0s 600000s",
      },

      "&:-webkit-autofill:focus": {
        backgroundColor: "unset !important",
        color: `${theme.palette.primary["text-primary"]} !important`,
        fontSize: "16px !important",
        transition: "background-color 0s 600000s, color 0s 600000s",
      },
    },
  })
);

const ErrorText = styled(Typography)(
  ({ theme }): CSSProperties => ({
    position: "absolute",
    top: "52px",

    color: theme.palette.primary.error,
  })
);
