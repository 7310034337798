import { useContext, useEffect, useRef, useState } from "react";
import SceneAPIContext from "../../../common/context/sceneAPIContext/SceneAPIContext";
import { MathUtils } from "three";
import { IconButton, Stack, styled } from "@mui/material";
import { ColdIcon, HotIcon } from "../../../common/assets/svgIcons/icon";
import StyledSlider from "../../../common/components/StyledSlider";

const easeOutCubic = x => Math.pow(x, 2);
const easeOutCubicReverse = x => Math.pow(x, 1 / 3);

const useAnimationFrame = callback => {
  const requestRef = useRef(0);
  const previousTimeRef = useRef(0);

  const animate = timeStamp => {
    if (previousTimeRef.current) {
      const dt = timeStamp - previousTimeRef.current;

      callback(dt);
    }

    previousTimeRef.current = timeStamp;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [callback]);
};

export const TemperatureSlider = () => {
  const TIME_TO_FULLY_COOL = 5000;

  const { threeScene } = useContext(SceneAPIContext);
  const [value, setValue] = useState(0);
  const [animationIsOn, setAnimationIsOn] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const onChange = event => {
    setAnimationIsOn(false);
    setValue(+event.target.value);
    threeScene.sceneAnimation.temperatureEffect.setTemperatureValue(
      +event.target.value
    );
    threeScene.sceneAnimation.temperatureEffect.stopWindFlow();
  };

  useAnimationFrame(dt => {
    if (animationIsOn) {
      const sign = value > 0 ? 1 : -1;

      let newTimeLeft = timeLeft - dt;
      newTimeLeft = MathUtils.clamp(newTimeLeft, 0, TIME_TO_FULLY_COOL);
      setTimeLeft(newTimeLeft);
      const timeLeftValue = 1 - newTimeLeft / TIME_TO_FULLY_COOL;
      let newValue = easeOutCubic(1 - timeLeftValue);
      newValue = MathUtils.clamp(newValue, 0, 1);
      newValue *= sign;

      setValue(newValue);
      threeScene.sceneAnimation.temperatureEffect.setTemperatureValue(newValue);
      if (newValue === 0) {
        setAnimationIsOn(false);
        threeScene.sceneAnimation.temperatureEffect.stopWindFlow();
      }
    }
  });

  const onRelease = () => {
    if (animationIsOn) {
      return;
    }

    setAnimationIsOn(true);
    const valueToTime =
      TIME_TO_FULLY_COOL * easeOutCubicReverse(Math.abs(value));
    setTimeLeft(valueToTime);
    if (value > 0) {
      threeScene.sceneAnimation.temperatureEffect.startWindFlow();
    }
  };

  const setCold = () => {
    setValue(-1.0001);
  };

  const setHot = () => {
    setValue(1.0001);
  };

  useEffect(() => {
    if (value === 1.0001 || value === -1.0001) {
      onRelease();
    }
  }, [value]);

  return (
    <Wrapper>
      <SliderWrapper>
        <ColdButton disabled={animationIsOn} onClick={setCold}>
          <ColdIcon />
        </ColdButton>
        <StyledSlider
          min={-1.0001}
          max={1.0001}
          step={0.0001}
          value={value}
          onChange={onChange}
          onRelease={onRelease}
        />
        <HotButton disabled={animationIsOn} onClick={setHot}>
          <HotIcon />
        </HotButton>
      </SliderWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  justifyContent: "center",

  alignItems: "flex-end",
  [theme.breakpoints.down("tablet")]: {
    alignItems: "center",
  },
}));

const SliderWrapper = styled(Stack)(({ theme }) => ({
  width: "390px",
  height: "64px",
  position: "absolute",
  right: "-131px",
  background: theme.palette.primary["grey-200"],
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: theme.spacing(2),

  transform: "rotate(-90deg)",
  borderRadius: "100px",
  padding: theme.spacing(2),
  [theme.breakpoints.down("tablet")]: {
    transform: "none",
    position: "unset",

    width: "100%",
    height: "40px",

    marginTop: "auto",
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  position: "relative",
  opacity: "1",

  "&::before": {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0.1,
    content: '""',
    zIndex: -1,
    borderRadius: "100%",
  },
  "&>svg": {
    fontSize: "20px",
  },
  "&.Mui-disabled": {
    opacity: 0.5,
  },
}));

const HotButton = styled(StyledIconButton)(({ theme }) => ({
  "&::before": {
    background: theme.palette.primary.hot,
  },

  "&>svg": {
    color: theme.palette.primary.hot,
  },
}));

const ColdButton = styled(StyledIconButton)(({ theme }) => ({
  "&::before": {
    background: theme.palette.primary.cold,
  },

  "&>svg": {
    color: theme.palette.primary.cold,
  },
}));
