import { useEffect, useState } from "react";
import freeEmailDomains from "free-email-domains";
import * as EmailValidator from "email-validator";

import DetailsFormContext, {
  DetailsFormDataInterface,
  DetailsFormSelectInputInterface,
  DetailsFormTextInputInterface,
} from "./DetailsFormContext";

import { DefaultsDetailsData } from "./DefaultFormData";

const DetailsFormDataLocalStorageKey = "DetailsFormData";

interface DetailsFormContextProviderType {
  children: React.ReactNode;
}

const DetailsFormContextProvider = ({
  children,
}: DetailsFormContextProviderType): JSX.Element => {
  const [detailsData, setDetailsData] = useState(DefaultsDetailsData);
  const [isShowInputError, setIsShowInputError] = useState(false);

  const handleOnDetailChange = (value: string, dataKey: string): void => {
    const newDetailsData = { ...detailsData };

    const inputData = newDetailsData[dataKey as keyof DetailsFormDataInterface];

    inputData.value = value;
    inputData.error = value === "";

    if (inputData.dataKey === "email") {
      const newData = emailValidation(inputData, value);
      inputData.error = newData.error;
      inputData.errorMessage = newData.errorMessage;
    }

    setDetailsData(newDetailsData);
    setDetailsToStorage(newDetailsData);
  };

  const emailValidation = (
    inputData: DetailsFormTextInputInterface | DetailsFormSelectInputInterface,
    value: string
  ): DetailsFormTextInputInterface | DetailsFormSelectInputInterface => {
    inputData.errorMessage = "Email address is required";
    if (!EmailValidator.validate(value)) {
      inputData.error = true;
      inputData.errorMessage = "Email address must be formatted correctly.";
    } else if (freeEmailDomains.includes(inputData.value.split("@")[1])) {
      inputData.error = true;
      inputData.errorMessage = `Please enter your business email address. This form does not accept addresses from ${
        inputData.value.split("@")[1]
      }`;
    }
    return inputData;
  };

  const toggleShowInputError = (value: boolean): void => {
    setIsShowInputError(value);
  };

  // Get items from local storage.
  const getDetailsFromStorage = (): void => {
    const storageData = localStorage.getItem(DetailsFormDataLocalStorageKey);

    if (storageData) {
      const details = JSON.parse(storageData);
      setDetailsData(details);
    }
  };
  // Set items to local storage.
  const setDetailsToStorage = (details: DetailsFormDataInterface): void => {
    localStorage.setItem(
      DetailsFormDataLocalStorageKey,
      JSON.stringify(details)
    );
  };

  useEffect((): void => {
    getDetailsFromStorage();
  }, []);

  return (
    <DetailsFormContext.Provider
      value={{
        detailsData,
        handleOnDetailChange,
        isShowInputError,
        toggleShowInputError,
      }}
    >
      {children}
    </DetailsFormContext.Provider>
  );
};

export default DetailsFormContextProvider;
