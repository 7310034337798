import MuiThemeProvider from "./common/theme/MuiThemeProvider";

import "./App.css";
import Layout from "./common/components/layout/Layout";

const App = (): JSX.Element => {
  return (
    <MuiThemeProvider>
      <Layout />
    </MuiThemeProvider>
  );
};

export default App;
