import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

interface NavigationContext {
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  prevIndex: number;
}

const NavigationContext = createContext<NavigationContext | null>(null);

interface NavigationContextProviderProps {
  children: ReactNode;
}

export const NavigationContextProvider = ({
  children,
}: NavigationContextProviderProps): any => {
  const [activeIndex, setActiveIndex] = useState(0);
  const prevIndex = useRef(activeIndex);

  useEffect(() => {
    prevIndex.current = activeIndex;
  }, [activeIndex]);

  const contextValue = {
    activeIndex,
    setActiveIndex,
    prevIndex: prevIndex.current,
  };

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = (): NavigationContext => {
  const context = useContext(NavigationContext);

  if (context == null) {
    throw new Error("useMenu cannot be used without its provider");
  }

  return context;
};
