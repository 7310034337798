import { MouseEventHandler } from "react";
import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import EnterLocationInput from "./EnterLocationInput";
import CheckAtYourFacilityButton from "./CheckAtYourFacilityButton";
import ChooseLocationNavigation from "./ChooseLocationNavigation";
import MapViewButton from "./MapViewButton";

import { useResponsive } from "../../../common/hooks/useResponsive";

type Props = {
  openMap: MouseEventHandler<HTMLDivElement> | undefined;
};

const ChooseLocationAside = ({ openMap }: Props): JSX.Element => {
  const { isExtraLargeDevice } = useResponsive();

  return (
    <Wrapper>
      <Header>
        <Title variant="h4">Choose a location</Title>
        <Description variant="body-500-18">
          Choose your own facility location to see digitally how Minerva can
          integrate seamlessly into your environment.
        </Description>
        <EnterLocationInput />
        {!isExtraLargeDevice && <MapViewButton onClick={openMap} />}
      </Header>
      <Footer>
        <CheckAtYourFacilityButton />
        {isExtraLargeDevice && <ChooseLocationNavigation />}
      </Footer>
    </Wrapper>
  );
};

export default ChooseLocationAside;

const Wrapper = styled(Box)(
  ({ theme }): CSSProperties => ({
    width: "100%",
    display: "flex",
    flex: "1",
    padding: "40px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "40px",
    overflow: "auto",

    borderRadius: "16px",
    backgroundColor: theme.palette.primary["grey-300"],
    backdropFilter: "blur(10px)",
  })
);

const Header = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
  })
);

const Footer = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "40px",
  })
);

const Title = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["brand-orange"],
  })
);

const Description = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-secondary"],
  })
);
