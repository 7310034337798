import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";

import PageTransition from "../../common/components/pageTransition/PageTransition";
import { useResponsive } from "../../common/hooks/useResponsive";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";
import { getHotspotPoint } from "../../common/utils/getHotspotPoint";

type Hotspot = {
  x: number;
  y: number;
};

const NvidiaPage = (): JSX.Element => {
  const { threeScene, sceneViewport } = useContext(SceneAPIContext);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [hotspotPoint, setHotspotPoint] = useState<Hotspot>({ x: 0, y: 0 });

  const navigate = useNavigate();
  const { isExtraLargeDevice } = useResponsive();

  const openNextPage = (): void => {
    navigate("/nvidia/details");
  };

  const getHotspotPosition = (): void => {
    const point = threeScene.sceneObjects.getObjectByName(
      Scene3DObjectsNames.NvidiaHotspot
    );

    const windowPosition =
      threeScene.sceneObjects.getObjectWindowPosition(point);

    const position = getHotspotPoint(
      windowPosition,
      sceneViewport.current.clientHeight
    );

    setHotspotPoint(position);
  };

  const onAnimationComplete = (): void => {
    setLoaded(true);
    getHotspotPosition();
  };

  useEffect((): void => {
    setLoaded(false);
    if (threeScene) {
      threeScene.loadSceneStage(
        "nvidia",
        isExtraLargeDevice ? "desktop" : "mobile",
        1000,
        onAnimationComplete
      );
    }
  }, [isExtraLargeDevice]);

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      setLoaded(false);

      onAnimationComplete();
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageTransition>
      <PageWrapper p={6} gap={6}>
        <Typography variant="h3">Nvidia powered</Typography>
        {loaded && (
          <HotspotPoint
            x={hotspotPoint.x}
            y={hotspotPoint.y}
            onClick={openNextPage}
          />
        )}
      </PageWrapper>
    </PageTransition>
  );
};

const PageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 80px)",
  display: "flex",
  pointerEvents: "auto",
  justifyContent: "center",

  "& h3": {
    position: "absolute",
    left: 0,
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down("tablet")]: {
      paddingLeft: theme.spacing(4),
    },
  },
  position: "relative",

  padding: theme.spacing(6),

  [theme.breakpoints.down("tablet")]: {
    padding: theme.spacing(4),
  },
}));

const HotspotPoint = styled(Box)<{ x: number; y: number }>(
  ({ theme, x, y }) => ({
    position: "absolute",
    background: theme.palette.primary["brand-orange"],
    cursor: "pointer",

    top: y,
    left: x,
    width: "16px",
    height: "16px",
    borderRadius: "100%",
  })
);

export default NvidiaPage;
