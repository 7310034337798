import { useRef, useState } from "react";
import { useLoadScript, Libraries } from "@react-google-maps/api";

import LocationMapContext, {
  MapCenterCoordinateInterface,
} from "./LocationMapContext";

const DefaultCenter = { lat: 38.60315847979884, lng: -104.3136763850405 };

const libraries: Libraries = ["places"];

interface LocationMapContextProviderInterface {
  children: React.ReactNode;
}

const LocationMapContextProvider = ({
  children,
}: LocationMapContextProviderInterface): JSX.Element => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapCenterCoordinates, setMapCenterCoordinates] =
    useState<MapCenterCoordinateInterface>(DefaultCenter);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
    libraries: libraries,
  });

  const onMapLoad = (map: google.maps.Map): void => {
    mapRef.current = map;
    mapRef.current.setCenter(mapCenterCoordinates);
  };

  const onMapUnmount = (): void => {
    mapRef.current = null;
  };

  const onMapCenterChanged = (): void => {
    if (mapRef.current !== undefined && mapRef.current !== null) {
      const newCenter = mapRef.current.getCenter();

      if (newCenter !== undefined) {
        const newCoords = {
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        };

        setMapCenterCoordinates(newCoords);
      }
    }
  };

  const onSearchMapLocation = (query: string): void => {
    const request = {
      query,
      fields: ["name", "geometry"],
    };

    if (
      mapRef.current !== undefined &&
      mapRef.current !== null &&
      query !== ""
    ) {
      const map = mapRef.current;
      const service = new google.maps.places.PlacesService(map);

      service.textSearch(
        request,
        (
          results: google.maps.places.PlaceResult[] | null,
          status: google.maps.places.PlacesServiceStatus
        ): void => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            map.setCenter(results[0].geometry!.location!);
          }
        }
      );
    }
  };

  return (
    <LocationMapContext.Provider
      value={{
        isMapLoaded: isLoaded,
        mapCenterCoordinates,
        onMapLoad,
        onMapCenterChanged,
        onMapUnmount,
        onSearchMapLocation,
      }}
    >
      {children}
    </LocationMapContext.Provider>
  );
};

export default LocationMapContextProvider;
