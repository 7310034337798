import { ThemeProvider, CssBaseline } from "@mui/material";

import { theme } from "./Theme";

interface MuiThemeProviderProps {
  children: React.ReactNode;
}

const MuiThemeProvider = ({ children }: MuiThemeProviderProps): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
