import { Easing, Tween } from "@tweenjs/tween.js";
import { AnimationMixer, BoxHelper } from "three";
import { Scene3DObjectsNames } from "../SceneObjectsPathData";

const clipsNames = {
  firegas1: "anim_effect_firegas1",
  firegas2: "anim_effect_firegas2",
  firegas3: "anim_effect_firegas3",
};

class GasLeak {
  animationActions = {};
  isUpdated = false;

  hideGasAnimation = new Tween(null)
    .to({}, 300)
    .easing(Easing.Quadratic.In)
    .onStart(() => {
      this.gasShown = false;
    })
    .onUpdate((_, alpha) => {
      this.firegas1.setObjectOpacity(1 - alpha);
      this.firegas2.setObjectOpacity(1 - alpha);
      this.firegas3.setObjectOpacity(1 - alpha);
    })
    .onComplete(() => {
      this.gasAnimation.isPlaying() && this.gasAnimation.stop();
    });

  gasAnimation = new Tween(null)
    .to({}, 10000)
    .delay(1000)
    .easing(Easing.Linear.None)
    .repeat(Infinity)
    .onUpdate((_, alpha) => {
      if (alpha <= 0.33) {
        this.firegas1.setObjectOpacity(1);
        this.firegas2.setObjectOpacity(0);
        this.box1.visible = true;
        this.box2.visible = false;

        if (!this.step1) {
          if (this.step.length > 1) {
            this.step[this.step.length - 2].state = false;
          }

          this.step = [
            ...this.step,
            {
              state: true,
            },
          ];
        }

        this.step1 = true;

        this.CB(this.step);
      } else if (alpha > 0.33 && alpha < 0.66) {
        this.firegas2.setObjectOpacity(1);
        this.firegas3.setObjectOpacity(0);
        this.box2.visible = true;
        this.box3.visible = false;

        if (!this.step2) {
          if (this.step.length > 1) {
            this.step[this.step.length - 2].state = false;
          }

          this.step = [
            ...this.step,
            {
              state: true,
            },
          ];
        }

        this.step2 = true;
        this.CB(this.step);
      } else {
        this.firegas3.setObjectOpacity(1);
        this.firegas1.setObjectOpacity(0);
        this.box3.visible = true;
        this.box1.visible = false;

        if (!this.step3) {
          if (this.step.length > 1) {
            this.step[this.step.length - 2].state = false;
          }

          this.step = [
            ...this.step,
            {
              state: true,
            },
          ];
        }

        this.step3 = true;

        this.CB(this.step);
      }
    })
    .onRepeat(() => {
      this.step1 = false;
      this.step2 = false;
      this.step3 = false;
    });

  constructor(scene) {
    this.scene = scene;
    this.step = [];
    this.CB;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
  }

  addTracking(object) {
    const box = new BoxHelper(object, 0xff0000);
    box.visible = false;

    this.scene.scene.add(box);

    return box;
  }

  init() {
    this.firegas1 = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.Firegas01
    );

    this.box1 = this.addTracking(this.firegas1.object);

    this.firegas2 = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.Firegas02
    );

    this.box2 = this.addTracking(this.firegas2.object);

    this.firegas3 = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames.Firegas03
    );

    this.box3 = this.addTracking(this.firegas3.object);

    this.firegas1Mixer = new AnimationMixer(this.firegas1.object);
    this.firegas2Mixer = new AnimationMixer(this.firegas2.object);
    this.firegas3Mixer = new AnimationMixer(this.firegas3.object);

    // Firegas1.
    for (const clip of this.firegas1.animations) {
      this.animationActions[`${clip.name}1`] =
        this.firegas1Mixer.clipAction(clip);
      this.animationActions[`${clip.name}1`].timeScale = 0.05;
    }

    // Firegas2.
    for (const clip of this.firegas2.animations) {
      this.animationActions[`${clip.name}2`] =
        this.firegas2Mixer.clipAction(clip);
      this.animationActions[`${clip.name}2`].timeScale = 0.05;
    }

    // Firegas3.
    for (const clip of this.firegas3.animations) {
      this.animationActions[`${clip.name}3`] =
        this.firegas3Mixer.clipAction(clip);
      this.animationActions[`${clip.name}3`].timeScale = 0.05;
    }
  }

  start(CB = () => {}) {
    this.CB = CB;

    this.hideGasAnimation.isPlaying() && this.hideGasAnimation.stop();
    !this.gasAnimation.isPlaying() && this.gasAnimation.start();

    this.animationActions[clipsNames.firegas1].reset().play();
    this.animationActions[clipsNames.firegas2].reset().play();
    this.animationActions[clipsNames.firegas3].reset().play();
  }

  stop() {
    this.scene.scene.remove(this.box1);
    this.scene.scene.remove(this.box2);
    this.scene.scene.remove(this.box3);

    this.animationActions[clipsNames.firegas1].stop();
    this.animationActions[clipsNames.firegas2].stop();
    this.animationActions[clipsNames.firegas3].stop();

    this.hideGasAnimation.start();
  }

  update(dt) {
    this.hideGasAnimation &&
      this.hideGasAnimation.isPlaying() &&
      this.hideGasAnimation.update();

    this.gasAnimation &&
      this.gasAnimation.isPlaying() &&
      this.gasAnimation.update();

    // Mixers.
    this.gasAnimation &&
      this.gasAnimation.isPlaying() &&
      this.firegas1Mixer.update(0.015 * dt);

    this.box1 && this.box1.update();
    this.box2 && this.box2.update();
    this.box3 && this.box3.update();

    this.gasAnimation &&
      this.gasAnimation.isPlaying() &&
      this.firegas2Mixer.update(0.008 * dt);

    this.gasAnimation &&
      this.gasAnimation.isPlaying() &&
      this.firegas3Mixer.update(0.007 * dt);
  }
}

export default GasLeak;
