import { Easing, Tween } from "@tweenjs/tween.js";

import { Scene3DObjectsNames } from "../SceneObjectsPathData";
import { MathUtils } from "three/src/math/MathUtils";

class SceneOpacityAnimation {
  constructor(scene) {
    this.scene = scene;
    this.object;
    this.initialOpacity;
    this.destination;
    this.opacity;
  }

  transitionAnimation = new Tween(null)
    .to({}, 1000)
    .onUpdate((_, alpha) => {
      if (this.destination < this.opacity) {
        this.object.setObjectOpacity(this.opacity - alpha * this.destination);
      } else {
        this.object.setObjectOpacity(this.opacity + alpha * this.destination);
      }
    })
    .easing(Easing.Linear.None)
    .onComplete(() => {
      const opacity = MathUtils.clamp(this.object.opacity, 0, 1);
      this.object.setObjectOpacity(opacity);

      this.transitionAnimation.isPlaying() && this.transitionAnimation.stop();
    });

  init(objectName) {
    this.object = this.scene.sceneObjects.getSceneObjectInstanceByName(
      Scene3DObjectsNames[objectName]
    );

    this.initialOpacity = this.object.opacity;
  }

  start(destination) {
    this.destination = destination;
    this.opacity = this.object.opacity;

    if (this.destination === this.opacity) {
      return;
    }

    !this.transitionAnimation.isPlaying() && this.transitionAnimation.start();
  }

  stop() {
    this.object && this.object.setObjectOpacity(this.initialOpacity);
    this.object = false;

    this.transitionAnimation.isPlaying() && this.transitionAnimation.stop();
  }

  update() {
    if (this.transitionAnimation && this.transitionAnimation.isPlaying()) {
      this.transitionAnimation.update();
      this.scene.sceneRenderers.toggleMainViewRendering(true);
    }
  }
}

export default SceneOpacityAnimation;
