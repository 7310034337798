import { useState } from "react";
import { Box, styled } from "@mui/material";

import MinervaCardSummary from "./MinervaCardSummary";
import MinervaCardCloseOpenButton from "./MinervaCardCloseOpenButton";
import MinervaCardDetails from "./MinervaCardDetails";
import MinervaCardAmountControls from "./MinervaCardAmountControls";
import MinervaCardDeleteButton from "./MinervaCardDeleteButton";

import { useResponsive } from "../../hooks/useResponsive";

import MinervaCameraImage from "../../assets/images/minerva-camera.png";

const MinervaCard = ({ cardData, handleOnEdit, handleOnDelete }) => {
  const [open, setOpen] = useState(false);
  const { isExtraLargeDevice } = useResponsive();

  return (
    <CardWrapper>
      <CardHeader>
        {isExtraLargeDevice && <StyledImage src={MinervaCameraImage} />}
        <MinervaCardSummary
          title={`${cardData.title}-${cardData.order}`}
          description={cardData.description}
        />
        <MinervaCardCloseOpenButton open={open} setOpen={setOpen} />
      </CardHeader>
      <MinervaCardDetails open={open} optionData={cardData.optionData} />
      <CardFooter>
        <MinervaCardAmountControls
          builtId={cardData.id}
          amount={cardData.amount}
          handleOnEdit={handleOnEdit}
        />
        <MinervaCardDeleteButton
          builtId={cardData.id}
          handleOnDelete={handleOnDelete}
        />
      </CardFooter>
    </CardWrapper>
  );
};
export default MinervaCard;

const CardWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(8),
  [theme.breakpoints.down("laptop")]: {
    padding: theme.spacing(6),
  },

  borderRadius: "16px",
  backgroundColor: theme.palette.primary["grey-300"],
  backdropFilter: "blur(10px)",
}));

const CardHeader = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  gap: "16px",
  paddingBottom: "16px",
}));

const StyledImage = styled("img")(() => ({
  width: "80px",
  height: "auto",
}));

const CardFooter = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: "24px",

  borderTop: `1px solid ${theme.palette.primary["base-border"]}`,
}));
