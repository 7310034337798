import { useContext, useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import DetilsNavigation from "./DetilsNavigation";
import EnterDetailsForm from "./EnterDetailsForm";

import DetailsFormContext, {
  DetailsFormContextType,
} from "../context/detailsFormContext/DetailsFormContext";

const EnterDetailsBlock = (): JSX.Element => {
  const { toggleShowInputError } = useContext(
    DetailsFormContext
  ) as DetailsFormContextType;

  useEffect((): void => {
    toggleShowInputError(false);
  }, []);

  return (
    <Container>
      <Section>
        <Title variant="h4">Enter your details</Title>
      </Section>
      <EnterDetailsForm />
      <BottomSection>
        <Hint variant="body-400-14">
          By clicking the “Secure a spot” button, you agree to the Minerva Terms
          of Use and Privacy Policy.
        </Hint>
        <DetilsNavigation />
      </BottomSection>
    </Container>
  );
};

export default EnterDetailsBlock;

const Container = styled(Box)(
  (): CSSProperties => ({
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
  })
);

const Title = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-accent"],
  })
);

const Hint = styled(Typography)(
  ({ theme }): CSSProperties => ({
    color: theme.palette.primary["text-secondary"],
  })
);

const Section = styled(Box)(
  (): CSSProperties => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "32px",
  })
);

const BottomSection = styled(Section)(() => ({
  marginTop: "auto",
}));
