import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";

import { LogoIcon } from "../../assets/svgIcons/LogoIcon";

const HeaderLogo = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/");
  };

  return (
    <LogoWrapper onClick={handleOnClick}>
      <LogoIcon />
    </LogoWrapper>
  );
};

export default HeaderLogo;

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  pointerEvents: "auto",
  "& svg": {
    width: "270px",
    [theme.breakpoints.down("tablet")]: {
      width: "180px",
    },
  },
}));
