import { useContext, useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";

import FactoryToolbar from "./components/FactoryToolbar";
import FactoryNavigation from "./components/FactoryNavigation";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import ConfiguratorProgressBarContext from "../../common/context/configuratorProgressBarContext/ConfiguratorProgressBarContext";

import { RendererViewModeTypes } from "../../common/webgl/renderer/SceneRendererData";
import { useResponsive } from "../../common/hooks/useResponsive";

const FactoryPage = () => {
  const { threeScene, setActiveRendererViewMode, activeRendererViewMode } =
    useContext(SceneAPIContext);
  const { handleSetCurrentStep } = useContext(ConfiguratorProgressBarContext);
  const { isExtraLargeDevice } = useResponsive();

  useEffect(() => {
    if (threeScene) {
      threeScene.loadSceneStage(
        "factory",
        isExtraLargeDevice ? "desktop" : "mobile"
      );
      handleSetCurrentStep("factory");
    }

    return () => {
      setActiveRendererViewMode(RendererViewModeTypes.viewMain);

      threeScene.sceneRenderers.toggleGasViewRendering(false);
      threeScene.sceneRenderers.toggle360ViewRendering(false);

      threeScene.sceneControls.disableLookCameraControl();

      threeScene.sceneObjects.resetObjectsToInitialPositionAndRotation();
    };
  }, [isExtraLargeDevice]);

  return (
    <PageWrapper>
      <Title variant="h3">Test digitally at a mock facility</Title>
      <PageFooter>
        <FactoryToolbar />
        {activeRendererViewMode === RendererViewModeTypes.viewMain && (
          <FactoryNavigation />
        )}
      </PageFooter>
    </PageWrapper>
  );
};

export default FactoryPage;

const PageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-end",
  padding: theme.spacing(6, 6, 6),
  position: "relative",
  [theme.breakpoints.down("laptop")]: {
    "& h3": {
      position: "absolute",
      top: theme.spacing(6),
      left: theme.spacing(6),
      right: theme.spacing(6),
    },
  },
}));

const PageFooter = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("laptop")]: {
    alignItems: "flex-end",
  },
}));

const Title = styled(Typography)(() => ({
  position: "absolute",
  top: 0,
}));
