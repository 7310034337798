import { useMediaQuery, useTheme } from "@mui/material";

export const useResponsive = () => {
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up("laptop"));
  const laptop = useMediaQuery(theme.breakpoints.up("tablet"));
  const tablet = useMediaQuery(theme.breakpoints.between("mobile", "laptop"));
  const mobile = useMediaQuery(theme.breakpoints.down("tablet"));

  const isSmallDevice = tablet || mobile;
  const isLargeDevice = laptop;
  const isExtraLargeDevice = desktop;

  return {
    desktop,
    laptop,
    tablet,
    mobile,
    isSmallDevice,
    isLargeDevice,
    isExtraLargeDevice,
  };
};
