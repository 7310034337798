import { Box, styled } from "@mui/material";
import { Suspense, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

import AppRoutes from "../../../routes/AppRoutes";
import LayerContainer from "./LayerContainer";
import AppHeader from "../header/AppHeader";
import AppFooter from "../footer/AppFooter";
import Loader from "../loader/Loader";
import FooterNavigation from "../footer/FooterNavigation";

import SceneAPIContext from "../../context/sceneAPIContext/SceneAPIContext";

import Menu from "../header/Menu";

const UILayer = () => {
  const { isSceneReady, percentageOfLoadedScene, sceneViewport } =
    useContext(SceneAPIContext);
  const { pathname } = useLocation();

  const isConfigurator = pathname.includes("configurator");
  const isPreorder = pathname.includes("preorder");
  const isDetails = pathname.includes("details");

  return (
    <LayerContainer>
      {isSceneReady ? (
        <Wrapper>
          <AppHeader />
          <Menu />
          <Main ref={sceneViewport}>
            <Suspense>
              <AppRoutes />
            </Suspense>
            <Outlet />
          </Main>
          {isConfigurator && <AppFooter />}
          {!isDetails && !isConfigurator && !isPreorder && <FooterNavigation />}
        </Wrapper>
      ) : (
        <Loader percentage={percentageOfLoadedScene} />
      )}
    </LayerContainer>
  );
};

export default UILayer;

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
}));

const Main = styled("main")(() => ({
  flex: 1,
  overflow: "hidden",
}));
