import { Easing, Tween } from "@tweenjs/tween.js";

class SceneTransitionAnimation {
  constructor() {
    this.animation = null;
    this.stageData = null;
    this.animationEasing = Easing.Quadratic.In;
  }

  run(stageData, animationDuration, CB) {
    this.stop();
    this.onRun();

    this.stageData = stageData;

    this.start(animationDuration, CB);
  }

  start(animationDuration, CB) {
    const from = this.getStateFrom();
    const to = this.getStateTo();

    this.animation = new Tween(from, false);

    this.animation
      .to(to, animationDuration)
      .easing(this.animationEasing)
      .onStart(() => this.onStart())
      .onUpdate(() => this.onUpdate(from))
      .onComplete(() => {
        CB();
        this.onComplete();
      })
      .start();
  }

  stop() {
    this.animation && this.animation.isPlaying() && this.animation.stop();
  }

  update() {
    this.animation && this.animation.isPlaying() && this.animation.update();
    this.updateIsFinished
      ? this.sceneRenderers.toggleMainViewRendering(false)
      : this.sceneRenderers.toggleMainViewRendering(true);
  }

  getStateFrom() {}

  getStateTo() {}

  onRun() {}

  onStart() {}

  onUpdate() {}

  onComplete() {}
}

export default SceneTransitionAnimation;
