import Renderer from "./Renderer";

class MainRenderer extends Renderer {
  constructor(
    scene,
    camera,
    canvasElement,
    viewportDimensions,
    clearColor,
    clearOpacity
  ) {
    super(
      scene,
      camera,
      canvasElement,
      viewportDimensions,
      clearColor,
      clearOpacity
    );

    this.enabled = true;
  }
}

export default MainRenderer;
