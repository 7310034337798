import { useNavigate } from "react-router-dom";

import AnimatedTextButton from "../styledComponents/AnimatedTextButton";

import { BuildMinervaIcon } from "../../assets/svgIcons/icon";

const BuildMinervaButton = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/configurator/quiz");
  };

  return (
    <AnimatedTextButton
      variant="outlined"
      color="button-tertiary"
      onClick={handleOnClick}
      endIcon={<BuildMinervaIcon />}
    >
      Build your own Minerva
    </AnimatedTextButton>
  );
};

export default BuildMinervaButton;
