import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { type CSSProperties } from "@mui/material/styles/createMixins";

import IconPrevButton from "../../../common/components/navigationButtons/IconPrevButton";
import PreOrderMinervaButton from "../../../common/components/header/PreOrderMinervaButton";

const ChooseLocationNavigation = (): JSX.Element => {
  const navigate = useNavigate();

  const handlePrevButtonClick = (): void => {
    navigate("/configurator/factory");
  };

  return (
    <NavigationButtonsWrapper>
      <IconPrevButton onClick={handlePrevButtonClick} />
      <PreOrderMinervaButton />
    </NavigationButtonsWrapper>
  );
};

export default ChooseLocationNavigation;

const NavigationButtonsWrapper = styled(Box)(
  (): CSSProperties => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
  })
);
