import { useContext, useEffect } from "react";
import { Box, Stack, Typography, styled } from "@mui/material";

import CameraViewButton from "./CameraViewButton";
import CameraViewCloseButton from "./CameraViewCloseButton";

import SceneAPIContext from "../../../../common/context/sceneAPIContext/SceneAPIContext";

import { RendererViewModeTypes } from "../../../../common/webgl/renderer/SceneRendererData";
import { useBrowser } from "../../../../common/hooks/useBrowser";
import InfoIcon from "../../../../common/assets/svgIcons/InfoIcon";

const CameraViewTogglerWrapper = () => {
  const {
    threeScene,
    activeRendererViewMode,
    setActiveRendererViewMode,
    isGasRenderWasVisible,
    is360CameraRenderWasVisible,
  } = useContext(SceneAPIContext);

  const { isMobileDevice } = useBrowser();

  const togglerBackgroundTranslateX =
    activeRendererViewMode === RendererViewModeTypes.view360 ? 55 : -55;

  const handleView360ButtonOnClick = () => {
    setActiveRendererViewMode(RendererViewModeTypes.view360);
    if (is360CameraRenderWasVisible) {
      threeScene.sceneRenderers.toggleGasViewRendering(false);
      threeScene.sceneRenderers.toggle360ViewRendering(true);
    } else {
      setTimeout(() => {
        threeScene.sceneRenderers.toggleGasViewRendering(false);
        threeScene.sceneRenderers.toggle360ViewRendering(true);
      }, 100);
    }

    threeScene.sceneControls.disableLookCameraControl();
  };

  const handleViewGasButtonOnClick = () => {
    setActiveRendererViewMode(RendererViewModeTypes.viewGas);
    if (isGasRenderWasVisible) {
      threeScene.sceneRenderers.toggle360ViewRendering(false);
      threeScene.sceneRenderers.toggleGasViewRendering(true);
    } else {
      setTimeout(() => {
        threeScene.sceneRenderers.toggle360ViewRendering(false);
        threeScene.sceneRenderers.toggleGasViewRendering(true);
      }, 100);
    }

    threeScene.sceneControls.enableLookCameraControl();
  };

  const handleCloseCameraViewButtonOnClick = () => {
    threeScene.sceneRenderers.toggleGasViewRendering(false);
    threeScene.sceneRenderers.toggle360ViewRendering(false);

    threeScene.sceneControls.disableLookCameraControl();
    setActiveRendererViewMode(RendererViewModeTypes.viewMain);
  };

  useEffect(() => {
    if (!threeScene) {
      return;
    }

    if (isGasRenderWasVisible) {
      threeScene.sceneRenderers.toggleMainViewRendering(false);
      threeScene.sceneRenderers.toggleGasViewRendering(true);
      threeScene.sceneControls.enableLookCameraControl();
    } else {
      setTimeout(() => {
        threeScene.sceneRenderers.toggleMainViewRendering(false);
        threeScene.sceneRenderers.toggleGasViewRendering(true);
        threeScene.sceneControls.enableLookCameraControl();
      }, 50);
    }

    return () => {
      threeScene.sceneRenderers.toggleMainViewRendering(true);
    };
  }, []);

  return (
    <Wrapper isMobile={isMobileDevice ? 1 : 0}>
      {isMobileDevice && (
        <InfoWrapper direction={"row"} gap={2} alignItems={"center"}>
          <InfoIcon />
          <Typography variant="body-400-12">
            For a better experience, please use the desktop version
          </Typography>
        </InfoWrapper>
      )}
      <Container>
        <TogglerWrapper isMobile={isMobileDevice ? 1 : 0}>
          <CameraViewButton
            title="Gas camera"
            handleOnClick={handleViewGasButtonOnClick}
          />
          {!isMobileDevice && (
            <CameraViewButton
              title="360° Camera"
              handleOnClick={handleView360ButtonOnClick}
            />
          )}
          <TogglerBackground
            isMobile={isMobileDevice ? 1 : 0}
            translatex={togglerBackgroundTranslateX}
          />
        </TogglerWrapper>

        {!isMobileDevice && (
          <CameraViewCloseButton
            handleOnClick={handleCloseCameraViewButtonOnClick}
          />
        )}
      </Container>
      {isMobileDevice && (
        <CameraViewCloseButton
          handleOnClick={handleCloseCameraViewButtonOnClick}
        />
      )}
    </Wrapper>
  );
};

export default CameraViewTogglerWrapper;

const Wrapper = styled(Stack)(({ theme, isMobile }) => ({
  position: "absolute",
  bottom: isMobile ? "-50px" : "0px",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  marginTop: "auto",
}));

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px",
  gap: "8px",
  borderRadius: "100px",
  backgroundColor: theme.palette.primary["grey-300"],
  [theme.breakpoints.down("laptop")]: {
    padding: theme.spacing(2),
    gap: 0,
    justifyContent: "space-between",
    minWidth: "300px",
    width: "85%",
  },
  [theme.breakpoints.down("tablet")]: {
    padding: theme.spacing(1.5),
  },
}));

const TogglerWrapper = styled(Box)(({ theme, isMobile }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
  [theme.breakpoints.down("laptop")]: {
    width: "400px",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "250px",
  },

  ...(isMobile && {
    width: "100% !important",
  }),
}));

const TogglerBackground = styled(Box)(({ translatex, theme, isMobile }) => ({
  position: "absolute",
  width: "170px",
  height: "100%",
  borderRadius: "100px",
  backgroundColor: theme.palette["button-secondary"].main,
  transform: `translateX(${translatex}%)`,
  transition: "transform 0.3s",
  [theme.breakpoints.down("laptop")]: {
    width: "170px",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "calc(50% - 12px)",
  },
  ...(isMobile && {
    width: "100% !important",
    transform: "none",
  }),
}));

const InfoWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  background: theme.palette.primary["grey-1300"],
  color: theme.palette.primary["grey-100"],

  padding: theme.spacing(2, 2.5),
  borderRadius: "8px",
}));
