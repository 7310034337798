import { forwardRef, useContext, useEffect } from "react";
import { Box, styled } from "@mui/material";

import SceneAPIContext from "../../context/sceneAPIContext/SceneAPIContext";

import { RendererViewModeTypes } from "../../webgl/renderer/SceneRendererData";

const View360CanvasData = [
  { id: "360-view-1" },
  { id: "360-view-2" },
  { id: "360-view-3" },
  { id: "360-view-4" },
];

const View360RendererViewport = forwardRef((_, ref) => {
  const { activeRendererViewMode, set360CameraRenderVisibility } =
    useContext(SceneAPIContext);

  useEffect(() => {
    activeRendererViewMode === RendererViewModeTypes.view360 &&
      set360CameraRenderVisibility(true);
  }, [activeRendererViewMode]);

  return (
    <Wrapper mode={activeRendererViewMode}>
      <CamerasWrapper>
        {View360CanvasData.map(({ id }, i) => (
          <CanvasWrapper key={id}>
            <MiniCanvas
              ref={el => (ref.current[i] = el)}
              id={`view-360-viewport-canvas-${id}`}
            />
          </CanvasWrapper>
        ))}
      </CamerasWrapper>
    </Wrapper>
  );
});

export default View360RendererViewport;

const Wrapper = styled(Box)(({ mode }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "rgb(255, 255, 255, 0.4)",
  pointerEvents: "auto",
  visibility: "hidden",
  ...(mode === RendererViewModeTypes.view360 && {
    visibility: "visible",
  }),
}));

const CamerasWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  alignContent: "flex-start",
  gap: "8px",
  flex: "1 0 0",
  flexWrap: "wrap 2",
  pointerEvents: "auto",
  "& *": {
    pointerEvents: "auto",
  },

  [theme.breakpoints.down("desktop")]: {
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    overflow: "auto",
    padding: theme.spacing(8),
  },
}));

const CanvasWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  borderRadius: "20px",
  border: "1px solid #FFFFFF",
  backgroundColor: theme.palette.primary["grey-400"],
  overflow: "hidden",

  [theme.breakpoints.down("tablet")]: {
    flexShrink: "0",
  },
}));

const MiniCanvas = forwardRef(({ id }, ref) => {
  return (
    <MiniCanvasStyled>
      <canvas ref={ref} id={id}></canvas>
    </MiniCanvasStyled>
  );
});

const MiniCanvasStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "240px",
  maxHeight: "45vh",

  aspectRatio: "3/2",
  overflow: "hidden",

  "& canvas": {
    width: "100% !important",
    height: "100% !important",
    pointerEvents: "auto",
    borderRadius: "20px",
  },

  [theme.breakpoints.down("tablet")]: {
    maxWidth: "75vw",
    height: "auto",
  },
}));
