import { useState } from "react";
import { Box, Button, styled } from "@mui/material";

const AnimatedTextButton = ({ variant, color, onClick, endIcon, children }) => {
  const [isItemHovered, setIsItemHovered] = useState("default");

  const handleOnMouseEnter = () => {
    setIsItemHovered("hovered");
  };

  const handleOnMouseLeave = () => {
    setIsItemHovered("default");
  };

  return (
    <Button
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={onClick}
      variant={variant}
      color={color}
      endIcon={endIcon}
    >
      <TitleWrapper>
        <Title isitemhovered={isItemHovered}>{children}</Title>
        <Title isitemhovered={isItemHovered}>{children}</Title>
      </TitleWrapper>
    </Button>
  );
};

export default AnimatedTextButton;

const TitleWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  overflow: "hidden",
}));

const Title = styled(Box)(({ theme, isitemhovered }) => ({
  transform:
    isitemhovered === "hovered" ? "translateY(-50%)" : "translateY(50%)",
  transition: "all 0.3s",
  [theme.breakpoints.down("tablet")]: {
    fontSize: "14px",
  },
}));
