import { useContext, useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import PageTransition from "../../common/components/pageTransition/PageTransition";
import { Scene3DObjectsNames } from "../../common/webgl/sceneObjects/SceneObjectsPathData";
import { getHotspotPoint } from "../../common/utils/getHotspotPoint";

import SceneAPIContext from "../../common/context/sceneAPIContext/SceneAPIContext";
import { useResponsive } from "../../common/hooks/useResponsive";
import {
  ArrowLeftIcon,
  MinusIcon,
  PlusIcon,
} from "../../common/assets/svgIcons/icon";
import BuildMinervaButton from "../../common/components/header/BuildMinervaButton";
import PreOrderMinervaButton from "../../common/components/header/PreOrderMinervaButton";

type Hotspot = {
  type: string;
  x: number;
  y: number;
};

type PositionType = {
  x: number;
  y: number;
};

const EasyReplaceDetailsPage = (): JSX.Element => {
  const { threeScene, sceneViewport } = useContext(SceneAPIContext);
  const theme = useTheme();

  const { isExtraLargeDevice } = useResponsive();

  const [hotspotVisible, setHotspotVisible] = useState<boolean>(false);
  const [hotspotPoint, setHotspotPoint] = useState<Array<Hotspot>>([]);
  const [hintStage, setHintStage] = useState<boolean>(false);
  const [textStage, setTextStage] = useState<boolean>(isExtraLargeDevice);

  const returnToMainState = (): void => {
    const onReady = (): void => {
      showHotspot();
      setHintStage(true);
    };

    threeScene.loadSceneStage(
      "replaceDetails",
      isExtraLargeDevice ? "desktop" : "mobile",
      1000,
      onReady
    );
  };

  const showHotspot = (): void => {
    setHotspotVisible(true);
  };

  const hideHotspot = (): void => {
    setHotspotVisible(false);
  };

  const toogleText = (): void => {
    setTextStage(!textStage);
  };

  const replaceCamera = (): void => {
    const onReady = (): void => {
      threeScene.sceneAnimation.replaceCamera.start(returnToMainState);
    };

    hideHotspot();
    setHintStage(false);
    threeScene.loadSceneStage(
      "replaceCamera",
      isExtraLargeDevice ? "desktop" : "mobile",
      1000,
      onReady
    );
  };

  const replaceSensor = (): void => {
    const onReady = (): void => {
      showHotspot();
      setHintStage(true);
    };

    hideHotspot();
    setHintStage(false);

    threeScene.sceneAnimation.replaceSensor.start(onReady);
  };

  const getHotspotPosition = (point: any): PositionType | void => {
    const windowPosition =
      threeScene.sceneObjects.getObjectWindowPosition(point);

    const position = getHotspotPoint(
      windowPosition,
      sceneViewport.current.clientHeight
    );

    return position;
  };

  const getCameraHotspotPosition = (): void => {
    const object = threeScene.sceneObjects.getObjectByName(
      Scene3DObjectsNames.GasCamera35mm
    );

    const position = getHotspotPosition(object.children[0]);
    if (!position) {
      return;
    }

    setHotspotPoint(prev => {
      const newArray = prev.filter(el => el.type !== "Camera");

      return [...newArray, { type: "Camera", x: position.x, y: position.y }];
    });
  };

  const getSensorHotspotPosition = (): void => {
    const object = threeScene.sceneObjects.getObjectByName(
      Scene3DObjectsNames.MinervaCameraSingleCamera
    );

    const position = getHotspotPosition(object.children[0].children[4]);
    if (!position) {
      return;
    }

    setHotspotPoint(prev => {
      const newArray = prev.filter(el => el.type !== "Sensor");

      return [
        ...newArray,
        {
          type: "Sensor",
          x: position.x,
          y: position.y,
        },
      ];
    });
  };

  const onComplete = (): void => {
    showHotspot();

    getCameraHotspotPosition();

    getSensorHotspotPosition();
  };

  useEffect((): (() => void) => {
    hideHotspot();

    setTextStage(isExtraLargeDevice ? true : false);

    if (threeScene) {
      threeScene.loadSceneStage(
        "replaceDetails",
        isExtraLargeDevice ? "desktop" : "mobile",
        1000,
        onComplete
      );
    }

    threeScene.sceneAnimation.replaceCamera.init();
    threeScene.sceneAnimation.replaceSensor.init();

    return () => {
      threeScene.sceneAnimation.replaceCamera.stop();
      threeScene.sceneAnimation.replaceSensor.stop();
    };
  }, [isExtraLargeDevice]);

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      hideHotspot();
      onComplete();
      setTextStage(true);
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageTransition>
      <Wrapper p={6}>
        <Typography variant="h3"> Effortless Maintenance</Typography>

        <TextBox>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Title variant="h5">Minerva&apos;s Quick Swap System</Title>
            {!isExtraLargeDevice && (
              <IconButton onClick={toogleText}>
                {textStage ? <MinusIcon /> : <PlusIcon />}
              </IconButton>
            )}
          </Stack>
          {textStage && (
            <Text variant="body-400-18">
              Minerva&apos;s QuickSwap system is a game-changer in sensor
              maintenance. Each sensor module is engineered for rapid
              replacement, necessitating just a few simple steps: undoing four
              screws and engaging a user-friendly quick-tab lever.
            </Text>
          )}
        </TextBox>
        {isExtraLargeDevice && hintStage && (
          <HintWrapper>
            <ArrowLeftIcon color={theme.palette.primary.contrastText} />
            <Typography component={"p"} variant="body-500-16">
              Tailor Minerva to your business needs with our builder
            </Typography>
          </HintWrapper>
        )}
        {!isExtraLargeDevice && (
          <ButtonsWrapper gap={2}>
            <BuildMinervaButton />
            <PreOrderMinervaButton />
          </ButtonsWrapper>
        )}
        {hotspotVisible &&
          hotspotPoint.map(
            (el): JSX.Element => (
              <HotspotPoint
                key={el.type}
                x={el.x}
                y={el.y}
                onClick={el.type === "Camera" ? replaceCamera : replaceSensor}
              />
            )
          )}
      </Wrapper>
    </PageTransition>
  );
};

export default EasyReplaceDetailsPage;

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 80px)",
  pointerEvents: "auto",
  gap: theme.spacing(6),

  "& h3": {
    color: theme.palette.primary.main,
  },
  position: "relative",
  [theme.breakpoints.down("laptop")]: {
    gap: theme.spacing(1),
    padding: theme.spacing(4, 4, 1, 4),
  },
}));

const TextBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(4),
  padding: theme.spacing(10),
  background: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(4),
  width: "440px",
  position: "relative",
  zIndex: 1,
  [theme.breakpoints.down("laptop")]: {
    marginTop: "auto",

    width: "100%",
    gap: theme.spacing(2),

    padding: theme.spacing(2),
  },
}));

const Title = styled(Typography)(() => ({}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const HotspotPoint = styled(Box)<{ x: number; y: number }>(
  ({ theme, x, y }) => ({
    position: "absolute",
    background: theme.palette.primary["brand-orange"],
    cursor: "pointer",

    top: y,
    left: x,
    width: "16px",
    height: "16px",
    borderRadius: "100%",
    [theme.breakpoints.down("tablet")]: {
      width: "10px",
      height: "10px",
    },
  })
);

const HintWrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.primary["brand-orange"],
  color: theme.palette.primary.contrastText,
  position: "absolute",
  right: "24px",
  top: "0",
  width: "224px",
  heigth: "224px",
  aspectRatio: "1/1",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "100%",
  padding: theme.spacing(5),
  gap: theme.spacing(2),

  "& p": {
    textAlign: "center",
  },
  "& svg": {
    transform: "rotateZ(90deg)",
  },
}));

const ButtonsWrapper = styled(Stack)(() => ({
  justifyContent: "center",
  alignItems: "center",

  "& button": {
    width: "100%",
  },
}));
