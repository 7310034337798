import { GoogleTilesRenderer } from "3d-tiles-renderer";

import { MathUtils, Raycaster, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

import { Scene3DObjectsNames } from "../sceneObjects/SceneObjectsPathData";

import { TransformControlsMode } from "../controls/TransformControlData";

class MapTilesRenderer {
  constructor(scene, sceneCamera, sceneRenderer, sceneControls, sceneObjects) {
    this.scene = scene;
    this.sceneCamera = sceneCamera;
    this.sceneRenderer = sceneRenderer;
    this.sceneControls = sceneControls;
    this.sceneObjects = sceneObjects;
    this.tiles = null;

    this.intersectPointFrom = new Vector3(0, 15000, 0);
    this.intersectPointTo = new Vector3(0, -15000, 0);
  }

  dispose() {
    if (this.tiles) {
      this.sceneControls.transformControl.removeEventListener(
        "objectChange",
        this.transformControlOnTowerPositionCahnge.bind(this)
      );

      this.scene.remove(this.tiles.group);
      this.tiles.dispose();
      this.tiles = null;
    }
  }

  loadTiles(lon, lng, isLoadCallBack, loadingPercentageCallBack) {
    this.tiles = new GoogleTilesRenderer(process.env.REACT_APP_GOOGLE_API_KEY);

    this.tiles.optimizeRaycast = true;

    this.tiles.setLatLonToYUp(MathUtils.degToRad(lon), MathUtils.degToRad(lng));

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://unpkg.com/three@0.153.0/examples/jsm/libs/draco/gltf/"
    );

    const loader = new GLTFLoader(this.tiles.manager);
    loader.setDRACOLoader(dracoLoader);

    this.tiles.manager.addHandler(/\.gltf$/, loader);
    this.scene.add(this.tiles.group);

    this.tiles.setResolutionFromRenderer(this.sceneCamera, this.sceneRenderer);
    this.tiles.setCamera(this.sceneCamera);

    let count = 0;
    const maxCount = 8;
    let isLoaded = false;

    this.tiles.manager.onProgress = (_, itemsLoaded, itemsTotal) => {
      if (!isLoaded) {
        loadingPercentageCallBack((itemsLoaded / itemsTotal) * 100);
      }
    };

    this.tiles.manager.onLoad = () => {
      if (!this.tiles) {
        return;
      }

      if (count >= maxCount) {
        isLoadCallBack(true);

        isLoaded = true;
      }

      if (count <= maxCount) {
        count += 1;
      }

      this.updateOrbitControlTarget();
      this.updateTowerPosition();
    };

    this.sceneControls.transformControl.addEventListener(
      "objectChange",
      this.transformControlOnTowerPositionCahnge.bind(this)
    );
  }

  updateOrbitControlTarget() {
    if (!this.tiles) {
      return;
    }

    const fromPoint = this.intersectPointFrom.clone();
    const toPoint = this.intersectPointTo.clone();

    const point = this.getIntersectPoint(fromPoint, toPoint);

    if (point) {
      this.sceneControls.orbitControl.setOrbitCameraTarget(point);
    }
  }

  transformControlOnTowerPositionCahnge() {
    if (!this.tiles) {
      return;
    }

    if (
      this.sceneControls.transformControl.mode ===
        TransformControlsMode.translateObject &&
      this.sceneControls.transformControl.object.name ===
        Scene3DObjectsNames.Tower
    ) {
      this.updateTowerPosition();
    }
  }

  updateTowerPosition() {
    if (!this.tiles) {
      return;
    }

    const towerCurrentPosition = this.sceneObjects
      .getSceneObjectInstanceByName(Scene3DObjectsNames.Tower)
      .getObjectWorldPosition();

    const fromPoint = towerCurrentPosition.clone();
    fromPoint.y = this.intersectPointFrom.y;

    const toPoint = towerCurrentPosition.clone();
    toPoint.y = this.intersectPointTo.y;

    const point = this.getIntersectPoint(fromPoint, toPoint);

    if (point) {
      this.sceneObjects.setTowerToMapLocationPosition(point.clone());
    }
  }

  getIntersectPoint(fromPoint, toPoint) {
    const raycaster = new Raycaster();

    const origin = fromPoint.clone();
    const direction = toPoint.clone().sub(fromPoint).normalize();

    raycaster.set(origin, direction);

    const hitList = raycaster.intersectObject(this.tiles.group, true);

    if (hitList.length > 0) {
      return hitList
        .map(hit => hit.point)
        .reduce((res, vec) => {
          if (!res) {
            return vec;
          } else {
            return vec.y < res.y ? vec : res;
          }
        });
    }
  }

  render(camera, renderer) {
    if (this.tiles) {
      this.tiles.setResolutionFromRenderer(camera, renderer);
      this.tiles.setCamera(camera);

      camera.updateMatrixWorld();
      this.tiles.update();
    }
  }
}

export default MapTilesRenderer;
